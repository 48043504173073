'use strict';

import React from 'react';
import ButtonNavigation from './../button-navigation';
import InputSelect from '../input-select';
import ImageContainer from '../image-container';
import PropTypes from 'prop-types';
import imagesHelper from '../../lib/images-helper';

class Measurement extends React.Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }

    render() {
        const {t} = this.props;
        return (
            <div className="comp-measurement">
                <div className="grid-container">
                    <div className="grid-x">
                        <div className="cell small-8 xlarge-7">
                            <h2>{t('Wählen Sie die Hauptabmessung ihres Schachtbauwerk')}</h2>
                        </div>
                    </div>
                </div>

                <form>
                    <div className="grid-container">
                        <div className="grid-x">
                            <div className="cell small-5 xlarge-4">
                                <InputSelect
                                    t={t}
                                    options={this.props.options}
                                    name="measurement"
                                    value={this.props.value}
                                    onChange={this.onChange}
                                />
                            </div>

                            <div className="cell small-6 small-offset-1 xlarge-7 xlarge-offset-1">
                                <div className="cf right margin-top--6">
                                    <ImageContainer
                                        src={imagesHelper[this.props.rightImageSrc]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <div className="main-content-footer">
                    <ButtonNavigation
                        t={t}
                        history={this.props.history}
                        enableNext={this.props.allowNext}
                        location={this.props.location}
                        eventHandler={this.props.eventHandler} />
                </div>
            </div>
        )
    }

    onChange(e) {
        let target = e.target;

        this.props.update(target.value);
    }
}

Measurement.propTypes = {
    options: PropTypes.any,
    history: PropTypes.func,
    allowNext: PropTypes.bool,
    location: PropTypes.any,
    locations: PropTypes.any,
    update: PropTypes.func,
    eventHandler: PropTypes.func,
    rightImageSrc: PropTypes.string,
    value: PropTypes.string,
    t: PropTypes.func
};

export default Measurement;