import React     from 'react';
import PropTypes from 'prop-types';
import get       from 'lodash.get';

const komplettSchachtConfig =
    require('./../../../../server/model/komplettschacht-configurator/config/komplettschacht-configuration');
const schlammsammlerSchachtConfig =
    require('./../../../../server/model/schlammsammler-configurator/config/schlammsammler-configuration');
const productConfig =
    require('./../../../../server/model/price-calculator/config');

const komplettschachtTypeOfDimension =
    new (require('./../../../../server/model/komplettschacht-configurator/steps/typeOfDimension'));
const schlammsammlerTypeOfDimension =
    new (require('./../../../../server/model/schlammsammler-configurator/steps/typeOfDimension'));

const pipeTypes = {
    pvc: 'PVC',
    pe: 'PE',
    pp: 'PP',
    gup: 'GUP',
    geopur: 'Geopur',
    centub: 'Centub'
};


const belastungsKlasse = {
    belastungsklasse_a: 'A',
    belastungsklasse_b: 'B',
    belastungsklasse_c: 'C',
    belastungsklasse_d: 'D'
};

class ProtocolText extends React.Component {
    constructor(props) {
        super(props);
    }

    generateText(session){
        let {steps, data} = session;

        if(!data) return '';

        const {t} = this.props;

        let config = data.product === 'schlammsammler' ? schlammsammlerSchachtConfig : komplettSchachtConfig;
        data.t = t;

        schlammsammlerTypeOfDimension.setTranslateFunction(t);
        komplettschachtTypeOfDimension.setTranslateFunction(t);

        let typeOfDimensionConfig = null;
        let typeDimension = null;
        if(steps.typeOfDimension) {
            typeOfDimensionConfig = data.product === 'schlammsammler' ?
                schlammsammlerTypeOfDimension.getStepConfig(data) : komplettschachtTypeOfDimension.getStepConfig(data);

            typeDimension = typeOfDimensionConfig.types.filter(entry => {
                return entry.key === data.typeDimension;
            }).pop();
        }


        let product = data.product === 'komplettschacht' ?
            t('Kontroll- / Einstiegsschächte') : t('Schlammsammler / Sammelschächte');

        const measurement = config.mainMeasurementSelector.inputs.filter((entries) => {
            return parseInt(entries.key) === data.measurement
        }).pop();

        const floorType = config.floorTypeSelector.floorTypes.filter(entry => {
            return entry.value === parseInt(data.floortype);
        }).pop();

        const valueDimension = !data.secondValueDimension ? `${data.valueDimension} mm`
            : `${data.valueDimension} ${t('M.ü.M.')} (${t('Deckel')}) - ` +
            `${data.secondValueDimension} ${t('M.ü.M.')} (${t('Auslauf')})`;

        let productText = '';
        if (data.productId) {
            let product = productConfig.priceCalculation.normSchachtboden.list.filter((product) => {
                return product.productId === data.productId
            }).pop();

            productText = `${t('Produkt')}: ${product.productId} ${product.name}`;
        }

        let HD = data.hdDimension;
        if (!HD) {
            HD = 0;
        }

        let leiter = null;

        if(get(config.optionalInformation, 'ladders'))
            leiter = config.optionalInformation.ladders.options.filter(ladder => {
                return ladder.key === data.leiter;
            }).pop();

        data.inlets = data.inlets || [];

        const inlets = data.inlets.map(inlet => {
            inlet.measurementLabel = t('Abstand zur Sohle Auslauf');

            if (inlet.abstand_zur_sohle_auslauf) {
                inlet.measurementValue = `${inlet.abstand_zur_sohle_auslauf} mm`;
            }

            if (inlet.hohenkote_ab_ok_schacht) {
                inlet.measurementValue = `${inlet.hohenkote_ab_ok_schacht} ${t('M.ü.M.')}`;
            }

            return inlet;
        });

        let inletsText = [],
            outfallText = [];

        if (inlets.length > 0) {
            inletsText = inlets.map((inlet, i) => {

                if (pipeTypes[inlet.pipetype]) {
                    let inletDetial = [<b key={i}>{t('Einlauf')} {i + 1}</b>];
                    inletDetial.push(<p key={i + 'Rohrtyp'}>{t('Rohrtyp')}: {pipeTypes[inlet.pipetype]}</p>);
                    inletDetial.push(<p key={i + 'Durchmesser'}>{t('Durchmesser')}: {inlet.diameter} mm</p>);
                    inletDetial.push(<p key={i + 'Rohrserie'}>{t('Rohrserie')}: {inlet.pipeseries || '-'}</p>);
                    inletDetial.push(<p key={i + 'Gefälle'}>{t('Gefälle')}: {inlet.fall ? inlet.fall + '‰' : '-'}</p>);
                    inletDetial.push(<p key={i + 'Winkel'}>{t('Winkel')}: {inlet.winkel}°</p>);
                    inletDetial.push(<p key={i + 'Measurement'}>{inlet.measurementLabel}: {inlet.measurementValue}</p>);
                    inletDetial.push(<p key={i + 'Kommentar'}>{t('Kommentar')}: {inlet.comment || '-'}</p>);
                    return inletDetial;
                } else {
                    let inletDetial = [<b key={'no_inlet_title'}>{t('Einlauf')}</b>];
                    inletDetial.push(<p key={'no_inlet'}>{t('ohne Einlauf')}</p>);
                    return inletDetial;
                }
            });
        }

        // is this enough to define if there is an outfall?
        if (data.pipetype) {
            outfallText = [<b key={'Auslauf'}>{t('Auslauf')}</b>];
            outfallText.push(<p key={'Rohrtyp'}>{t('Rohrtyp')}: {pipeTypes[data.pipetype]}</p>);
            outfallText.push(<p key={'Durchmesser'}>{t('Durchmesser')}: {data.diameter} mm</p>);
            outfallText.push(<p key={'Rohrserie'}>{t('Rohrserie')}: {data.pipeseries || '-'}</p>);
            outfallText.push(<p key={'Gefälle'}>{t('Gefälle')}: {data.fall ? data.fall + '‰' : '-'}</p>);
            outfallText.push(<p key={'Kommentar'}>{t('Kommentar')}: {data.comment || '-'}</p>);
        }

        //<h3 key={'Ihre bisherige Konfiguration'}>Ihre bisherige Konfiguration:</h3>
        let text = [];

        if(steps.productSelector) {
            text.push(<p key={'Produkt'}>{t('Produkt')}: {product}</p>);
        }

        if(steps.mainMeasurementSelector) {
            text.push(<p key={'Hauptabmessung des Schachtbauwerks'}>
                {t('Hauptabmessung des Schachtbauwerks')}: {get(measurement, 'label', '-')}</p>);
        }

        if(steps.floorTypeSelector) {
            text.push(<p key={'Boden Art des Schachtbodens'}>
                {t('Boden Art des Schachtbodens')}: {t(get(floorType, 'label', '-'))}</p>);
            text.push(<p key={'productText'}>{productText}</p>);
        }

        if(steps.typeOfDimension) {
            text.push(<p key={'typeDimension'}>{t(get(typeDimension, 'label', '-'))}: {valueDimension}</p>);
            if (HD) {
                text.push(<p key={'HD'}>{t('HD')}: {HD} mm</p>);
            }
        }

        if(steps.outfallSelector) {
            text.push(<div key={'outfallSelectorOutfallText'}>{outfallText}</div>);
        }

        if(steps.inletSelector) {
            text.push(<div key={'inletsText'}>{inletsText}</div>);
        }

        if(steps.optionalInformation) {
            text.push(<b key={'Optionale Angaben'}>{t('Optionale Angaben')}</b>);
            if(get(leiter, 'label')) {
                text.push(<p key={'Aluminiumleiter'}>{t('Leiter')}: {t(get(leiter, 'label'))}</p>);
            }
            text.push(<p key={'Konus'}>{t('Konus')}: {data.konus ? t('Ja') : t('Nein')}</p>);

            if (data.brunnenringTypeOption) {
                text.push(
                    <p key={'Art Brunnenring'}>
                        {t('Art Brunnenring')}: {t(data.brunnenringTypeOption)}
                    </p>);
            }


            if (data.coverEntrance) {
                const coverEntranceObject = config.optionalInformation.coverEntrance.options.find(option => (
                    option.value === data.coverEntrance
                ));
                if (coverEntranceObject) {
                    text.push(<p key={'Deckel Einstieg'}>{t('Deckel Einstieg')}: {t(coverEntranceObject.label)}</p> )
                }
            }

            text.push(<p key={'Belastungsklasse'}>{t('Belastungsklasse')}: {
                belastungsKlasse[data.belastungsklasse]
            }</p>);
            let coverText = t('Ohne Schachtabdeckung');
            if (data.coverOption === 'known_article_number' && data.coverArticleNumber) {
                const coverObject = productConfig.priceCalculation.coverArticles.list
                    .filter(cover => {
                        return cover.productId === data.coverArticleNumber
                    }).pop();
                if (coverObject) {
                    coverText = `${coverObject.productId} ${coverObject.name}`;
                }
            }
            text.push(<p key={'Schachtabdeckung'}>{t('Schachtabdeckung')}: {coverText}</p>);

            if(data.titleBlockNumber && data.titleBlockNumber.length > 0) {
                text.push(<p key={'Schriftschild'}>{t('Schriftschild')}: {data.titleBlockNumber}</p>);
            }

            if (data.sealingTapeOption) {
                text.push(
                    <p key={'SikaSwell (Quellfähigesdichtungsband)'}>
                        {t('SikaSwell (Quellfähigesdichtungsband)')}: {t(data.sealingTapeOption)}
                    </p>);
            }

            text.push(<p key={'Bemerkungen'}>
                {t('Bemerkungen')}: {data.opt_comment || '-'}</p>);
        }


        if(steps.priceVisualization) {
            text.push(<b key={'Preis und Gewicht'}>{t('Preis und Gewicht')}</b>);
            text.push(<p key={'Preis'}>{t('Preis')}: {data.price} CHF</p>);
            text.push(<p key={'Gewicht'}>{t('Gewicht')}: {data.weight} kg</p>);
        }

        return text;
    }

    render() {
        if(!this.props.config) return '';

        let text = this.generateText(this.props.config);

        return <div>
            {text}
        </div>
    }
}

ProtocolText.propTypes = {
    config: PropTypes.object,
    t: PropTypes.func.isRequired
};

export default ProtocolText;

/*
Kontaktinformationen
Vorname:\t${data.vorname}
Nachname:\t${data.nachname}
E-Mail:\t\t${data.email}
Kundennummer:\t${data.kundennummer || '-'}
Firma:\t${data.firma || '-'}
Strasse:\t${data.strasse || '-'}
Ort:\t${data.ort || '-'}
Telefon:\t${data.telefon || '-'}
Fax:\t${data.fax || '-'}
Objekt:\t${data.objekt || '-'}
Schachtbezeichnung:\t${data.schachtbezeichnung || '-'}
Liefertermin:\t${data.liefertermin || '-'}
Lieferadresse:\t${data.lieferadresse || '-'}
Bemerkung:\t${data.bemerkung || '-'}
 */