import fetchAndCheck from '../../lib/advanced-fetch';
import cfg           from '../../conf';

export function saveProjectInfoData(id, projectData) {

    let apiURL = `${cfg.api.projectApiUrlTemplate(id)}`;

    let apiMethod = 'PUT';

    delete projectData.created_at;
    delete projectData.date_offer;
    delete projectData.date_order;
    delete projectData.id;
    delete projectData.modified_at;
    delete projectData.status;
    delete projectData.user;
    delete projectData.hasConfigurations;
    delete projectData.projectInfoFulfilled;

    return {
        type: 'save-project-info-data',
        payload: fetchAndCheck(apiURL, {
            method: apiMethod,
            body: JSON.stringify(projectData),
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
    };
}

export function syncProjectInfoData(data) {
    return {
        type: 'sync-project-info-data',
        data: data
    }
}

export function resetProjectInfoData() {
    return {
        type: 'reset-project-info-data'
    }
}
