import _ from 'lodash';
import 'whatwg-fetch';


let fetchAndCheck = (url, options, noForceLogout) => {

    function forceLogout() {
        if (url === '/login') return;
        if (noForceLogout) return;

        window.location.reload(true);
    }

    return fetch(url, options)
        .then((res) => {
            if (res.ok) {
                return res
                    .json()
                    .then(body => {
                        let status = _.get(body, 'error.status');

                        if (status && status === 403) {
                            forceLogout();
                        }

                        if (status && status > 200) {
                            return Promise.reject(body);
                        }

                        return body;
                    });
            } else {
                let error = new Error(res.statusText);
                error.status = res.status;

                return res
                    .json()
                    .catch(() => {
                        if (res.status === 403 && res.statusText === 'Forbidden') {
                            let err = new Error('Rejected by WAF');
                            err.status = res.status;
                            return Promise.reject(err);
                        }

                        return Promise.reject(error);
                    })
                    .then(err => {
                        let status = _.get(err, 'error.status');

                        if (status && status === 403) {
                            forceLogout();
                        }

                        return Promise.reject(err);
                    });
            }
        });
};

export default fetchAndCheck;