'use strict';

import React from 'react';
import merge from 'lodash.merge';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Login from '../login';
import checkAuthorised from '../authorisation/actions';
import Register from '../register';
import PasswordResetEmailRequest from '../password-reset-email-request';

class OpenLoginModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoginModalOpen: (!props.userAuthorised && props.checkUserAuthorised),
            isRegistrationModalOpen: false,
            isPasswordRequestModalOpen: false
        };
        this.checkAuthorised = this.checkAuthorised.bind(this);
        this.openLoginModal = this.openLoginModal.bind(this);
        this.closeLoginModal = this.closeLoginModal.bind(this);
        this.openRegistrationModal = this.openRegistrationModal.bind(this);
        this.closeRegistrationModal = this.closeRegistrationModal.bind(this);
        this.openPasswordRequestModal = this.openPasswordRequestModal.bind(this);
        this.closePasswordRequestModal = this.closePasswordRequestModal.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.checkUserAuthorised !== this.props.checkUserAuthorised) {
            !this.props.userAuthorised && this.openLoginModal();
        }
    }

    checkAuthorised() {
        this.props.dispatch(checkAuthorised());
    }

    render() {
        if (!this.props.userAuthorised) {
            return <span><Login
                {...this.props}
                openModal={this.openLoginModal}
                closeModal={this.closeLoginModal}
                isModalOpen={this.state.isLoginModalOpen}
                checkAuthorised={this.checkAuthorised}
                openRegistrationModal={this.openRegistrationModal}
                openPasswordRequestModal={this.openPasswordRequestModal}
            />
            <Register
                {...this.props}
                closeModal={this.closeRegistrationModal}
                isModalOpen={this.state.isRegistrationModalOpen}
                checkAuthorised={this.checkAuthorised}
                redirectOnSuccess={false}
            />
            <PasswordResetEmailRequest
                {...this.props}
                closePasswordRequestModal={this.closePasswordRequestModal}
                isModalOpen={this.state.isPasswordRequestModalOpen}
                checkAuthorised={this.checkAuthorised}
            />
            </span>
        }

        return (
            <div>
                {this.props.render(this.state)}
            </div>
        );
    }

    openLoginModal() {
        this.setState(prev => {
            prev.isLoginModalOpen = true;

            return prev;
        })
    }

    closeLoginModal(redirectToRoot) {
        this.setState(
            prev => {
                prev.isLoginModalOpen = false;

                return prev;
            },
            () => {
                if (redirectToRoot === true) {
                    this.props.history.push('/');
                }
            }
        );
    }

    openRegistrationModal() {
        this.setState({isRegistrationModalOpen: true});
    }

    closeRegistrationModal() {
        this.setState({isRegistrationModalOpen: false});
    }

    openPasswordRequestModal() {
        this.setState({isPasswordRequestModalOpen: true});
    }

    closePasswordRequestModal() {
        this.setState({isPasswordRequestModalOpen: false});
    }
}

OpenLoginModal.propTypes = {
    userAuthorised: PropTypes.bool,
    dispatch: PropTypes.func,
    history: PropTypes.object,
    checkUserAuthorised: PropTypes.bool,
    render: PropTypes.func
};

const mapStateToProps = state => {
    return merge({}, state.shared);
};

export default connect(mapStateToProps)(OpenLoginModal);
