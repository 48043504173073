'use strict';

import React     from 'react';
import PropTypes from 'prop-types';
import 'whatwg-fetch';

import FetchUtils from './../../advancd-fetch';
import Outfall    from './../outfall';

class OutfallData extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            input: {
                pipetype: null,
                diameter: null,
                fall: null,
                pipeseries: '',
                abstand_zur_sohle_auslauf: null, // Actual selection on the inlet
                hohenkote_ab_ok_schacht: null,   // Actual selection on the inlet
                abstandHohenkoteSelected: '',
                comment: ''
            },
            abstand_zur_sohle_auslauf: null, // Actual selection on the inlet
            hohenkote_ab_ok_schacht: null,
            pipetypes: [],
            diameters: [],
            pipeSeries: [],
            allowNext: false,
            fieldsWithError: []
        };

        this.load = this.load.bind(this);
        this.loadConfigs = this.loadConfigs.bind(this);
        this.update = this.update.bind(this);
        this.onUpdatePipetype = this.onUpdatePipetype.bind(this);
        this.onUpdatePipeSeries = this.onUpdatePipeSeries.bind(this);
        this.onUpdateDiameter = this.onUpdateDiameter.bind(this);
        this.onUpdateFall = this.onUpdateFall.bind(this);
        this.onUpdateComment = this.onUpdateComment.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.allowNext = this.allowNext.bind(this);
        this.onBack = this.onBack.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
        this.onInputChangeAbstandAndHohenkote = this.onInputChangeAbstandAndHohenkote.bind(this);
        this.onRadioSelectChangeAbstandAndHohenkote = this.onRadioSelectChangeAbstandAndHohenkote.bind(this);

        this.stepId = 'outfallSelector';

        // pare this. because somehow we come through the constructor relatively often
        this.props.eventHandler.unbind('next-clicked', this.handleNext);
        this.props.eventHandler.bind('next-clicked', this.handleNext);
        this.props.eventHandler.unbind('back-clicked', this.onBack);
        this.props.eventHandler.bind('back-clicked', this.onBack);
    }

    _handleKeyDown(event) {
        let ENTER_CODE = 13;

        if (event.keyCode === ENTER_CODE) {
            event.preventDefault();

            if (this.allowNext(this.state)) {
                this.props.eventHandler.trigger('next-clicked');
            }

            return false;
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this._handleKeyDown);
    }

    componentDidMount() {
        document.addEventListener('keydown', this._handleKeyDown);
        this.props.eventHandler.trigger('protocol');
        this.props.toggleProgressBar(this.props.showProgressBar);
        this.props.toggleSideProtocol(this.props.showSideProtocol);
        this.load();
    }


    handleNext() {
        this.update();
    }


    load() {
        fetch(`/api${this.props.location.pathname}`, {credentials: 'include'})
            .then((response) => {
                return FetchUtils.checkStatus(response);
            })
            .then(response => {
                this.props.eventHandler.trigger('update-progress-bar', {id: this.stepId, isCompleted: false});

                if (response.next) {
                    this.props.history.push(response.next);
                    return Promise.reject(new Error('done'));
                }

                if (response.rightImage) {
                    this.setState({rightImageSrc: response.rightImage.src})
                }

                if (response.rightImageCircle) {
                    this.setState({rightImageCircleSrc: response.rightImageCircle.src});
                }

                if (response.rightImageCircleWithAngleValues) {
                    this.setState({rightImageCircleLabeledSrc: response.rightImageCircleWithAngleValues.src});
                }

                if (response.rightImageArrow) {
                    this.setState({rightImageArrowSrc: response.rightImageArrow.src});
                }

                if (response.outfalls) {
                    this.setState(prev => {
                        prev.pipetypes = response.outfalls.pipetype.options;
                        prev.abstand_zur_sohle_auslauf = response.outfalls.abstand_zur_sohle_auslauf;
                        prev.hohenkote_ab_ok_schacht = response.outfalls.hohenkote_ab_ok_schacht;
                        prev.input.pipeseries = response.outfalls.pipeseries ? '' : null;

                        prev.input.pipetype = response.input.pipetype;
                        prev.input.diameter = response.input.diameter;
                        prev.input.fall = response.input.fall;
                        prev.input.pipeseries = response.input.pipeseries;
                        prev.input.comment = response.input.comment;
                        prev.input.abstand_zur_sohle_auslauf = response.input.abstand_zur_sohle_auslauf;
                        prev.input.hohenkote_ab_ok_schacht = response.input.hohenkote_ab_ok_schacht;


                        //In komplettschacht these fields are hided
                        if (prev.abstand_zur_sohle_auslauf) {
                            prev.abstand_zur_sohle_auslauf.value = response.input.abstand_zur_sohle_auslauf;
                        }
                        if (prev.hohenkote_ab_ok_schacht) {
                            prev.hohenkote_ab_ok_schacht.value = response.input.hohenkote_ab_ok_schacht;
                        }

                        if (prev.input.abstand_zur_sohle_auslauf) {
                            prev.input.abstandHohenkoteSelected = 'abstand_zur_sohle_auslauf';
                        } else if (prev.input.hohenkote_ab_ok_schacht) {
                            prev.input.abstandHohenkoteSelected = 'hohenkote_ab_ok_schacht';
                        }

                        prev.allowNext = this.allowNext(prev);

                        return prev;
                    }, () => {
                        this.loadConfigs();
                    });
                }
            })
            .catch(err => {
                if (err.message !== 'done') {
                    console.error(err);

                    this.props.history.push('/error');
                }
            });
    }

    /**
     * load diameters asynchronically because they depend on the pipetype
     */
    loadConfigs() {
        if (this.state.input.pipetype == null) return;

        fetch(`/api${this.props.location.pathname}/configs/${this.state.input.pipetype}`, {credentials: 'include'})
            .then(FetchUtils.checkStatus)
            .then(response => {
                this.setState(prev => {
                    prev.diameters = response.diameters.map(option => {
                        option.key = option.value;

                        return option;
                    });

                    if (prev.diameters.map(diameter => diameter.value).indexOf(prev.input.diameter) < 0) {
                        prev.input.diameter = null;
                    }

                    prev.allowNext = this.allowNext(prev);

                    return prev;
                });

                this.setState(prev => {
                    prev.pipeSeries = response.pipeSeries || [];

                    if (!prev.pipeSeries.find(({value}) => value === prev.input.pipeseries)) {
                        prev.input.pipeseries = null;
                    }

                    return prev;
                });
            })
            .catch(err => {
                console.error(err);

                this.setState(prev => {
                    prev.diameters = [];

                    return prev;
                })
            });
    }

    update() {

        // Reset error fields
        this.setState(prev => {
            prev.fieldsWithError = [];

            return prev;
        });

        fetch(`/api${this.props.location.pathname}`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state.input)
        })
            .then((result) => {
                result.json().then(body => {
                    if (body.error) {
                        this.setState(prev => {
                            prev.fieldsWithError = body.error.invalidFields;

                            return prev;
                        })
                    }

                    this.props.history.push(body.next);
                });
            })
    }

    onUpdatePipetype(value) {
        this.setState((prev) => {
            prev.input.pipetype = value;

            // allow if all of those values are set
            prev.allowNext = this.allowNext(prev);

            this.loadConfigs();

            return prev;
        });
    }

    onUpdateDiameter(value) {
        this.setState((prev) => {
            value = parseInt(value);

            prev.input.diameter = value;

            // allow if all of those values are set
            prev.allowNext = this.allowNext(prev);

            return prev;
        })
    }

    onUpdatePipeSeries(value) {
        this.setState((prev) => {

            prev.input.pipeseries = parseInt(value);

            if (prev.input.pipeseries === 0) {
                prev.input.pipeseries = '';
            }

            prev.allowNext = this.allowNext(prev);

            return prev;
        });
    }

    onUpdateFall(value) {
        this.setState((prev) => {
            value = parseInt(value);

            prev.input.fall = value;

            prev.allowNext = this.allowNext(prev);

            return prev;
        });
    }

    onRadioSelectChangeAbstandAndHohenkote(key) {
        this.setState(prev => {

            let input = prev.input;

            input.abstandHohenkoteSelected = key;
            if (key === 'hohenkote_ab_ok_schacht') {
                prev.input.abstand_zur_sohle_auslauf = '';
                if (prev.abstand_zur_sohle_auslauf) {
                    prev.abstand_zur_sohle_auslauf.value = '';
                }
            }
            if (key === 'abstand_zur_sohle_auslauf') {
                prev.input.hohenkote_ab_ok_schacht = '';
                if (prev.hohenkote_ab_ok_schacht) {
                    prev.hohenkote_ab_ok_schacht.value = '';
                }
            }

            prev.allowNext = this.allowNext(prev);

            return prev;
        });
    }

    onInputChangeAbstandAndHohenkote(key, id, value) {
        this.setState(prev => {
            prev.input[key] = value;
            prev[key].value = value;

            prev.allowNext = this.allowNext(prev);

            return prev;
        });

    }

    onUpdateComment(value) {
        this.setState((prev) => {
            prev.input.comment = value;

            return prev;
        })
    }

    allowNext(state) {
        if (this.props.productName === 'komplettschacht') {
            return !!(
                state.input.pipetype &&
                state.input.diameter &&
                state.input.pipeseries
            );
        }

        if (this.props.productName === 'schlammsammler') {
            if (state.input.pipetype && state.input.diameter) {
                return !((
                    state.input.abstand_zur_sohle_auslauf === null ||
                    state.input.abstand_zur_sohle_auslauf === '' ||
                    typeof state.input.abstand_zur_sohle_auslauf === 'undefined'
                ) &&
                (
                    state.input.hohenkote_ab_ok_schacht === null ||
                    state.input.hohenkote_ab_ok_schacht === '' ||
                    typeof state.input.abstand_zur_sohle_auslauf === 'undefined'
                ));
            }
        }

        return false;
    }

    onBack() {
        fetch(`/api${this.props.location.pathname}/back`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((result) => {
                result.json().then(body => {
                    this.props.history.push(body.previous);
                });
            });
    }

    render() {
        const {t} = this.props;
        return (
            <Outfall
                t={t}
                title={t('Kontrollansicht')}
                eventHandler={this.props.eventHandler}
                location={this.props.location}
                history={this.props.history}

                updateDiameter={this.onUpdateDiameter}
                updatePipetype={this.onUpdatePipetype}
                updateFall={this.onUpdateFall}
                onRadioSelectChangeAbstandAndHohenkote={this.onRadioSelectChangeAbstandAndHohenkote}
                onInputChangeAbstandAndHohenkote={this.onInputChangeAbstandAndHohenkote}
                updateComment={this.onUpdateComment}
                updatePipeSeries={this.onUpdatePipeSeries}
                productName={this.props.productName}

                pipetypeOptions={this.state.pipetypes}
                activePipetype={this.state.input.pipetype}

                diameterOptions={this.state.diameters}
                activeDiameter={this.state.input.diameter}

                pipeSeriesOptions={this.state.pipeSeries}
                pipeseries={this.state.input.pipeseries}

                fallInput={this.state.input.fall}

                abstand={this.state.abstand_zur_sohle_auslauf}
                hohenkote={this.state.hohenkote_ab_ok_schacht}
                abstandHohenkoteSelected={this.state.input.abstandHohenkoteSelected}

                commentInput={this.state.input.comment}

                allowNext={this.state.allowNext}
                fieldsWithError={this.state.fieldsWithError}
                rightImageCircleSrc={this.state.rightImageCircleSrc}
                rightImageCircleLabeledSrc={this.state.rightImageCircleLabeledSrc}
                rightImageArrowSrc={this.state.rightImageArrowSrc}
            />
        );
    }

}

OutfallData.propTypes = {
    t: PropTypes.func,
    productName: PropTypes.string,
    eventHandler: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    showSideProtocol: PropTypes.bool,
    showProgressBar: PropTypes.bool,
    toggleProgressBar: PropTypes.func,
    toggleSideProtocol: PropTypes.func
}

export default OutfallData;