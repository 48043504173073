'use strict';

import React from 'react';
import PropTypes from 'prop-types';

class Product extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (<div>
            <div
                data-product={this.props.productKey}
                className={`product-card ${this.props.isActive ? 'is-active' : ''}`}
                onClick={() => this.props.click(this.props.productKey)}
            >
                <div className="product-card__inner">
                    <span className={`product-card__icon ${this.props.productKey}`}/>
                </div>
            </div>
            <div className={'product-card__title'}>
                {this.props.name}
            </div>
        </div>
        )
    }
}

export default Product;

Product.propTypes = {
    click: PropTypes.any.isRequired,
    isActive: PropTypes.any,
    name: PropTypes.any.isRequired,
    productKey: PropTypes.any.isRequired
};