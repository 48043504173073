import React from 'react';
import PropTypes from 'prop-types';
import advancedFetch from '../advanced-fetch';
import cfg from '../../conf';
import UserOverview from '../user-overview';
import {subject} from '@casl/ability';
import {connect} from 'react-redux';
import translate from '../translate';
import merge from 'lodash.merge';

class UserOverviewData extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            users: []
        };

        this.loadUsers = this.loadUsers.bind(this);
    }

    componentDidMount() {
        this.props.toggleSideProtocol(this.props.showSideProtocol);
        if (!this.props.ability.can('read', subject('UserData', {}))) {
            this.props.history.replace('/404');
            return;
        }
        this.loadUsers();
    }


    loadUsers(){
        advancedFetch(cfg.api.userDataApiUrlTemplate(), {credentials: 'include'})
            .then(result => {
                this.setState({users: result});
            })
            .catch(err => {
                if (err.error.status === 404) this.props.history.replace('/404')
                else console.error(err)
            });
    }

    render() {
        const {t} = this.props;

        return (
            <div>
                <h2>{t('Benutzerübersicht')}</h2>
                <UserOverview
                    t={t}
                    users={this.state.users}
                    history={this.props.history}
                />
            </div>
        )
    }
}

UserOverviewData.propTypes = {
    t: PropTypes.func.isRequired,
    history: PropTypes.any,
    eventHandler: PropTypes.object,
    showSideProtocol: PropTypes.bool,
    toggleSideProtocol: PropTypes.func,
    ability: PropTypes.shape({
        can: PropTypes.func
    })
};

const mapStateToProps = state => {
    return merge({}, state.shared);
};
export default connect(mapStateToProps)(translate(UserOverviewData));
