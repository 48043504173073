'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import ButtonNavigation from './../button-navigation';
import InputRadioFieldCombo from '../input-radio-field-combo';
import ImageContainer from '../image-container';
import InfoText from '../info-text';
import imagesHelper from '../../lib/images-helper';

class TypeDimension extends React.Component {
    constructor(props) {
        super(props);
    }

    createMarkup(htmlText) {
        return {__html: htmlText};
    }

    render() {
        const {t} = this.props;

        return (
            <div className="comp-type-dimension">

                <div className="grid-container">

                    <div className="grid-x grid-padding-x">
                        <div className="cell small-6">
                            <h2>{t('Geben Sie die Höhe oder Länge des Schachtbauwerks an')}</h2>
                            <form>
                                {this.props.types.map(type => {
                                    return <InputRadioFieldCombo
                                        t={t}
                                        {...this.props}
                                        hasError={type.error != null && type.error === true}
                                        errorMessage={type.errorMessage}
                                        name={type.key}
                                        key={type.key}
                                        type={type}
                                        hided={type.hided ? true : false}
                                        showCheckbox={type.showCheckbox}
                                        activateValue={type.activateValue ? true : false}
                                    />
                                })}
                            </form>

                            <br/>

                            {
                                this.props.text && <InfoText>
                                    <div dangerouslySetInnerHTML={this.createMarkup(this.props.text)}/>
                                </InfoText>
                            }
                        </div>
                        <div className="cell small-6">
                            <div className="cf right">
                                <ImageContainer
                                    src={imagesHelper[this.props.image]}
                                />
                            </div>
                        </div>

                    </div>

                </div>

                <div className="main-content-footer">
                    <ButtonNavigation
                        history={this.props.history}
                        enableNext={this.props.allowNext}
                        location={this.props.location}
                        eventHandler={this.props.eventHandler}/>
                </div>
            </div>
        )
    }
}

TypeDimension.propTypes = {
    t: PropTypes.func,
    types: PropTypes.object,
    text: PropTypes.string,
    image: PropTypes.string,
    history: PropTypes.object,
    allowNext: PropTypes.bool,
    location: PropTypes.object,
    eventHandler: PropTypes.func
};

export default TypeDimension;