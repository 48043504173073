import React     from 'react';
import PropTypes from 'prop-types';

class InputField extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {t} = this.props;
        let inputClasses = 'input',
            errorClass = 'input__error-right',
            requiredOptionalClass = 'input--optional',
            labelClass = 'label--content';


        if (this.props.disabled) {
            inputClasses += ' disabled';
        }

        if (this.props.unit) {
            inputClasses += ' input--unit-' + this.props.unit;
        }

        if (this.props.modifier) {
            inputClasses += ' input--' + this.props.modifier;
        }

        if (this.props.error) {
            inputClasses += ' error';
        }

        if (!this.props.optional) {
            requiredOptionalClass = 'input--required';
            labelClass += this.props.showRequiredSign ? '' : ' hide-sign';
        }

        if (this.props.errorLayout) {
            errorClass = `input_error-${this.props.errorLayout}`
        }

        let labelOutput = '';
        if (this.props.label !== '' && this.props.label !== undefined) {
            labelOutput = (
                <span className={labelClass}>{t(this.props.label)}</span>
            );
        }

        return (
            <label
                className={`${inputClasses} ${requiredOptionalClass}`}>
                {labelOutput}
                <span className="input__control">
                    <input
                        id={this.props.id}
                        name={this.props.name}
                        value={this.props.value}
                        type={this.props.type}
                        placeholder={this.props.placeholder}
                        disabled={this.props.disabled}
                        onChange={this.props.change}
                        required={this.props.required}
                    />
                    <span className={errorClass}>{this.props.error ? t(this.props.error.message) : ''}</span>
                </span>
            </label>
        )
    }
}

InputField.propTypes = {
    error: PropTypes.object,
    errorLayout: PropTypes.string,
    label: PropTypes.any,
    unit: PropTypes.any,
    modifier: PropTypes.any,
    id: PropTypes.any,
    name: PropTypes.any,
    value: PropTypes.any,
    type: PropTypes.any,
    placeholder: PropTypes.any,
    disabled: PropTypes.any,
    change: PropTypes.func,
    required: PropTypes.any,
    t: PropTypes.func,
    optional: PropTypes.bool,
    showRequiredSign: PropTypes.bool
};

InputField.defaultProps = {
    optional: true,
    showRequiredSign: true
};

export default InputField;