'use strict';

import React                  from 'react';
import PropTypes              from 'prop-types';
import ButtonNavigation       from './../button-navigation';
import InputSelect            from './../input-select';
import InputCheckbox          from './../input-checkbox';
import InputTextarea          from '../input-textarea/index';
import InputRadioFieldCombo   from '../input-radio-field-combo';
import InputAdvancedSelect    from '../input-advanced-select';
import CoverEntranceAngleView from '../angle-view/lib/coverEntrance';

class OptionalInformation extends React.Component {
    constructor(props) {
        super(props);

        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    getError() {
        if (this.props.errorMessage) {
            return <div className="cell small-8 xlarge-7">
                <div className="alert callout" id="error-alert">
                    <h5>Error</h5>
                    <p>{this.props.errorMessage}</p>
                </div>
            </div>
        }
    }

    getBrunnenringSelect(t) {
        if (this.props.deckplatte &&
            (this.props.brunnenring600 || this.props.brunnenring800) &&
            this.props.brunnenringConfig
        ) {
            return <InputSelect
                t={t}
                id={this.props.brunnenringConfig.key}
                onChange={this.props.onInputChange}
                value={this.props.brunnenring}
                label={t('Höhe Brunnenring NW{{NW}}', {
                    NW: this.props.brunnenring600 ? 600 : 800
                })}
                options={this.props.brunnenringConfig.options.map(option => {
                    return {
                        key: option.key,
                        label: t(option.label),
                        value: option.value
                    };
                })}
                error={
                    this.props.fieldsWithError &&
                    this.props.fieldsWithError.filter(field => {
                        return field.fieldName === this.props.brunnenringConfig.key
                    })[0]}
                optional={false}
            />;
        }

        return '';
    }

    getBrunnenringTypeSelect(t) {
        if (this.props.brunnenringTypeConfig && this.props.brunnenring &&
            (this.props.brunnenring600 || this.props.brunnenring800)) {
            return <div className="radio-group input--required">
                <h3 className={'label--content'}>{t(this.props.brunnenringTypeConfig.label)}</h3>
                {
                    this.props.brunnenringTypeConfig.options.lose &&
                    <InputRadioFieldCombo
                        onRadioSelectChange={value => {
                            this.props.onInputChangeDestructured(
                                this.props.brunnenringTypeConfig.key, value
                            );
                        }}
                        name={this.props.brunnenringTypeConfig.options.lose.key}
                        type={{
                            key: this.props.brunnenringTypeConfig.options.lose.key,
                            value: this.props.brunnenringTypeConfig.options.lose.label,
                            label: this.props.brunnenringTypeConfig.options.lose.label
                        }}
                        typeSelected={this.props.brunnenringTypeOption}
                        t={t}
                        showInput={false}
                        radioContainerClasses={'small-12 margin-0'}
                        inputContainerClasses={'small-12'}
                        optional={false}
                        showRequiredSign={false}
                    />
                }
                {
                    this.props.brunnenringTypeConfig.options.fest &&
                    <InputRadioFieldCombo
                        onRadioSelectChange={value => {
                            this.props.onInputChangeDestructured(
                                this.props.brunnenringTypeConfig.key, value
                            );
                        }}
                        name={this.props.brunnenringTypeConfig.options.fest.key}
                        type={{
                            key: this.props.brunnenringTypeConfig.options.fest.key,
                            value: this.props.brunnenringTypeConfig.options.fest.label,
                            label: this.props.brunnenringTypeConfig.options.fest.label
                        }}
                        typeSelected={this.props.brunnenringTypeOption}
                        t={t}
                        showInput={false}
                        radioContainerClasses={'small-12 margin-0'}
                        inputContainerClasses={'small-12'}
                        optional={false}
                        showRequiredSign={false}
                    />
                }
            </div>
        }
        return '';
    }

    render() {
        const {t, coverConfig, sealingTapeConfig} = this.props;

        return (
            <div className="comp-optional-information">
                <div className="grid-container">
                    <div className="grid-x">
                        {this.getError()}
                        <div className="cell small-10 xlarge-8">
                            <h2>{t('Optionale Angaben')}</h2>
                        </div>
                    </div>
                </div>

                <div className="grid-container">
                    <div className="grid-x">
                        <div className="cell small-12 large-6">
                            <form>
                                {
                                    this.props.laddersConfig && <div>
                                        <InputSelect
                                            t={t}
                                            id={'leiter'}
                                            onChange={this.props.onInputChange}
                                            label={t(this.props.laddersConfig.label)}
                                            options={this.props.laddersConfig.options.map(option => {
                                                return {
                                                    key: option.key,
                                                    label: t(option.label),
                                                    value: option.value
                                                };
                                            })}
                                            value={this.props.leiter}
                                            error={this.props.fieldsWithError &&
                                            this.props.fieldsWithError
                                                .filter(field => field.fieldName === this.props.laddersConfig.key)[0]}
                                        />
                                        <hr/>
                                    </div>
                                }

                                <div className="checkbox-group">
                                    {
                                        this.props.konusConfig &&
                                        <div className="checkbox">
                                            <input
                                                type={this.props.konusConfig.type}
                                                id={this.props.konusConfig.key}
                                                checked={this.props.konus}
                                                disabled={this.props.konusConfig.disabled || false}
                                                onFocus={this.handleFocus}
                                                onBlur={this.handleBlur}
                                                onChange={this.props.onInputChange}/>
                                            <label
                                                htmlFor={this.props.konusConfig.key}
                                                className={'input--required'}
                                            >
                                                <span className={'label--content'}>
                                                    {t(
                                                        this.props.konusConfig.label,
                                                        {
                                                            NW: this.props.NW,
                                                            interpolation: {escapeValue: false}
                                                        }
                                                    )}
                                                </span>
                                            </label>
                                        </div>
                                    }
                                    {
                                        this.props.deckplatteConfig &&
                                        <div className="checkbox">
                                            <input
                                                type={this.props.deckplatteConfig.type}
                                                id={this.props.deckplatteConfig.key}
                                                checked={this.props.deckplatte}
                                                disabled={this.props.deckplatteConfig.disabled || false}
                                                onFocus={this.handleFocus}
                                                onBlur={this.handleBlur}
                                                onChange={this.props.onInputChange}/>
                                            <label
                                                htmlFor={this.props.deckplatteConfig.key}
                                                className={'input--required'}
                                            >
                                                <span className={'label--content'}>
                                                    {t(
                                                        this.props.deckplatteConfig.label,
                                                        {
                                                            NW: this.props.NW,
                                                            interpolation: {escapeValue: false}
                                                        }
                                                    )}
                                                </span>
                                            </label>
                                        </div>
                                    }
                                </div>

                                {
                                    this.props.coverEntranceConfig &&
                                        <InputSelect
                                            t={t}
                                            id={'coverEntrance'}
                                            onChange={this.props.onInputChange}
                                            label={t(this.props.coverEntranceConfig.label)}
                                            options={this.props.coverEntranceConfig.options.map(option => {
                                                return {
                                                    key: option.key,
                                                    label: t(option.label),
                                                    value: option.value
                                                };
                                            })}
                                            value={this.props.coverEntrance}
                                            error={this.props.fieldsWithError &&
                                            this.props.fieldsWithError
                                                .find(field => (
                                                    field.fieldName === this.props.coverEntranceConfig.key
                                                ))
                                            }
                                            optional={this.props.coverEntranceConfig.optional}
                                        />
                                }

                                <div className="checkbox-group">
                                    {
                                        this.props.brunnenring600Config && this.props.deckplatte === true &&
                                        <InputCheckbox
                                            label={this.props.brunnenring600Config.label}
                                            t={t}
                                            name={this.props.brunnenring600Config.label}
                                            id={this.props.brunnenring600Config.key}
                                            onChange={this.props.onInputChange}
                                            disabled={this.props.brunnenring600Config.disabled || false}
                                            checked={this.props.brunnenring600}
                                            className={''}
                                        />
                                    }
                                    {
                                        this.props.brunnenring800Config && this.props.deckplatte === true &&
                                        <InputCheckbox
                                            label={this.props.brunnenring800Config.label}
                                            t={t}
                                            name={this.props.brunnenring800Config.label}
                                            id={this.props.brunnenring800Config.key}
                                            onChange={this.props.onInputChange}
                                            disabled={this.props.brunnenring800Config.disabled || false}
                                            checked={this.props.brunnenring800}
                                            className={''}
                                        />
                                    }
                                    {this.getBrunnenringSelect(t)}
                                </div>
                                {this.getBrunnenringTypeSelect(t)}
                                {
                                    this.props.belastungsklasseConfig &&
                                    <InputSelect
                                        t={t}
                                        id={this.props.belastungsklasseConfig.key}
                                        onChange={this.props.onInputChange}
                                        value={this.props.belastungsklasse}
                                        label={t(this.props.belastungsklasseConfig.label)}
                                        options={this.props.belastungsklasseConfig.options}
                                        error={this.props.fieldsWithError &&
                                        this.props.fieldsWithError
                                            .filter(field =>
                                                field.fieldName === this.props.belastungsklasseConfig.key)[0]
                                        }
                                        optional={false}
                                    />
                                }
                                {
                                    coverConfig &&
                                    <div className="radio-group input--required">
                                        <h3 className={'label--content'}>{t(coverConfig.label)}</h3>
                                        {
                                            coverConfig.options.unknown_article_number &&
                                            <InputRadioFieldCombo
                                                t={t}
                                                type={{
                                                    value: coverConfig.options.unknown_article_number.value,
                                                    label: coverConfig.options.unknown_article_number.label,
                                                    key: coverConfig.options.unknown_article_number.key
                                                }}
                                                label={coverConfig.options.unknown_article_number.label}
                                                name={coverConfig.options.unknown_article_number.key}
                                                onRadioSelectChange={value => {
                                                    this.props.onInputChangeDestructured(coverConfig.key, value);
                                                }}
                                                typeSelected={this.props.coverOption}
                                                showInput={false}
                                                radioContainerClasses={'small-12 margin-0'}
                                                optional={false}
                                                showRequiredSign={false}
                                            />
                                        }
                                        {
                                            coverConfig.options.known_article_number &&
                                            <InputRadioFieldCombo
                                                t={t}
                                                type={{
                                                    value: this.props.coverArticleNumber,
                                                    label: coverConfig.options.known_article_number.label,
                                                    key: coverConfig.options.known_article_number.key
                                                }}
                                                typeSelected={this.props.coverOption}
                                                name={coverConfig.options.known_article_number.key}
                                                onRadioSelectChange={value => {
                                                    this.props.onInputChangeDestructured(coverConfig.key, value);
                                                }}
                                                showInput={false}
                                                onInputChange={(key, id, value) => {
                                                    this.props.onInputChangeDestructured(
                                                        coverConfig.options.known_article_number.input_key,
                                                        value
                                                    );
                                                }}
                                                radioContainerClasses={'small-12 margin-0'}
                                                inputContainerClasses={'small-12'}
                                                inputSize={'large'}
                                                inputType={'text'}
                                                hasError={
                                                    this.props.fieldsWithError &&
                                                    !!(this.props.fieldsWithError
                                                        .find(field =>
                                                            field.field ===
                                                            coverConfig.options.known_article_number.input_key))
                                                }
                                                errorMessage={
                                                    this.props.fieldsWithError &&
                                                    this.props.fieldsWithError
                                                        .find(field =>
                                                            field.field ===
                                                            coverConfig.options.known_article_number.input_key) &&
                                                    this.props.fieldsWithError
                                                        .find(field =>
                                                            field.field ===
                                                            coverConfig.options.known_article_number.input_key).message
                                                }
                                                optional={false}
                                                showRequiredSign={false}
                                            />
                                        }
                                        {
                                            coverConfig.options.known_article_number &&
                                            <InputAdvancedSelect
                                                suggestions={this.props.coverArticles}
                                                noOptionsMessage={''}
                                                pleaseChoose={t('Bitte wählen')}
                                                disabled={
                                                    this.props.coverOption !== 'known_article_number'
                                                }
                                                options={[]}
                                                t={t}
                                                onChange={(key) => {
                                                    this.props.onInputChangeDestructured(
                                                        key.item.id,
                                                        'coverArticleNumber'
                                                    );
                                                }}
                                                value={this.props.selectedCoverArticle.productId}
                                                error={
                                                    this.props.fieldsWithError &&
                                                    this.props.fieldsWithError
                                                        .find(field =>
                                                            field.field ===
                                                            coverConfig.options.known_article_number.input_key) &&
                                                    this.props.fieldsWithError
                                                        .find(field =>
                                                            field.field ===
                                                            coverConfig.options.known_article_number.input_key).message}
                                                name={coverConfig.options.known_article_number.key}
                                                showSuggestionsOnFocus={true}
                                                suggestionValueKey={'id'}
                                                sortByProperty={'productId'}
                                                keyToSearchFor={'productId'}
                                            />
                                        }

                                        <a
                                            href={'https://aco.ch/shop/de/s/bauelemente/bauguss/'}
                                            className={'button button-link'}
                                            target={'_blank'}
                                            rel={'noreferrer'}
                                        >
                                            {t('Produktfinder für Schachtabdeckungen')}
                                        </a>

                                    </div>
                                }
                                {
                                    this.props.showTitleBlock &&
                                    <InputCheckbox
                                        label={this.props.titleConfig.label}
                                        t={t}
                                        value={this.props.titleBlockOption}
                                        disabled={
                                            this.props.coverOption !== 'known_article_number'
                                        }
                                        name={this.props.titleConfig.label}
                                        id={this.props.titleConfig.key}
                                        onChange={this.props.onInputChange}
                                        checked={this.props.setTitleBlock}
                                    />
                                }

                                {
                                    this.props.showTitleBlock && this.props.setTitleBlock &&
                                    <div>
                                        <InputSelect
                                            t={t}
                                            id={'titleBlocks'}
                                            onChange={this.props.onInputChange}
                                            value={this.props.titleBlockOption}
                                            label={t('Schriftschild:')}
                                            options={Object.values(this.props.titleBlocks).map(option => {
                                                return {
                                                    key: option.articleNumber,
                                                    label: t(option.name),
                                                    value: option.articleNumber
                                                };
                                            })}
                                            error={this.props.fieldsWithError &&
                                            this.props.fieldsWithError
                                                .filter(field =>
                                                    field.fieldName === this.props.belastungsklasseConfig.key)[0]
                                            }
                                            optional={false}
                                        />

                                        <a
                                            href={
                                                'https://www.aco.ch/shop/de/bauelemente/zubehoer-zu-aco-bauguss/beschriftungsschild.html'  // eslint-disable-line
                                            }
                                            className={'button button-link titleBlockButton'}
                                            target={'_blank'}
                                            rel={'noreferrer'}
                                        >
                                            {t('Produktfinder für Schriftschilder')}
                                        </a>
                                    </div>
                                }
                                {
                                    sealingTapeConfig &&
                                    <div className="radio-group input--required">
                                        <h3 className={'label--content'}>{t(sealingTapeConfig.label)}</h3>
                                        {
                                            sealingTapeConfig.options.ohne_sika_swell &&
                                            <InputRadioFieldCombo
                                                onRadioSelectChange={value => {
                                                    this.props.onInputChangeDestructured(sealingTapeConfig.key, value);
                                                }}
                                                name={sealingTapeConfig.options.ohne_sika_swell.key}
                                                type={{
                                                    key: sealingTapeConfig.options.ohne_sika_swell.key,
                                                    value: sealingTapeConfig.options.ohne_sika_swell.label,
                                                    label: sealingTapeConfig.options.ohne_sika_swell.label
                                                }}
                                                typeSelected={this.props.sealingTapeOption || 'ohne_sika_swell'}
                                                t={t}
                                                showInput={false}
                                                radioContainerClasses={'small-12 margin-0'}
                                                inputContainerClasses={'small-12'}
                                                optional={false}
                                                showRequiredSign={false}
                                            />
                                        }
                                        {
                                            sealingTapeConfig.options.am_schachtkoerper &&
                                            <InputRadioFieldCombo
                                                onRadioSelectChange={value => {
                                                    this.props.onInputChangeDestructured(sealingTapeConfig.key, value);
                                                }}
                                                name={sealingTapeConfig.options.am_schachtkoerper.key}
                                                type={{
                                                    key: sealingTapeConfig.options.am_schachtkoerper.key,
                                                    value: sealingTapeConfig.options.am_schachtkoerper.label,
                                                    label: sealingTapeConfig.options.am_schachtkoerper.label
                                                }}
                                                typeSelected={this.props.sealingTapeOption}
                                                t={t}
                                                showInput={false}
                                                radioContainerClasses={'small-12 margin-0'}
                                                inputContainerClasses={'small-12'}
                                                optional={false}
                                                showRequiredSign={false}
                                            />
                                        }
                                        {
                                            sealingTapeConfig.options.am_schachtdeckplatte &&
                                            <InputRadioFieldCombo
                                                onRadioSelectChange={value => {
                                                    this.props.onInputChangeDestructured(sealingTapeConfig.key, value);
                                                }}
                                                name={sealingTapeConfig.options.am_schachtdeckplatte.key}
                                                type={{
                                                    key: sealingTapeConfig.options.am_schachtdeckplatte.key,
                                                    value: sealingTapeConfig.options.am_schachtdeckplatte.label,
                                                    label: sealingTapeConfig.options.am_schachtdeckplatte.label
                                                }}
                                                typeSelected={this.props.sealingTapeOption}
                                                t={t}
                                                showInput={false}
                                                radioContainerClasses={'small-12 margin-0'}
                                                inputContainerClasses={'small-12'}
                                                optional={false}
                                                showRequiredSign={false}
                                            />
                                        }
                                        {
                                            sealingTapeConfig.options.am_brunnenring && this.props.brunnenring &&
                                            (this.props.brunnenring600 || this.props.brunnenring800) &&
                                            <InputRadioFieldCombo
                                                onRadioSelectChange={value => {
                                                    this.props.onInputChangeDestructured(sealingTapeConfig.key, value);
                                                }}
                                                name={sealingTapeConfig.options.am_brunnenring.key}
                                                type={{
                                                    key: sealingTapeConfig.options.am_brunnenring.key,
                                                    value: sealingTapeConfig.options.am_brunnenring.label,
                                                    label: sealingTapeConfig.options.am_brunnenring.label
                                                }}
                                                typeSelected={this.props.sealingTapeOption}
                                                t={t}
                                                showInput={false}
                                                radioContainerClasses={'small-12 margin-0'}
                                                inputContainerClasses={'small-12'}
                                                optional={false}
                                                showRequiredSign={false}
                                            />
                                        }
                                    </div>
                                }
                                {
                                    this.props.kommentarConfig &&
                                    <div className={'cell'}>
                                        <InputTextarea
                                            t={t}
                                            id={this.props.kommentarConfig.key}
                                            label="Bemerkungen"
                                            value={this.props.opt_comment || ''}
                                            onChange={this.props.onInputCommentChange}
                                        />
                                    </div>
                                }
                            </form>
                        </div>
                        <div className="cell show-for-large large-6">
                            {
                                this.props.coverEntranceConfig &&
                                <CoverEntranceAngleView
                                    selection={this.props.coverEntrance}
                                />
                            }
                        </div>
                    </div>
                </div>

                <div className="main-content-footer">
                    <ButtonNavigation
                        t={t}
                        history={this.props.history}
                        enableNext={this.props.allowNext}
                        location={this.props.location}
                        eventHandler={this.props.eventHandler}/>
                </div>
            </div>
        )
    }

    handleFocus(e) {
        e.currentTarget.parentNode.classList.add('focused');
    }

    handleBlur(e) {
        e.currentTarget.parentNode.classList.remove('focused');
    }
}

OptionalInformation.propTypes = {
    updateComment: PropTypes.func,
    opt_comment: PropTypes.string,
    kommentarConfig: PropTypes.object,
    onInputChange: PropTypes.func,
    onInputChangeDestructured: PropTypes.func,
    onInputCommentChange: PropTypes.func,
    onCalculatePrice: PropTypes.func,
    belastungsklasseConfig: PropTypes.object,
    eventHandler: PropTypes.any,
    fieldsWithError: PropTypes.any,
    history: PropTypes.any,
    konusConfig: PropTypes.object,
    brunnenringConfig: PropTypes.object,
    brunnenring600Config: PropTypes.object,
    brunnenring800Config: PropTypes.object,
    laddersConfig: PropTypes.any,
    leiter: PropTypes.any,
    leiterAlluminium: PropTypes.bool,
    leiterAlluminiumConfig: PropTypes.object,
    leiterEdelstahl: PropTypes.bool,
    belastungsklasse: PropTypes.string,
    konus: PropTypes.bool,
    brunnenring: PropTypes.string,
    brunnenring600: PropTypes.bool,
    brunnenring800: PropTypes.bool,
    deckplatte: PropTypes.bool,
    deckplatteConfig: PropTypes.object,
    allowNext: PropTypes.bool,
    allowPriceCalculation: PropTypes.bool,
    errorMessage: PropTypes.string,
    konusSelectionDisaled: PropTypes.bool,
    leiterEdelstahlConfig: PropTypes.object,
    location: PropTypes.any,
    t: PropTypes.any,
    NW: PropTypes.string,
    coverOption: PropTypes.string,
    coverArticleNumber: PropTypes.string,
    coverConfig: PropTypes.object,
    brunnenringTypeConfig: PropTypes.object,
    brunnenringTypeOption: PropTypes.string,
    sealingTapeConfig: PropTypes.object,
    sealingTapeOption: PropTypes.string,
    selectedCoverArticle: PropTypes.object,
    coverArticles: PropTypes.any,
    showTitleBlock: PropTypes.bool,
    titleConfig: PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
    }),
    setTitleBlock: PropTypes.bool,
    titleBlocks: PropTypes.any,
    titleBlockOption: PropTypes.string,
    coverEntranceConfig: PropTypes.object,
    coverEntrance: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};


export default OptionalInformation;