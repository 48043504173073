'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import FetchUtils from './../../advancd-fetch';
import translate from '../translate';
import cfg from '../../conf.js';

class Restart extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        fetch(cfg.api.restartApiUrlTemplate(),
            {
                method: 'POST',
                credentials: 'include'
            })
            .then((response) => {
                return FetchUtils.checkStatus(response);
            })
            .then(response => {
                if (response.next) return this.props.history.push(response.next);
            });
    }

    render() {
        const {t} = this.props;

        return (<h1>{t('Gleich gehts weiter.')}</h1>);
    }
}

Restart.propTypes = {
    t: PropTypes.func.isRequired,
    history: PropTypes.any.isRequired
};

export default (translate(Restart));