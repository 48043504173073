import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'sfp-react-modal';

import ProtocolText from './../../protocol/lib/protocol-text';

class ProtocolModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            protocolModalIsOpened: true
        };
    }

    render() {
        const {t} = this.props;

        return (
            <Modal
                id={`protocol-modal-${this.props.listId}`}
                isOpen={this.props.opened}
                escClose={true}
                backgroundClickClose={false}
                customClasses={'protocol-modal'}
                closeHandler={() => this.props.closeHandler(this.props.listId)}>
                <div className="grid-container">
                    <span onClick={() => this.props.closeHandler(this.props.listId)} className="close-container">
                        <div className="close"/>
                    </span>
                    <div className="grid-x grid-margin-x">
                        <div className="product-container">
                            <ProtocolText
                                t={t}
                                config={{
                                    data: this.props.data,
                                    steps: {
                                        productSelector: true,
                                        mainMeasurementSelector: true,
                                        floorTypeSelector: true,
                                        typeOfDimension: true,
                                        outfallSelector: true,
                                        inletSelector: true,
                                        optionalInformation: true
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

ProtocolModal.propTypes = {
    t: PropTypes.func,
    opened: PropTypes.bool,
    closeHandler: PropTypes.func,
    data: PropTypes.object,
    listId: PropTypes.string
};

export default ProtocolModal;