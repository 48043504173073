import fetchAndCheck from '../../lib/advanced-fetch';
import cfg from '../../conf';

export function requestPasswordReset(language, username) {
    return {
        type: 'reset-password-request',
        payload: fetchAndCheck(`${cfg.api.passwordResetRequest(language)}`, {
            method: 'POST',
            body: JSON.stringify({username: username}),
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
}