'use strict';

const Step = require('./../../steps/step');
const Measurement = require('./measurement');
const FloorType = require('./floortype');
const config = require('./../config/komplettschacht-configuration.json');

const _ = require('lodash');

const tableInfoText = (t) => {
    return `<table class="table-info-text">
              <tr>
                <td>${t('NW')}<sub>${t('Einstieg')}</sub></td>
                <td>= 600mmm</td>
              </tr>
              <tr>
                <td>h<sub>k</sub>&nbsp;</td>
                <td>= 400 mm NW800, 1000</td>
              </tr>
              <tr>
                <td/>
                <td>= 600 mm NW900/1100, 1200/1500</td>
              </tr>
              <tr>
                <td>${t('HD')}</td>
                <td>= ${t('Höhe für den Einbau der Abdeckung und evtl. des Betonsockels inkl ' +
        'Reserve für Gefällsanpassungen.')}</td>
              </tr>
              <tr>
                <td/>
                <td>${t('Berechnung je nach Belastungsklasse')}</td>
              </tr>
              <tr>
                <td>${t('Adapter')}</td>
                <td>= ${t('Höhe')} 160 mm</td>
              </tr>
            </table><br/>
        `;
};

class StepTypeDimension extends Step {

    constructor(props) {
        props = props || {};

        props.config = props.config || config;
        props.stepName = 'typeOfDimension';

        super(props);

        this.measurement = new Measurement();
        this.floorType = new FloorType();
    }

    precondition(data) {
        if (!this.measurement.isComplete(data)) return this.measurement.getPathName(data);
        if (!this.floorType.isComplete(data)) return this.floorType.getPathName(data);

        return null;
    }

    getStepConfig(data) {
        let {floortype} = this.floorType.getStepData(data);
        let config = _.cloneDeep(super.getStepConfig());
        if (
            'adapter_für_ortsbeton_und_keilgleitdichtung' === floortype ||
            'adapter_für_keilgleitdichtung' === floortype
        ) {

            let minMaxL = this._getMinMax(data);

            if (minMaxL) { // Only show L form if we have data to validate it, otherwise we show HS,TS...
                config.types = [
                    {
                        'key': 'dimension_l',
                        'label': 'L (Länge Schachtrohr)',
                        'unit': 'mm',
                        'type': 'checkbox',
                        'value': ''
                    }
                ];
            }
        }

        config.text = tableInfoText(this.t);

        if ('adapter_für_ortsbeton_und_keilgleitdichtung' === floortype) {
            config.image = '/static/assets/images/04.png';
        } else if ('adapter_für_keilgleitdichtung' === floortype) {
            config.image = '/static/assets/images/05.png';
        } else {
            config.image = '/static/assets/images/06.png';
        }

        this.addStepInputToData(config.input, data);

        return config;
    }


    validate(data, sessionData) {
        if (data == null) return false;
        if (data.typeDimension == null) return false;
        if (data.valueDimension == null || data.valueDimension === '') return false;
        if (data.valueDimension != null && parseInt(data.valueDimension) < 0) return false;
        if (data.typeDimension === 'hohenkote_ab_ok_schacht') {
            if (data.secondValueDimension == null || data.secondValueDimension === '') return false;
            if (data.secondValueDimension != null && parseInt(data.secondValueDimension) < 0) return false;
        }

        return this.getInvalidsFieldsMessages(data, sessionData).length === 0;
    }

    isComplete(data) {
        return this.validate(data, data);
    }


    getInvalidsFieldsMessages(data, sessionData) {
        let invalidFields = [];

        if (data.typeDimension == null) {
            invalidFields.push({fieldName: 'typeDimension', message: this.t('Dies ist ein Pflichtfeld.')});
            return invalidFields;
        }

        if (data.valueDimension == null || data.valueDimension === '' || isNaN(parseFloat(data.valueDimension)))
            invalidFields.push({fieldName: data.typeDimension, message: this.t('Dies ist ein Pflichtfeld.')});

        if (data.typeDimension === 'hohenkote_ab_ok_schacht') {
            if (data.secondValueDimension == null || data.secondValueDimension === '') {
                invalidFields.push({fieldName: data.typeDimension, message: this.t('Dies ist ein Pflichtfeld.')});
            } else {
                if (parseInt(data.secondValueDimension) < 0)
                    invalidFields.push(
                        {
                            fieldName: data.typeDimension,
                            message: this.t('Die Eingabe muss eine positive Zahl sein.')
                        }
                    );
            }
        }

        if (data.valueDimension != null && data.valueDimension !== '') {
            if (parseInt(data.valueDimension) < 0)
                invalidFields.push(
                    {
                        fieldName: data.typeDimension,
                        message: this.t('Die Eingabe muss eine positive Zahl sein.')
                    }
                );
        }

        if (invalidFields.length > 0) return invalidFields;

        let {HS, TS, L} = this.getStepData(data);

        let minMaxL = this._getMinMax(sessionData);

        const message = 'Der Wert muss im Bereich liegen';

        if (L != null && (L < minMaxL.min || L > minMaxL.max)) {
            invalidFields.push({
                fieldName: data.typeDimension,
                message: this.t(message, {min: minMaxL.min, max: minMaxL.max})
            });
        }

        if (HS != null && (HS < minMaxL.min || HS > minMaxL.max)) {
            invalidFields.push({
                fieldName: data.typeDimension,
                message: this.t(message, {min: minMaxL.min, max: minMaxL.max})
            });
        }

        if ((TS != null && data.secondValueDimension == null) && (TS < minMaxL.min || TS > minMaxL.max)) {
            invalidFields.push({
                fieldName: data.typeDimension,
                message: this.t(message, {min: minMaxL.min, max: minMaxL.max})
            });
        }

        if ((TS != null && data.secondValueDimension != null) && (TS < minMaxL.min || TS > minMaxL.max)) {
            invalidFields.push({
                fieldName: data.typeDimension,
                message: this.t('Differenz muss im Bereich liegen', {min: minMaxL.min, max: minMaxL.max, ts: TS})
            });
        }

        return invalidFields;
    }


    addStepInputToData(data, input) {
        data.typeDimension = input.typeDimension;
        data.valueDimension = input.valueDimension;
        data.secondValueDimension = input.secondValueDimension;
        data.hdDimension = input.hdDimension;

        return data;
    }

    removeDataOfStep(session) {
        _.set(session, ['data', 'typeDimension'], null);
        _.set(session, ['data', 'valueDimension'], null);
        _.set(session, ['data', 'secondValueDimension'], null);
        _.set(session, ['data', 'hdDimension'], null);
        _.set(session, ['steps', super.getStepConfig().previousStep], false);
        _.set(session, ['steps', this.getStepName()], false);
    }

    /**
     *
     * @param data
     * @return {{HS: number, TS: number, schachtMuM: number, auslaufMuM: number}}
     */
    getStepData(data) {

        let HS, TS, HD, schachtMuM, auslaufMuM, L, noMuM, HK, schachtMuMAsMeter, auslaufMuMAsMeter;

        if ('hs_hohe_schacht' === data.typeDimension) {
            HS = parseInt(data.valueDimension);
            noMuM = true;
        } else if ('ts_tiefe_schacht' === data.typeDimension) {
            TS = parseInt(data.valueDimension);
            HD = data.hdDimension ? parseInt(data.hdDimension) : null;
            noMuM = true;
        } else if ('hohenkote_ab_ok_schacht' === data.typeDimension) {
            schachtMuMAsMeter = parseFloat(data.valueDimension);
            auslaufMuMAsMeter = parseFloat(data.secondValueDimension);
            schachtMuM = parseFloat((schachtMuMAsMeter * 1000.0).toFixed(3)); // this is mm above sea level
            auslaufMuM = parseFloat((auslaufMuMAsMeter * 1000.0).toFixed(3));
            TS = (schachtMuM - auslaufMuM);
            HK = TS;
            HD = data.hdDimension ? parseInt(data.hdDimension) : null;
            noMuM = false;
        } else if ('dimension_l' === data.typeDimension) {
            L = parseFloat(data.valueDimension);
            noMuM = true;
        } else {
            throw new Error('Unknown type of dimension :' + data.typeDimension);
        }

        return {
            HS: HS,
            TS: TS,
            HD: HD,
            schachtMuM: schachtMuM,
            auslaufMuM: auslaufMuM,
            schachtMuMAsMeter: schachtMuMAsMeter,
            auslaufMuMAsMeter: auslaufMuMAsMeter,
            L: L,
            noMuM: noMuM,
            HK: HK
        };
    }

    next(data) {
        let {floortype, productId} = this.floorType.getStepData(data);

        if (
            'adapter_für_ortsbeton_und_keilgleitdichtung' === floortype ||
            'adapter_für_keilgleitdichtung' === floortype
        ) {
            return '/komplettschacht/optional-information';

        } else if ('boden_mit_gerinne' === floortype) {
            if (productId != null)
                return '/komplettschacht/optional-information';
            return '/komplettschacht/outfall';
        }

        return '/komplettschacht/optional-information';
    }


    /**
     *
     * @param sessionData
     * @private
     */
    _getMinMax(sessionData) {
        let min, max;
        let NW = this.measurement.getStepData(sessionData);

        min = Math.min.apply(
            null,
            _.get(config, ['outfallSelector', 'diametersConfig', NW, 'min']).filter(Boolean)
        );
        max = Math.max.apply(
            null,
            _.get(config, ['outfallSelector', 'diametersConfig', NW, 'max']).filter(Boolean)
        );

        return {
            min: min,
            max: max
        };
    }
}

module.exports = StepTypeDimension;