const isDeleted = (input) => !!input.deleted;
const missingPipetype = (input) => !input.pipetype;
const missingDiameter = (input) => !input.diameter;
const missingWinkel = (input) => !input.winkel;
const missingAbstandHohenkote = (input) => !input[input.abstandHohenkoteSelected];
const missingPipeSerie = (input, hasPipeSeriesConfig) => (hasPipeSeriesConfig && !input.pipeseries);

const pipetypeIsNoInlet = (input) => input.pipetype === 'no_inlet';

const hasMissingInformation = (input, hasPipeSeriesConfig) =>
    missingPipetype(input)
    || missingDiameter(input)
    || missingWinkel(input)
    || missingAbstandHohenkote(input)
    || missingPipeSerie(input, hasPipeSeriesConfig);




export function inputIsIncomplete(input, hasPipeSeriesConfig) {
    return !isDeleted(input)
        && !pipetypeIsNoInlet(input)
        && hasMissingInformation(input, hasPipeSeriesConfig);
}
