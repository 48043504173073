module.exports = {
    user: {
        ID: 'id',
        FIRST_NAME: 'first_name',
        LAST_NAME: 'last_name',
        STREET: 'street',
        CITY: 'city',
        PHONE: 'phone',
        EMAIL: 'email',
        PASSWORD: 'password',
        EMAIL_VERIFIED: 'email_verified',
        AGB_ACCEPTED: 'agb_accepted',
        DELETED: 'deleted',
        CREATED_AT: 'created_at',
        MODIFIED_AT: 'modified_at',
        TYPE: 'type'
    },
    project: {
        ID: 'id',
        CUSTOMER_NUMBER: 'customer_number',
        COMPANY: 'company',
        FIRST_NAME: 'first_name',
        LAST_NAME: 'last_name',
        STREET: 'street',
        CITY: 'city',
        EMAIL: 'email',
        PHONE: 'phone',
        DELIVERY_DATE: 'delivery_date',
        DELIVERY_ADDRESS: 'delivery_address',
        COMMENT: 'comment',
        DATE_ORDER: 'date_order',
        DATE_OFFER: 'date_offer',
        STATUS: 'status',
        PROJECT_NAME: 'project_name',
        USER: 'user',
        DELETED: 'deleted',
        CREATED_AT: 'created_at',
        MODIFIED_AT: 'modified_at'
    },
    configuration: {
        ID: 'id',
        UID: 'uid',
        PRODUCT: 'product',
        PRODUCTID: 'productId',
        MAINMEASUREMENT: 'mainMeasurement',
        FLOOR: 'floor',
        HS: 'HS',
        TS: 'TS',
        COVER_ARTICLE_NUMBER: 'coverArticleNumber',
        TITLE_BLOCK_NUMBER: 'titleBlockNumber',
        TYPE_DIMENSION: 'typeDimension',
        HEIGHTABOVEDATUMTOP: 'heightAboveDatumTop',
        HEIGHTABOVEDATUMBOTTOM: 'heightAboveDatumBottom',
        KONUS: 'konus',
        WEIGHTCLASS: 'weightClass',
        LADDER: 'ladder',
        PRICE: 'price',
        WEIGHT: 'weight',
        CONFIGURATION_NAME: 'configuration_name',
        MATERIAL: 'material',
        DIAMETER: 'diameter',
        PIPESERIES: 'pipeseries',
        FALL: 'fall',
        DIFFERENCE_TO_OUTFALL: 'difference_to_outfall',
        HEIGHT_ABOVE_DATUM: 'height_above_datum',
        COMMENT: 'comment',
        OPT_COMMENT: 'opt_comment',
        OFFER_STATUS: 'offerStatus',
        BRUNNENRING: 'brunnenring',
        BRUNNENRING_SIZE: 'brunnenringSize',
        PROJECT: 'project',
        DELETED: 'deleted',
        CREATED_AT: 'created_at',
        MODIFIED_AT: 'modified_at'
    }
};