'use strict';

import React        from 'react';
import PropTypes    from 'prop-types';
import ResetConfirm from '../reset-confirm/index';
import translate    from '../translate';
import Modal        from 'sfp-react-modal';
import 'whatwg-fetch';

class ButtonNavigation extends React.Component {
    constructor(props) {
        super(props);

        this.eventHandler = props.eventHandler;

        this.state = {
            isResetModalOpen: false
        };

        this.resetAvailable = this.resetAvailable.bind(this);
        this.triggerNext = this.triggerNext.bind(this);
        this.triggerBack = this.triggerBack.bind(this);
        this.reset = this.reset.bind(this);
        this.abortReset = this.abortReset.bind(this);
        this.openResetModal = this.openResetModal.bind(this);
    }

    componentDidMount() {
        this.props.eventHandler.trigger('protocol');
    }

    renderResetModal() {
        const {t} = this.props;

        return <Modal
            id={'modal-reset'}
            isOpen={this.state.isResetModalOpen}
            escClose={false}
            backgroundClickClose={true}
            closeHandler={this.abortReset}
        >
            <ResetConfirm
                t={t}
                id={'modal-reset'}
                resetButtonLabel={'Ja, zurücksetzen'}
                closebuttonLabel={'Nein, abbrechen'}
                onResetButtonClicked={this.reset}
                onAbortReset={this.abortReset}
                message={
                    t('Alle Daten gehen verloren. Sind Sie sicher, dass sie den Konfigurator zurücksetzen wollen?')
                }
            />
        </Modal>
    }

    render() {
        const {t} = this.props;
        const next = <button className="button button--red" onClick={this.triggerNext}
            disabled={this.props.enableNext ? '' : 'disabled'}>{t('Weiter')}</button>;

        const reset = this.resetAvailable() ?
            <button className="button" onClick={this.openResetModal}>{t('Zurücksetzen')}</button> : null;

        const back = <button className="button" onClick={this.triggerBack}>{t('Zurück')}</button>;

        return (
            <nav className="grid-container">
                <ul className="button-bar">
                    {
                        !this.props.hideBackButton && <li className="button-bar__item">{back}</li>
                    }
                    <li className="button-bar__item">{next}</li>
                    <li className="button-bar__item">{reset}</li>
                </ul>

                {this.state.isResetModalOpen &&
                    this.renderResetModal()}
            </nav>
        );
    }

    triggerNext() {
        this.eventHandler.trigger('next-clicked');
    }

    triggerBack() {
        this.eventHandler.trigger('back-clicked');
    }

    resetAvailable() {
        return this.props.location.pathname !== '/';
    }

    abortReset() {
        this.setState({isResetModalOpen: false});
    }

    reset() {
        fetch('/api/restart', {
            method: 'POST',
            credentials: 'include'
        })
            .then((response) => {
                response.json().then((body) => {
                    this.eventHandler.trigger('protocol');
                    this.abortReset();

                    if (body.next) {
                        this.props.history.push(body.next);
                    }
                });
            });
    }

    openResetModal() {
        this.setState({isResetModalOpen: true});
    }
}

ButtonNavigation.propTypes = {
    hideBackButton: PropTypes.bool,
    history: PropTypes.object,
    location: PropTypes.object,
    enableNext: PropTypes.bool,
    eventHandler: PropTypes.object,
    t: PropTypes.func
};

export default (translate(ButtonNavigation));