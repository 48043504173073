'use strict';

import {combineReducers}             from 'redux';
import authorisedReducer             from '../components/authorisation/reducers';
import {projectInfoDataReducer}      from '../components/project-infos/reducers';
import {userReducer}                 from '../components/register/reducers';
import {changePasswordReducer}       from '../components/password-reset/reducers';
import {resetPasswordRequestReducer} from '../components/password-reset-email-request/reducers';


export function createReducer(initState, handlers) {
    return function reducer(state, action) {
        if (!state) return initState;

        if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
            return handlers[action.type](state, action);
        } else {
            return state;
        }
    };
}

export default combineReducers({
    shared: authorisedReducer,
    projectInfo: projectInfoDataReducer,
    userReducer,
    changePassword: changePasswordReducer,
    resetPasswordRequest: resetPasswordRequestReducer
});
