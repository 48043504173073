'use strict';

import React from 'react';
import PropTypes from 'prop-types';

class InputRadioButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isFocused: false,
        };

        this.radioInputRef = React.createRef(); // Create a ref for the radio input
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    componentDidMount() {
        this.updateEventListeners('add');
    }

    componentWillUnmount() {
        this.updateEventListeners('remove');
    }

    updateEventListeners(action) {
        const radioInput = this.radioInputRef.current;
        if (radioInput) {
            const method = action === 'add' ? 'addEventListener' : 'removeEventListener';
            radioInput[method]('focus', this.handleFocus);
            radioInput[method]('blur', this.handleBlur);
        }
    }

    render() {
        const {t} = this.props;

        const { isFocused } = this.state;
        const radioClass = `radio ${isFocused ? 'focused' : ''}`;

        return (
            <div className={radioClass}>
                <input type="radio"
                    name={this.props.name}
                    value={this.props.value}
                    id={this.props.id}
                    checked={this.props.checked ? 'checked' : ''}
                    onChange={this.props.click}
                    ref={this.radioInputRef}
                />
                <label htmlFor={this.props.id}>{t(this.props.label)}</label>
            </div>
        )
    }

    handleFocus() {
        this.setState({ isFocused: true });
    }

    handleBlur() {
        this.setState({ isFocused: false });
    }
}

InputRadioButton.propTypes = {
    t: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.string,
    checked: PropTypes.bool,
    click: PropTypes.func,
    label: PropTypes.string
};

export default InputRadioButton;