'use strict';

import React     from 'react';
import PropTypes from 'prop-types';
import 'whatwg-fetch';

import FetchUtils from './../../advancd-fetch';

import Measurement from './../measurement';

class MeasurementData extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            input: {
                measurement: null
            },
            rightImageSrc: '',
            measurements: [],
            allowNext: false
        };

        this.load = this.load.bind(this);
        this.update = this.update.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.allowNext = this.allowNext.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
        this.onBack = this.onBack.bind(this);
        this.stepId = 'mainMeasurementSelector';

        // pare this. because somehow we come through the constructor relatively often
        this.props.eventHandler.unbind('next-clicked', this.handleNext);
        this.props.eventHandler.bind('next-clicked', this.handleNext);
        this.props.eventHandler.unbind('back-clicked', this.onBack);
        this.props.eventHandler.bind('back-clicked', this.onBack);
    }

    _handleKeyDown(event) {
        let ENTER_CODE = 13;

        if (event.keyCode === ENTER_CODE) {
            event.preventDefault();

            if (this.allowNext(this.state)) {
                this.props.eventHandler.trigger('next-clicked');
            }

            return false;
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this._handleKeyDown);
    }

    componentDidMount() {
        document.addEventListener('keydown', this._handleKeyDown);
        this.props.eventHandler.trigger('protocol');
        this.props.toggleProgressBar(this.props.showProgressBar);
        this.props.toggleSideProtocol(this.props.showSideProtocol);
        this.load();
    }


    handleNext() {
        this.update();
    }

    allowNext(state) {
        return state.input.measurement != null;
    }

    load() {
        fetch(`/api${this.props.location.pathname}`, {credentials: 'include'})
            .then((response) => {
                return FetchUtils.checkStatus(response);
            })
            .then(response => {
                this.props.eventHandler.trigger('update-progress-bar', {id: this.stepId, isCompleted: false});

                if (response.next) {
                    this.props.history.push(response.next);
                    return Promise.reject(new Error('done'));
                }

                if (response.inputs) {
                    this.setState(prev => {
                        prev.measurements = response.inputs;
                        prev.input.measurement = response.input.measurement;
                        prev.allowNext = this.allowNext(prev);
                    });
                }

                if (response.rightImage) {
                    this.setState({rightImageSrc: response.rightImage.src})
                }
            })
            .catch(err => {
                if (err.message !== 'done') {
                    console.error(err);

                    this.props.history.push('/error');
                }
            });
    }

    update() {
        fetch(`/api${this.props.location.pathname}`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                measurement: this.state.input.measurement
            })
        })
            .then((result) => {
                result.json().then(body => {
                    this.props.history.push(body.next);
                });
            })
    }

    onBack() {
        fetch(`/api${this.props.location.pathname}/back`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((result) => {
                result.json().then(body => {
                    this.props.history.push(body.previous);
                });
            })
            .catch(console.error);
    }


    onUpdate(value) {
        this.setState((prev) => {
            value = parseInt(value);

            prev.input.measurement = value;
            prev.allowNext = !!value;

            return prev;
        });
    }


    render() {
        const {t} = this.props;
        return (
            <Measurement
                t={t}
                eventHandler={this.props.eventHandler}
                location={this.props.location}
                history={this.props.history}

                update={this.onUpdate}
                options={this.state.measurements}
                value={this.state.input.measurement}

                activeId={this.state.input.measurement}
                allowNext={this.state.allowNext}
                rightImageSrc={this.state.rightImageSrc}
            />
        );
    }
}

MeasurementData.propTypes = {
    t: PropTypes.func,
    eventHandler: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    showSideProtocol: PropTypes.bool,
    showProgressBar: PropTypes.bool,
    toggleProgressBar: PropTypes.func,
    toggleSideProtocol: PropTypes.func
}

export default MeasurementData;
