import React from 'react';
import PropTypes from 'prop-types';
const moment = require('moment');

class UserOverview extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {t} = this.props;

        return (
            <div className="user-overview">
                {!this.props.users.length &&
                    <div>{t('Es konnten keine Benutzer gefunden werden')}</div>
                }
                {this.props.users && this.props.users.length > 0 &&
                    <table>
                        <thead>
                            <tr>
                                <th>{t('Vorname')}</th>
                                <th>{t('Name')}</th>
                                <th>{t('E-Mail')}</th>
                                <th>{t('Anzahl Projekte')}</th>
                                <th>{t('Anzahl Konfigurationen')}</th>
                                <th>{t('Letzte Aktivität')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.users.map((user) => {
                                return <tr className="users" key={user.email}>
                                    <td width="215">{user.first_name}</td>
                                    <td width="215">{user.last_name}</td>
                                    <td width="215">{user.email}</td>
                                    <td>{user.project_count}</td>
                                    <td>{user.configuration_count}</td>
                                    <td>{user.latest_activity &&
                                            moment(user.latest_activity).format('DD.MM.YYYY hh:mm')}
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                }
            </div>
        )
    }
}

UserOverview.propTypes = {
    t: PropTypes.func.isRequired,
    history: PropTypes.any.isRequired,
    users: PropTypes.array.isRequired,
};

export default UserOverview;
