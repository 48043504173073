'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import translate from '../translate';
import Modal from 'sfp-react-modal';
import InputField from '../input-field'
import * as Actions from './actions';
import {connect} from 'react-redux';
import merge from 'lodash.merge';
import InputCheckbox from '../input-checkbox';

class Register extends React.Component {
    constructor(props) {
        super(props);

        this.closeModalConfirm = this.closeModalConfirm.bind(this);
        this.updateInputValue = this.updateInputValue.bind(this);
        this.showConfirmModal = this.showConfirmModal.bind(this);
        this.updateAgb = this.updateAgb.bind(this);
        this.checkError = this.checkError.bind(this);
        this.displayAgb = this.displayAgb.bind(this);
        this.buttonSaveDisabled = this.buttonSaveDisabled.bind(this);
        this.submitRegistration = this.submitRegistration.bind(this);

        this.state = {
            modalConfirmIsOpened: false,
            email: '',
            password: '',
            password_repeat: '',
            agb_accepted: false,
            save: true,
            mailError: false,
            passConfError: false,
            loading: false
        };
    }

    updateInputValue(event) {
        event.preventDefault();
        const data = {
            name: event.target.name,
            value: event.target.value
        };

        this.setState(prev => {
            prev[data.name] = data.value;

            return prev;
        },
        () => {
            this.props.dispatch(Actions.syncUserRegistrationData(data))
        });
    }

    updateAgb(e) {
        const data = {
            name: e.target.name,
            value: e.target.checked ? 1 : 0
        };
        this.setState(prev => {
            prev[data.name] = data.value;
            prev.save = !this.state.save;

            return prev;
        },
        () => {
            this.props.dispatch(Actions.syncUserRegistrationData(data))
        });
    }

    buttonSaveDisabled() {

        if(this.state.email.length === 0) {
            return true;
        }

        if(this.state.password.length === 0) {
            return true;
        }

        if(this.state.password_repeat.length === 0) {
            return true;
        }

        return !this.state.agb_accepted;
    }

    checkError() {
        if(this.state.email.length !== 0 && this.state.password_repeat.length !== 0) {
            this.submitRegistration();
        } else {
            this.setState({mailError: true});
        }
    }

    displayAgb() {
        const {t} = this.props;

        return <div className='agb-text' dangerouslySetInnerHTML={{__html: t('AGB Accept Text')}}/>
    }

    getError() {
        const {t} = this.props;

        if(this.state.mailError) {
            return <div className="cell full-width">
                <div className="email-error">
                    <p>{t('Bitte überprüfen Sie Ihre E-Mail Adresse')}</p>
                </div>
            </div>
        } else {
            return null;
        }
    }

    renderRegisterBoxInstance() {
        const {t} = this.props;

        return <Modal
            id={'register-modal'}
            isOpen={this.props.isModalOpen}
            escClose={true}
            backgroundClickClose={false}
            customClasses={'register-modal'}
            closeHandler={this.props.closeModal}>
            <div className='register-content'>
                <div className="grid-container">
                    <span onClick={this.props.closeModal} className="close-container">
                        <div className="close"/>
                    </span>
                    <div className="register">
                        <div className="grid-x grid-margin-x">
                            <div className="cell full-width">
                                <h2>{t('Benutzer Registrierung')}</h2>
                            </div>
                            { this.state.mailError && this.getError()}
                            <div className="cell small-5">
                                <div className="cell">
                                    <p>{t('E-Mail (= Benutzername)')}</p>
                                </div>
                            </div>
                            <div className="cell small-5">
                                <div className="cell">
                                    <p>{t('Passwort')}</p>
                                </div>
                            </div>
                            <div className="cell small-5">
                                <InputField
                                    t={t}
                                    disabled={false}
                                    change={this.updateInputValue}
                                    name={'email'}
                                    tabIndex="0"
                                    placeholder={t('Ihre E-Mail Adresse')}
                                    id="email"
                                    type="text"
                                    error={
                                        this.props.invalidFields.filter(error => {
                                            return error.fieldName === 'email';
                                        })[0]
                                    }
                                    errorLayout={'bottom'}
                                />
                            </div>
                            <div className="cell small-5">
                                <InputField
                                    t={t}
                                    disabled={false}
                                    placeholder={t('Mind. 8 Zeichen')}
                                    change={this.updateInputValue}
                                    type={'password'}
                                    name={'password'}
                                    error={
                                        this.props.invalidFields.filter(error => {
                                            return error.fieldName === 'password';
                                        })[0]
                                    }
                                    errorLayout={'bottom'}
                                />
                            </div>
                            <div className="cell small-5">
                            </div>
                            <div className="cell small-5">
                                {this.state.password.length > 0 &&
                                        <InputField
                                            t={t}
                                            disabled={false}
                                            placeholder={t('Bitte Passwort wiederholen!')}
                                            type={'password'}
                                            change={this.updateInputValue}
                                            name={'password_repeat'}
                                            error={
                                                this.props.invalidFields.filter(error => {
                                                    return error.fieldName === 'password_repeat';
                                                })[0]
                                            }
                                            errorLayout={'bottom'}
                                        />
                                }
                            </div>
                            <div className="cell small-5">
                                <button onClick={this.checkError} disabled={this.buttonSaveDisabled()}
                                    className="button button--register">{t('Speichern')}</button>
                            </div>
                            <div className="cell small-5">
                                {this.state.password_repeat.length > 0 &&
                                        <div className="checkbox-group">
                                            <InputCheckbox
                                                label={this.displayAgb()}
                                                t={t}
                                                name={'agb_accepted'}
                                                id={'agb_accepted'}
                                                onChange={this.updateAgb}
                                                checked={!!this.state.agb_accepted}
                                                value={this.state.agb_accepted}
                                                error={
                                                    this.props.invalidFields.filter(error => {
                                                        return error.fieldName === 'agb_accepted';
                                                    })[0]
                                                }
                                                errorLayout={'bottom'}
                                            />
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    }

    renderRegisterConfirmationBoxInstance() {
        const {t} = this.props;

        return <Modal
            id={'register-modal'}
            isOpen={this.state.modalConfirmIsOpened}
            escClose={true}
            backgroundClickClose={false}
            customClasses={'register-modal'}
            closeHandler={this.closeModalConfirm}>
            <div className="grid-container">
                <span onClick={this.closeModalConfirm} className="close-container">
                    <div className="close"/>
                </span>
                <div className="grid-x grid-margin-x">
                    <div className="cell full-width">
                        <h2>{t('Vielen Dank für Ihre Registrierung!')}</h2>
                    </div>
                    <div
                        className="cell full-width">
                        {t('Um den Registrierungsprozess abzuschliessen, ' +
                            'bestätigen Sie bitte Ihre E-Mail Adresse.')}
                    </div>
                    <br/>
                    <div
                        className="cell full-width">
                        {t('Dazu wurde Ihnen eine E-Mail mit einem Bestätigungs-Link gesendet.')}</div>
                    <br/>
                    <div
                        className="cell full-width">
                        {t('Bitte rufen Sie diesen innerhalb der nächsten 24 Std. auf')}</div>
                    <div className="cell large">
                        <br/><br/>
                        <button
                            onClick={this.closeModalConfirm}
                            className="button button--register"
                        >
                            {t('Weiter')}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    }

    render() {
        return (
            <>
                {this.props.isModalOpen && this.renderRegisterBoxInstance()}
                {this.state.modalConfirmIsOpened && this.renderRegisterConfirmationBoxInstance()}
            </>
        )
    }

    closeModalConfirm() {
        this.setState(prev => {
            prev.modalConfirmIsOpened = false;
            return prev;
        }, () => {
            this.props.checkAuthorised();
            if(this.props.redirectOnSuccess) {
                this.props.history.push('/dashboard');
            }
        });
    }

    showConfirmModal() {
        this.props.closeModal();
        this.setState({
            modalConfirmIsOpened: true
        });
    }

    submitRegistration() {
        this.setState(prev => {
            prev.loading = true;
            return prev;
        },
        () => {
            this.props.dispatch(Actions.saveUserData(this.props.i18n.language, this.props.user))
                .then(() => {
                    return this.setState(prev => {
                        prev.loading = false;
                        return prev;
                    });
                })
                .then(() => {
                    return this.showConfirmModal();
                })
                .catch(err => {
                    console.error(err, 'error');
                });
        });
    }
}

Register.propTypes = {
    t: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    i18n: PropTypes.any,
    user: PropTypes.any,
    history: PropTypes.any.isRequired,
    checkAuthorised: PropTypes.func.isRequired,
    invalidFields: PropTypes.array,
    redirectOnSuccess: PropTypes.bool
};

Register.defaultProps = {
    redirectOnSuccess: true
};

const mapStateToProps = state => {
    return merge({}, state.userReducer, {shared: state.shared});
};

export default connect(mapStateToProps)(translate(Register));
