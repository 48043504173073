let cfg = {
    api: {
        baseUrl: '/api',
        userApiUrlTemplate: lang => `/api/${lang}/users`,
        userSingleApiUrlTemplate: lang => `/api/${lang}/user`,
        userDataApiUrlTemplate: () => '/api/userdata',
        configurationsApiUrlTemplate: projectId => `/api/projects/${projectId}/configurations`,
        configurationsAdditionalDataApiUrlTemplate: projectId => (
            `/api/projects/${projectId}/configurations/additionalData`
        ),
        configurationApiUrlTemplate: configurationId => `/api/configurations/${configurationId}`,
        projectsApiUrlTemplate: () => '/api/projects',
        projectApiUrlTemplate: projectId => `/api/projects/${projectId}`,
        projectOfferApiUrlTemplate: projectId => `/api/projects/${projectId}/offer`,
        projectOrderApiUrlTemplate: projectId => `/api/projects/${projectId}/order`,
        productSelectorApiUrlTemplate: () => '/api/product-selector',
        restartApiUrlTemplate: () => '/api/restart',
        currentSessionApiUrlTemplate: () => '/api/current-config',
        projectInitSession: () => '/api/init',

        //Reset password
        passwordResetRequest: (lang) => `/api/${lang}/user/password-reset-email`,
        changePasswordApiUrl: (lang) => `/api/${lang}/user/change-password`,
    }
};

export default cfg;

export const {api} = cfg;
