'use strict';

import React       from 'react';
import PropTypes   from 'prop-types';
import translate   from '../translate';
import Modal       from 'sfp-react-modal';
import InputField  from '../input-field'
import * as Action from './actions';
import merge       from 'lodash.merge';
import {connect}   from 'react-redux';

class PasswordResetEmailRequest extends React.Component {
    constructor(props) {
        super(props);

        this.updateInputValue = this.updateInputValue.bind(this);
        this.checkMailError = this.checkMailError.bind(this);
        this.buttonSaveDisabled = this.buttonSaveDisabled.bind(this);
        this.submitPasswordRequest = this.submitPasswordRequest.bind(this);
        this.showConfirmModal = this.showConfirmModal.bind(this);
        this.closeModalConfirm = this.closeModalConfirm.bind(this);
        this.closePasswordRequestModal = this.closePasswordRequestModal.bind(this);

        this.state = {
            modalConfirmIsOpened: false,
            email: '',
            save: true,
            loading: false,
            error_msg: ''
        };
    }

    updateInputValue(event) {
        event.preventDefault();
        const data = {
            name: event.target.name,
            value: event.target.value
        };

        this.setState(prev => {
            prev[data.name] = data.value;

            return prev;
        });
    }

    buttonSaveDisabled() {
        return this.state.email.length === 0;
    }

    checkMailError() {
        if (this.state.email.length !== 0) {
            this.submitPasswordRequest();
        }
    }

    renderPasswordRequestBoxInstance() {
        const {t} = this.props;

        return <Modal
            id={'password-request-modal'}
            isOpen={this.props.isModalOpen}
            escClose={true}
            backgroundClickClose={false}
            customClasses={'password-request-modal'}
            closeHandler={this.closePasswordRequestModal}>
            <div className='password-request-content'>
                <div className="grid-container">
                    <span onClick={this.closePasswordRequestModal} className="close-container">
                        <div className="close"/>
                    </span>
                    <div className="password-request">
                        <div className="grid-x grid-margin-x">
                            <div className="cell full-width">
                                <h2>{t('Passwort zurücksetzen')}</h2>

                                {this.state.error_msg.length !== 0 &&
                                        <div className='email-error '>
                                            <p>{t(this.state.error_msg)}</p>
                                        </div>
                                }
                            </div>
                            <div className="cell small-5">
                                <div className="cell">
                                    <p>{t('E-Mail (= Benutzername)')}</p>
                                </div>

                                <InputField
                                    t={t}
                                    disabled={false}
                                    change={this.updateInputValue}
                                    name={'email'}
                                    tabIndex="0"
                                    placeholder={t('Ihre E-Mail Adresse')}
                                    value={this.state.email}
                                    id="email"
                                    type="text"
                                    errorLayout={'bottom'}
                                />
                            </div>
                            <div className="cell small-5">
                            </div>
                            <div className="cell small-5">
                                <button onClick={this.checkMailError} disabled={this.buttonSaveDisabled()}
                                    className="button button--password-request">
                                    {t('Link zum Zurücksetzen des Passwortes schicken')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    }

    renderPasswordRequestBoxConfirmInstance() {
        const {t} = this.props;

        return <Modal
            id={'register-modal'}
            isOpen={this.state.modalConfirmIsOpened}
            escClose={true}
            backgroundClickClose={false}
            customClasses={'register-modal'}
            closeHandler={this.closeModalConfirm}>
            <div className="grid-container">
                <span onClick={this.closeModalConfirm} className="close-container">
                    <div className="close"/>
                </span>
                <div className="grid-x grid-margin-x">
                    <div className="cell full-width">
                        <h2>{t('Vielen Dank!')}</h2>
                    </div>
                    <div
                        className="cell full-width">
                        {t('Wir haben an die angegebene E-Mail-Adresse ' +
                            'soeben eine E-Mail mit einem Bestätigungs-Link gesendet.')}
                    </div>
                    <br/>
                    <div
                        className="cell full-width">
                        {t('Bitte rufen Sie diesen Link auf um den Vorgang abzuschliessen.')}</div>
                    <br/>
                    <div className="cell large">
                        <br/><br/>
                        <button onClick={this.closeModalConfirm}
                            className="button button--register">{t('Weiter')}</button>
                    </div>
                </div>
            </div>
        </Modal>
    }

    render() {
        return (
            <>
                {this.props.isModalOpen && this.renderPasswordRequestBoxInstance()}
                {this.state.modalConfirmIsOpened && this.renderPasswordRequestBoxConfirmInstance()}
            </>
        )
    }

    closePasswordRequestModal() {
        this.setState(
            {
                error_msg: '',
                email: ''
            },
            this.props.closePasswordRequestModal
        );
    }

    closeModalConfirm() {
        this.setState(prev => {
            prev.modalConfirmIsOpened = false;
            return prev;
        }, () => {
            if (this.props.redirectOnSuccess) {
                this.props.history.push('/');
            }
        });
    }

    showConfirmModal() {
        this.closePasswordRequestModal();
        this.setState({
            modalConfirmIsOpened: true
        });
    }

    submitPasswordRequest() {
        const {t} = this.props;

        this.props.dispatch(Action.requestPasswordReset(this.props.i18n.language, this.state.email))
            .then(this.showConfirmModal)
            .catch(() => {
                this.setState({error_msg: t('Der Benutzer konnte nicht gefunden werden.')});
            })
    }
}

PasswordResetEmailRequest.propTypes = {
    t: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    i18n: PropTypes.any,
    user: PropTypes.any,
    history: PropTypes.any.isRequired,
    checkAuthorised: PropTypes.func.isRequired,
    redirectOnSuccess: PropTypes.bool,
    closePasswordRequestModal: PropTypes.func
};

PasswordResetEmailRequest.defaultProps = {
    redirectOnSuccess: true
};

const mapStateToProps = state => {
    return merge({}, {resetPasswordRequest: state.resetPasswordRequest});
};


export default connect(mapStateToProps)(translate(PasswordResetEmailRequest));
