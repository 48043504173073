import React from 'react';
import PropTypes from 'prop-types';
import InputRadioButton from '../input-radio-button'
import {project as projectFields} from './../../lib/db-field';

class ProjectList extends React.Component {
    constructor(props) {
        super(props);

        this.updateSelectedProject = this.updateSelectedProject.bind(this);
    }

    updateSelectedProject(event) {
        this.props.updateSelectedProject(event.target.value);
    }

    render() {
        const {t} = this.props;

        return (
            <div className='projects-table-comp'>
                <table>
                    <thead>
                        <tr>
                            <th className='schacht'/>
                            <th/>
                        </tr>
                    </thead>
                    <tbody className="projects-border">
                        {this.props.projects.map(item  => (
                            <tr
                                className="projects" key={item[projectFields.ID]}>
                                <td><b>{item[projectFields.PROJECT_NAME]}</b></td>
                                <td>
                                    <InputRadioButton
                                        key={item[projectFields.ID]}
                                        id={`select-project${item[projectFields.ID]}`}
                                        t={t}
                                        name='selected-project'
                                        value={`${item[projectFields.ID]}`}
                                        checked={this.props.selectedProjectId === item[projectFields.ID]}
                                        click={this.updateSelectedProject}
                                    /></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        )
    }
}

ProjectList.defaultProps = {
    selectedProjectId: 0,
    projects: []
};

ProjectList.propTypes = {
    t: PropTypes.func,
    updateSelectedProject: PropTypes.func,
    selectedProjectId: PropTypes.number,
    projects: PropTypes.array
};

export default ProjectList;