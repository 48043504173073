'use strict';

import React from 'react';
import 'whatwg-fetch';

import ButtonNavigation from './../button-navigation';
import PropTypes        from 'prop-types';

class Error extends React.Component {
    constructor(props) {
        super(props);
    }


    componentDidMount() {
        this.props.toggleSideProtocol(this.props.showSideProtocol);
        this.props.toggleProgressBar(this.props.showProgressBar);
    }


    render() {
        const {t} = this.props;
        return (
            <div className="comp-error">
                <div className="grid-container">
                    <div className="grid-x">
                        <div className="cell small-8 xlarge-7">
                            <h1>{t('Ein unerwarteter Fehler ist aufgetreten.')}</h1>
                        </div>
                    </div>
                </div>
                <div className="main-content-footer">
                    <ButtonNavigation
                        history={this.props.history}
                        enableNext={false}
                        location={this.props.location}
                        eventHandler={this.props.eventHandler}/>
                </div>
            </div>
        )
    }
}

Error.propTypes = {
    t: PropTypes.func.isRequired,
    history: PropTypes.any.isRequired,
    eventHandler: PropTypes.object,
    location: PropTypes.object,
    showSideProtocol: PropTypes.bool,
    showProgressBar: PropTypes.bool,
    toggleProgressBar: PropTypes.func,
    toggleSideProtocol: PropTypes.func
};

export default Error;
