'use strict';

const _ = require('lodash');
const Step = require('./../../steps/step');
const ProductSelectorStep = require('./../../steps/product');
const MeasurementStep = require('./measurement');

const config = require('./../config/komplettschacht-configuration.json');
const priceConfig = require('../../price-calculator/config.json');

class FloorTypeStep extends Step {

    constructor(props) {
        props = props || {};

        props.config = config;
        props.stepName = 'floorTypeSelector';

        super(props);

        // previous
        this.productSelector = new ProductSelectorStep();
        this.measurement = new MeasurementStep();
    }

    getStepConfig(sessionData){
        let dn = this.measurement.getStepData(sessionData);

        let configCopy = _.cloneDeep(super.getStepConfig());
        configCopy.showProductTypeSelection =  false;
        if(dn === '800' || dn === '1000') {
            configCopy.floorTypes = configCopy.floorTypes.filter(floortype => {
                return floortype.key === 'title_ohne_schachtboden' ||
                    floortype.key === 'adapter_für_keilgleitdichtung' ||
                    floortype.key === 'title_boden_mit_gerinne' ||
                    floortype.key === 'boden_mit_gerinne';
            });
            configCopy.showProductTypeSelection =  true;
        }
        if(dn === '600' || dn === '700' || dn === '1200' || dn === '1500'){
            configCopy.floorTypes = configCopy.floorTypes.filter(floortype => {
                return floortype.key === 'title_ohne_schachtboden' ||
                    floortype.key === 'adapter_für_keilgleitdichtung' ||
                    floortype.key === 'title_boden_mit_gerinne' ||
                    floortype.key === 'boden_mit_gerinne';
            });
        }

        this.addDataToInput(sessionData, configCopy.inputs, configCopy);

        return configCopy;
    }


    precondition(data) {
        if(!this.productSelector.isComplete(data)) return '/';
        if(!this.measurement.isComplete(data)) return this.measurement.getPathName(data);

        return null;
    }


    validate(data) {
        if (!data) return false;
        if (!data.floortype) return false;
        return !(data.optionalChecked && !data.productId);
    }


    addStepInputToData(data, input) {
        data.floortype = input.floortype;
        data.productId = input.productId;

        if(data.floortype === 1 || data.floortype === 2){
            data.productId = null;
        }

        return data;
    }

    addDataToInput(data, input, {floorTypes, showProductTypeSelection}){
        const validFloreTypeSelected = floorTypes.find(floorType => (
            floorType.value === parseInt(data.floortype + '')
        ));
        input.floortype = data.floortype;
        if (!validFloreTypeSelected) {
            input.floortype = null;
        }

        input.productId = data.productId;
        if (!showProductTypeSelection) {
            input.productId = null;
        }

        if(data.floortype === 1 || data.floortype === 2){
            data.productId = null;
        }

        return data;
    }

    removeDataOfStep(session){
        _.set(session, ['data', 'floortype'], null);
        _.set(session, ['data', 'productId'], null);

        _.set(session, ['steps', this.config[this.getStepName()].previousStep], false);
        _.set(session, ['steps', this.getStepName()], false)
    }

    /**
     *
     * @param data
     * @return {{ floortype: string, productId: number, normSchachtboden: object }}
     */
    getStepData(data) {
        let config = this.getStepConfig(data);
        let input = config.floorTypes.filter(input => {
            return input.value === parseInt(data.floortype + '');
        });

        if (!input || input.length === 0) return {}

        return {
            floortype: input[0].key,
            productId: data.productId,
            normSchachtboden: _.find(priceConfig.priceCalculation.normSchachtboden.list, {productId: data.productId})
        };
    }

    /**
     * Get list of norm-schachtbodens from price configuration.
     *
     * @param sessionData
     */
    getNormSchachtboden(sessionData) {
        let dn = this.measurement.getStepData(sessionData);

        return priceConfig.priceCalculation.normSchachtboden.list
            .filter(schachtboden => schachtboden.nw === dn)
            .map((schachtboden => ({productId: schachtboden.productId, name: schachtboden.name})));
    }
}

module.exports = FloorTypeStep;
