'use strict';

import microevent from 'microevent';

class EventHandler {

}

microevent.mixin(EventHandler);


export default new EventHandler();