'use strict';

import React     from 'react';
import _         from 'lodash';
import PropTypes from 'prop-types';

import FetchUtils       from './../../advancd-fetch';
import Inlet            from './../inlet';
import ButtonNavigation from './../button-navigation';
import InfoText         from '../info-text';

import {inputIsIncomplete} from './lib/validate-inlet';
import InletAngleView from '../angle-view/lib/inlet';

class InletData extends React.Component {

    constructor(props) {
        super(props);

        this.emptyInletInput = {
            deleted: 0,
            configuration: null,
            pipetype: null,
            diameter: null,
            pipeseries: '',
            fall: null,
            winkel: null,
            comment: '',
            abstand_zur_sohle_auslauf: '', // Actual selection on the inlet
            hohenkote_ab_ok_schacht: '',   // Actual selection on the inlet
            abstandHohenkoteSelected: ''  // This is just to maintain the state in the
            // frontend. Will not the sent to the backend
        };

        this.state = {
            inputs: [
                _.cloneDeep(this.emptyInletInput)
            ],
            pipetypes: [],
            diameters: [],
            pipeSeries: [],
            abstand_zur_sohle_auslauf: null,  // Configuration object
            hohenkote_ab_ok_schacht: null,    // Configuration object
            maxInlets: 0,
            allowNext: false,
            fieldsWithError: [],
            maxInletReached: false,
            visibleElementsCount: null,
            outfallDiameter: null
        };

        this.load = this.load.bind(this);
        this.loadConfigs = this.loadConfigs.bind(this);
        this.update = this.update.bind(this);
        this.onUpdatePipetype = this.onUpdatePipetype.bind(this);
        this.onUpdateDiameter = this.onUpdateDiameter.bind(this);
        this.onUpdateFall = this.onUpdateFall.bind(this);
        this.onUpdateWinkel = this.onUpdateWinkel.bind(this);
        this.onUpdateComment = this.onUpdateComment.bind(this);
        this.onUpdatePipeSeries = this.onUpdatePipeSeries.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.addInlet = this.addInlet.bind(this);
        this.removeInlet = this.removeInlet.bind(this);
        this.allowNext = this.allowNext.bind(this);
        this.onBack = this.onBack.bind(this);
        this.onInputChangeAbstandAndHohenkote = this.onInputChangeAbstandAndHohenkote.bind(this);
        this.onRadioSelectChangeAbstandAndHohenkote = this.onRadioSelectChangeAbstandAndHohenkote.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
        this.checkIfMaxInletReached = this.checkIfMaxInletReached.bind(this);
        this.stepId = 'inletSelector';

        // pare this. because somehow we come through the constructor relatively often
        this.props.eventHandler.unbind('next-clicked', this.handleNext);
        this.props.eventHandler.bind('next-clicked', this.handleNext);
        this.props.eventHandler.unbind('back-clicked', this.onBack);
        this.props.eventHandler.bind('back-clicked', this.onBack);
    }

    _handleKeyDown(event) {
        let ENTER_CODE = 13;

        if (event.keyCode === ENTER_CODE) {
            event.preventDefault();

            if (this.allowNext()) {
                this.props.eventHandler.trigger('next-clicked');
            }

            return false;
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this._handleKeyDown);
    }

    componentDidMount() {
        document.addEventListener('keydown', this._handleKeyDown);
        this.props.eventHandler.trigger('protocol');
        this.props.toggleProgressBar(this.props.showProgressBar);
        this.props.toggleSideProtocol(this.props.showSideProtocol);
        this.load();
    }

    handleNext() {
        this.update();
    }

    load() {
        fetch(`/api${this.props.location.pathname}`, {credentials: 'include'})
            .then(FetchUtils.checkStatus)
            .then(response => {
                this.props.eventHandler.trigger('update-progress-bar', {id: this.stepId, isCompleted: false});

                if (response.next) {
                    this.props.history.push(response.next);
                    return Promise.reject(new Error('done'));
                }

                if (response.outfall) {
                    this.setState({outfallDiameter: response.outfall.diameter});
                }

                if (response.inlets) {

                    this.setState(prev => {
                        prev.maxInlets = response.inlets.maxInlets;

                        prev.pipetypes = response.inlets.pipetype.options;
                        prev.abstand_zur_sohle_auslauf = response.inlets.abstand_zur_sohle_auslauf;
                        prev.hohenkote_ab_ok_schacht = response.inlets.hohenkote_ab_ok_schacht;

                        prev.inputs = prev.inputs.reduce((result, input) => {
                            if (input.deleted) {
                                return result;
                            }

                            input.pipeseries = response.inlets.pipeseries ? '' : null;
                            result.push(input);

                            return result;
                        }, []);

                        prev.allowNext = this.allowNext(prev);
                        this.checkIfMaxInletReached(prev);
                        prev.visibleElementsCount = this.getVisibleElementsCount(prev);

                        return prev;
                    });
                }


                if (response.inputs) {
                    this.setState(prev => {
                        prev.inputs = response.inputs.reduce((result, input) => {
                            if (input.deleted) {
                                return result;
                            }

                            if (input.abstand_zur_sohle_auslauf != null) {
                                input.abstandHohenkoteSelected = 'abstand_zur_sohle_auslauf';
                            } else if (input.hohenkote_ab_ok_schacht != null) {
                                input.abstandHohenkoteSelected = 'hohenkote_ab_ok_schacht';
                            }
                            result.push(input);

                            return result;
                        }, []);

                        prev.allowNext = this.allowNext(prev);
                        this.checkIfMaxInletReached(prev);
                        prev.visibleElementsCount = this.getVisibleElementsCount(prev);

                        return prev;
                    }, () => {
                        this.state.inputs.forEach((input, i) => {
                            this.loadConfigs(i);
                        });
                    })
                }
            })
            .catch(err => {
                if (err.message !== 'done') {
                    console.error(err);

                    this.props.history.push('/error');
                }
            });
    }

    /**
     * load diameters asynchronically because they depend on the pipetype
     */
    loadConfigs(index) {
        if (this.state.inputs[index].pipetype === 'no_inlet') {
            this.setState({diameters: []});
            return this.state.diameters;
        }

        return fetch(
            `/api${this.props.location.pathname}/configs/${this.state.inputs[index].pipetype}`,
            {credentials: 'include'}
        )
            .then(FetchUtils.checkStatus)
            .then(response => {
                this.setState(prev => {
                    prev.diameters[index] = response.diameters.map(option => {

                        option.key = option.value;

                        return option;
                    });

                    prev.inputs = prev.inputs.map((input, i) => {
                        if (index === i) {
                            if (
                                prev.diameters[index]
                                    .map(diameter => diameter.value)
                                    .indexOf(input.diameter) < 0
                            ) {
                                input.diameter = null;
                            }
                        }

                        return input;
                    });

                    prev.allowNext = this.allowNext(prev);

                    return prev;
                });

                this.setState(prev => {
                    prev.pipeSeries[index] = response.pipeSeries || [];

                    if (!prev.pipeSeries[index].find(({value}) => value === prev.inputs[index].pipeseries)) {
                        prev.inputs[index].pipeseries = null;
                    }

                    prev.allowNext = this.allowNext(prev);

                    return prev;
                });
            })
            .catch(err => {
                console.error(err);

                this.setState(prev => {
                    prev.diameters[index] = [];

                    return prev;
                })
            });
    }

    update() {
        let inputs = this.state.inputs.map(input => {
            input = _.cloneDeep(input);

            if (input.abstandHohenkoteSelected === 'abstand_zur_sohle_auslauf') {
                input.hohenkote_ab_ok_schacht = null;
            } else if (input.abstandHohenkoteSelected === 'hohenkote_ab_ok_schacht') {
                input.abstand_zur_sohle_auslauf = null;
            } else if (input.pipetype === 'no_inlet') {
                input.abstand_zur_sohle_auslauf = null;
                input.hohenkote_ab_ok_schacht = null;
            } else {
                console.warn('Invalid selected key');
            }

            return input;
        });

        // Reset error fields
        this.setState(prev => {
            prev.fieldsWithError = [];

            return prev;
        });

        fetch(`/api${this.props.location.pathname}`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(inputs)
        })
            .then((result) => {
                result.json().then(body => {
                    if (body.error) {
                        this.setState(prev => {
                            prev.fieldsWithError = body.error.invalidFields || [];

                            return prev;
                        })
                    }

                    this.props.history.push(body.next);
                });
            })
    }

    onUpdatePipetype(index, value) {
        this.setState(prev => {
            prev.inputs[index].pipetype = value;

            prev.allowNext = this.allowNext(prev);

            return prev
        }, () => this.loadConfigs(index));
    }

    onUpdateDiameter(index, value) {
        this.setState((prev) => {
            value = parseInt(value);

            prev.inputs[index].diameter = value;

            // allow if all of those values are set
            prev.allowNext = this.allowNext(prev);

            return prev;
        })
    }

    onUpdatePipeSeries(index, value) {
        this.setState((prev) => {

            prev.inputs[index].pipeseries = parseInt(value);

            if (prev.inputs[index].pipeseries === 0) {
                prev.inputs[index].pipeseries = '';
            }

            prev.allowNext = this.allowNext(prev);

            return prev;
        });
    }

    onUpdateFall(index, value) {
        this.setState((prev) => {
            value = parseInt(value);

            prev.inputs[index].fall = value;

            prev.allowNext = this.allowNext(prev);

            return prev;
        });
    }

    getWinkelValue(value) {
        const maxLimit = 359;
        const minLimit = 0;

        if (value > maxLimit) {
            return maxLimit;
        } else if (value < minLimit) {
            return minLimit;
        }

        return value;
    }

    onUpdateWinkel(index, value) {
        this.setState((prev) => {
            value = parseInt(value);

            prev.inputs[index].winkel = this.getWinkelValue(value);

            prev.allowNext = this.allowNext(prev);

            return prev;
        });
    }

    onUpdateComment(index, value) {
        this.setState((prev) => {
            prev.inputs[index].comment = value;

            return prev;
        })
    }

    onRadioSelectChangeAbstandAndHohenkote(index, key) {
        this.setState(prev => {

            let input = prev.inputs[index];

            input.abstandHohenkoteSelected = key;

            prev.allowNext = this.allowNext(prev);

            return prev;
        });
    }

    onInputChangeAbstandAndHohenkote(index, key, id, value) {

        this.setState(prev => {
            prev.inputs[index][key] = value;
            prev[key].value = value;

            prev.allowNext = this.allowNext(prev);

            return prev;
        });

    }

    checkIfMaxInletReached(prev) {
        prev.maxInletReached = this.getVisibleElementsCount(prev) >= prev.maxInlets;
    }

    getVisibleElementsCount(state) {
        state = state || this.state;
        return state.inputs.filter(input => !input.deleted).length || 0;
    }

    allowNext(state) {
        if (!state) {
            state = this.state;
        }

        // some => at least one element matches the criteria
        let allowNext = !state.inputs.some((input, index) => {
            const hasPipeSeriesConfig = !!(state.pipeSeries &&
                state.pipeSeries[index] &&
                state.pipeSeries[index].length);
            return inputIsIncomplete(input, !!hasPipeSeriesConfig);
        });

        if (!allowNext) {
            return false;
        }

        if (state.inputs.length === 0) {
            return false;
        }

        const visibleInlets = this.getVisibleElementsCount(state);

        return !(visibleInlets === 0 || visibleInlets > state.maxInlets);
    }

    addInlet() {
        let inputs = this.state.inputs.reduce((result, input) => {
            if (input.deleted) {
                return result;
            }

            input = _.cloneDeep(input);

            if (input.abstandHohenkoteSelected === 'abstand_zur_sohle_auslauf') {
                input.hohenkote_ab_ok_schacht = null;
            } else if (input.abstandHohenkoteSelected === 'hohenkote_ab_ok_schacht') {
                input.abstand_zur_sohle_auslauf = null;
            } else {
                console.warn('Invalid selected key');
            }
            result.push(input);

            return result;
        }, []);

        // Reset error fields
        this.setState(prev => {
            prev.fieldsWithError = [];

            return prev;
        });

        fetch(`/api${this.props.location.pathname}/validate-angle`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(inputs)
        })
            .then((result) => {
                result.json().then(body => {
                    if (body.error) {
                        return this.setState(prev => {
                            prev.fieldsWithError = body.error.invalidFields || [];

                            return prev;
                        })
                    }

                    return this.setState(prev => {

                        this.checkIfMaxInletReached(prev);
                        if (prev.maxInletReached)
                            return prev;

                        prev.inputs.push(_.cloneDeep(this.emptyInletInput));

                        prev.diameters.push([]);

                        prev.allowNext = this.allowNext(prev);

                        this.checkIfMaxInletReached(prev);

                        prev.visibleElementsCount += 1;

                        return prev;
                    });
                });
            })


    }

    removeInlet() {
        this.setState(prev => {
            if (prev.inputs.length > 1) {
                for (let i = prev.inputs.length - 1; i >= 0; i--) {
                    if (!prev.inputs[i].deleted) {
                        if (!prev.inputs[i].configuration) {
                            prev.inputs.splice(i, 1);
                            break;
                        }
                        prev.inputs[i].deleted = 1;
                        break;
                    }
                }
                //Remove last element on the list
            }

            prev.allowNext = this.allowNext(prev);

            this.checkIfMaxInletReached(prev);
            prev.visibleElementsCount -= 1;

            return prev;
        });
    }

    onBack() {
        fetch(`/api${this.props.location.pathname}/back`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((result) => {
                result.json().then(body => {
                    this.props.history.push(body.previous);
                });
            });
    }

    render() {
        const {t} = this.props;
        let nameIndex = 1;
        const maxMessage = t('Maximale Einläufe', {max: this.state.maxInlets});

        return (
            <div className={'comp-inlet-wrapper'}>
                {
                    <InletAngleView
                        diameterOptions={this.state.diameters[0]}
                        activeDiameters={this.state.inputs.map(input => input.diameter)}
                        angleInputs={this.state.inputs.map(input => input.winkel)}
                        outfallDiameter={this.state.outfallDiameter}
                    />
                }
                {
                    this.state.inputs.map((input, index) => {
                        if (input.deleted) {
                            return null;
                        }

                        let abstand = _.cloneDeep(this.state.abstand_zur_sohle_auslauf);
                        _.set(abstand, 'value', input.abstand_zur_sohle_auslauf);

                        let hohenkote = _.cloneDeep(this.state.hohenkote_ab_ok_schacht);
                        _.set(hohenkote, 'value', input.hohenkote_ab_ok_schacht);

                        return <Inlet
                            {...this.props}
                            title={t('Kontrollansicht')}
                            key={index}
                            index={index}
                            nameIndex={nameIndex++}
                            updateDiameter={(value) => this.onUpdateDiameter(index, value)}
                            updatePipetype={(value) => this.onUpdatePipetype(index, value)}
                            updatePipeSeries={(value) => this.onUpdatePipeSeries(index, value)}
                            updateFall={(value) => this.onUpdateFall(index, value)}
                            updateWinkel={(value) => this.onUpdateWinkel(index, value)}
                            updateComment={(value) => this.onUpdateComment(index, value)}

                            onRadioSelectChangeAbstandAndHohenkote={
                                (value) => this.onRadioSelectChangeAbstandAndHohenkote(index, value)
                            }
                            onInputChangeAbstandAndHohenkote={
                                (key, id, value) => this.onInputChangeAbstandAndHohenkote(index, key, id, value)
                            }

                            pipetypeOptions={this.state.pipetypes}
                            activePipetype={input.pipetype}

                            diameterOptions={this.state.diameters[index]}
                            activeDiameter={input.diameter}
                            pipeseries={input.pipeseries}
                            pipeSeriesOptions={this.state.pipeSeries[index]}
                            fallInput={input.fall}
                            winkelInput={input.winkel}
                            commenInput={input.comment}
                            abstand={abstand}
                            hohenkote={hohenkote}
                            abstandHohenkoteSelected={input.abstandHohenkoteSelected}
                            fieldsWithErrors={this.state.fieldsWithError}
                        />
                    })
                }

                <div className="grid-container">
                    <div className="grid-x">
                        <div className="cell">
                            {
                                !this.state.maxInletReached && this.state.inputs[0].pipetype !== 'no_inlet' && <button
                                    className="button button--dark-grey button-additional-inlet"
                                    type="button"
                                    value={t('Weiteren Einlauf hinzufügen')}
                                    disabled={
                                        (
                                            !this.state.allowNext ||
                                            this.state.visibleElementsCount >= this.state.maxInlets
                                        ) ?
                                            'disabled' : ''
                                    }
                                    onClick={this.addInlet}>
                                    {t('Weiteren Einlauf hinzufügen')}
                                </button>
                            }

                            {this.state.inputs[0].pipetype !== 'no_inlet' &&
                            <button className="button button--red button-remove-inlet"
                                type="button"
                                value={t('Letzten Einlauf entfernen')}
                                disabled={this.state.visibleElementsCount === 1 ? 'disabled' : undefined}
                                onClick={this.removeInlet}>
                                {t('Letzten Einlauf entfernen')}
                            </button>
                            }
                            {
                                this.state.maxInletReached && <InfoText>
                                    <div>
                                        {maxMessage}&nbsp;<a href={'mailto:schacht@aco.ch'}>schacht@aco.ch</a>
                                    </div>
                                </InfoText>
                            }
                        </div>
                    </div>
                </div>
                <div className="main-content-footer">
                    <ButtonNavigation
                        t={t}
                        history={this.props.history}
                        enableNext={this.state.allowNext}
                        location={this.props.location}
                        eventHandler={this.props.eventHandler}/>
                </div>
            </div>
        );
    }

}

InletData.propTypes = {
    eventHandler: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    t: PropTypes.func.isRequired,
    showSideProtocol: PropTypes.bool,
    showProgressBar: PropTypes.bool,
    toggleProgressBar: PropTypes.func,
    toggleSideProtocol: PropTypes.func
};

InletData.defaultProps = {};

export default InletData;