import fetchAndCheck from '../../lib/advanced-fetch';
import cfg           from '../../conf';

export function saveUserData(language, user) {

    let apiURL = `${cfg.api.userApiUrlTemplate(language)}`;

    let apiMethod = 'POST';

    return {
        type: 'save-user-data',
        payload: fetchAndCheck(apiURL, {
            method: apiMethod,
            body: JSON.stringify(user),
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
    };
}

export function updateUserData(language, userData) {

    let apiURL = `${cfg.api.userSingleApiUrlTemplate(language)}`;

    let apiMethod = 'PUT';

    return {
        type: 'update-user-data',
        payload: fetchAndCheck(apiURL, {
            method: apiMethod,
            body: JSON.stringify(userData),
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
    };
}

export function getUserData(language, userData) {

    let apiURL = `${cfg.api.userSingleApiUrlTemplate(language)}`;

    let apiMethod = 'GET';

    return {
        type: 'get-user-data',
        payload: fetchAndCheck(apiURL, {
            method: apiMethod,
            body: JSON.stringify(userData),
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
    };
}

export function syncUserRegistrationData(data) {
    return {
        type: 'sync-registration-data',
        data: data
    }
}

export function resetUserData() {
    return {
        type: 'reset-user-data'
    }
}
