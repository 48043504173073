import React     from 'react';
import PropTypes from 'prop-types';

class InputCheckbox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showInfoText: false,
            checkboxFocused: false
        };

        this.checkboxInputRef = React.createRef();
        this.handleFocus = this.handleFocus.bind(this);
        this.handleBlur = this.handleBlur.bind(this);

        this.toggleInfoBlock = this.toggleInfoBlock.bind(this);
        this.renderInfoBlock = this.renderInfoBlock.bind(this);
    }

    componentDidMount() {
        this.updateEventListeners('add');
    }

    componentWillUnmount() {
        this.updateEventListeners('remove');
    }

    updateEventListeners(action) {
        const input = this.checkboxInputRef.current;
        if (input) {
            const method = action === 'add' ? 'addEventListener' : 'removeEventListener';
            input[method]('focus', this.handleFocus);
            input[method]('blur', this.handleBlur);
        }
    }

    render() {
        const {t} = this.props;
        const { checkboxFocused } = this.state;
        let infoText = this.renderInfoBlock(),
            label = <label htmlFor={this.props.id}>{this.props.label}</label>;

        if (this.props.htmlLabel) {
            label = <label htmlFor={this.props.id} dangerouslySetInnerHTML={{__html: this.props.htmlLabel}}/>;
        }

        let hasError = this.props.errors ? this.props.errors[this.props.name] : false,
            inputClasses = 'checkbox',
            error;

        if (hasError) {
            error = this.props.errors[this.props.name].map(errorMsg => t(errorMsg)).join(', ');
            inputClasses += ' ' + 'error';
        }

        if (checkboxFocused) {
            inputClasses += ' ' + 'focused';
        }

        return (
            <div className={`checkbox ${this.props.className} ${inputClasses}`}>
                <input
                    type="checkbox"
                    name={this.props.name}
                    id={this.props.id}
                    checked={this.props.checked ? 'checked' : ''}
                    onChange={this.props.onChange}
                    disabled={this.props.disabled}
                    ref={this.checkboxInputRef}
                />
                {label}

                {infoText}

                <span className={'error-message'}>{hasError ? error : ''}</span>
            </div>
        );
    }

    handleFocus() {
        this.setState({ checkboxFocused: true });
    }

    handleBlur() {
        this.setState({ checkboxFocused: false });
    }

    /**
     *
     * Render infoText box if infoText prop is provided
     */
    renderInfoBlock() {
        if (this.props.infoText) {
            return (
                <div className="info-text-wrapper">
                    <a href="#" onClick={this.toggleInfoBlock} className="icon icon-help primary"></a>
                    <span className={this.state.showInfoText ? 'info-text' : 'info-text hidden'}>
                        {this.props.infoText}
                    </span>
                </div>
            );
        } else return '';
    }

    /**
     *
     * @param e
     */
    toggleInfoBlock(e) {
        e.preventDefault();
        this.setState(state => {
            state.showInfoText = !state.showInfoText;

            return state;
        });
    }
}

InputCheckbox.propTypes = {
    t: PropTypes.func.isRequired,
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    htmlLabel: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    errors: PropTypes.object,
    infoText: PropTypes.string
};

InputCheckbox.defaultProps = {
    infoText: null
};

export default InputCheckbox;
