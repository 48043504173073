'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import InputField from '../input-field';
import InputTextarea from '../input-textarea';
import translate from '../translate';
import * as Actions from './actions';
import advancedFetch from '../advanced-fetch';
import cfg from '../../conf';
import merge from 'lodash.merge';
import isEqual from 'lodash.isequal';
import {connect} from 'react-redux';
import {subject} from '@casl/ability';

class ProjectInfos extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            projectData: {},
            disableSaveButton: true,
            projectId: null,
            readOnly: false
        };

        this.state.projectData = this.props.match.params.projectId;

        this.backToProject = this.backToProject.bind(this);
        this.submit = this.submit.bind(this);
        this.load = this.load.bind(this);
        this.updateInputValue = this.updateInputValue.bind(this);
        this.activateButtonIfRequiredFieldAreSet = this.activateButtonIfRequiredFieldAreSet.bind(this);
    }

    componentDidMount() {
        this.props.toggleProgressBar(this.props.showProgressBar);
        this.props.toggleSideProtocol(this.props.showSideProtocol);

        const {match: {params: {projectId: id}}} = this.props;

        this.setState({projectId: id});

        this.load();
    }

    load() {
        const {match: {params: {projectId: id}}} = this.props;

        advancedFetch(cfg.api.projectApiUrlTemplate(id), {credentials: 'include'})
            .then(projectData => {
                this.props.dispatch(Actions.syncProjectInfoData(projectData));
                this.setState({
                    projectData: projectData,
                    readOnly: !this.props.shared.ability.can('update', subject('Project', projectData))
                })
                this.activateButtonIfRequiredFieldAreSet();

            })
            .catch(console.error);
    }

    backToProject() {
        this.props.history.push('/project/' + this.state.projectId);
    }

    submit() {
        this.setState(prev => {
            prev.loading = true;
            return prev;
        },
        () => {
            this.props.dispatch(Actions.saveProjectInfoData(this.props.match.params.projectId, this.state.projectData))
                .then(() => {
                    return this.setState(prev => {
                        prev.loading = false;

                        return prev;
                    });
                })
                .then(() => {
                    // back to project page
                    this.props.history.push('/project/' + this.state.projectId);
                })
                .catch(err => {
                    console.error(err, 'error');
                });
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) { //eslint-disable-line camelcase
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (!isEqual(this.props.projectData, nextProps.projectData)) {
            this.setState({projectData: nextProps.projectData});
        }
    }

    activateButtonIfRequiredFieldAreSet(){
        if(
            this.state.projectData.first_name &&
            this.state.projectData.last_name &&
            this.state.projectData.email &&
            this.state.projectData.project_name &&
            this.state.projectData.delivery_address &&
            this.state.projectData.company &&
            this.state.projectData.zip
        ) {
            this.setState({
                disableSaveButton: false
            });
        } else {
            this.setState({
                disableSaveButton: true
            });
        }
    }

    updateInputValue(event) {
        const data = {
            name: event.target.name,
            value: event.target.value
        };

        if (data.name === 'zip' && data.value.length > 4) {
            return;
        }

        this.setState(prev => {
            prev.projectData[data.name] = data.value;

            return prev;
        }, () => {
            this.activateButtonIfRequiredFieldAreSet();
        });
    }

    render() {
        const {t} = this.props;

        return (
            <div className="project-info-comp">
                <div className="grid-container">
                    <h2>{this.state.projectData.project_name}</h2>
                    <div className="grid-x grid-margin-x">
                        <div className="cell small customer-number">
                            <InputField
                                t={t}
                                disabled={this.state.readOnly}
                                type='text'
                                name={'project_name'}
                                label={t('Projektname')}
                                value={this.state.projectData.project_name || ''}
                                change={this.updateInputValue}
                                error={
                                    this.props.invalidFields.fields.indexOf('project_name') >= 0 ?
                                        {message: t(this.props.invalidFields.details.customer_number)} :
                                        null
                                }
                                errorLayout={'bottom'}
                            />
                        </div>
                        <div className="cell small-5">
                            <InputField
                                t={t}
                                disabled={this.state.readOnly}
                                type="text"
                                label={t('Vorname')}
                                value={this.state.projectData.first_name || ''}
                                change={this.updateInputValue}
                                name={'first_name'}
                                error={
                                    this.props.invalidFields.fields.indexOf('first_name') >= 0 ?
                                        {message: t(this.props.invalidFields.details.first_name)} :
                                        null
                                }
                                errorLayout={'bottom'}
                                optional={false}
                            />
                        </div>
                        <div className="cell small-5">
                            <InputField
                                t={t}
                                disabled={this.state.readOnly}
                                type="text"
                                label={t('Nachname')}
                                value={this.state.projectData.last_name || ''}
                                name={'last_name'}
                                change={this.updateInputValue}
                                error={
                                    this.props.invalidFields.fields.indexOf('last_name') >= 0 ?
                                        {message: t(this.props.invalidFields.details.last_name)} :
                                        null
                                }
                                errorLayout={'bottom'}
                                optional={false}
                            />
                        </div>
                        <div className="cell small-5">
                            <InputField
                                t={t}
                                disabled={this.state.readOnly}
                                type="text"
                                label={t('E-Mail')}
                                value={this.state.projectData.email || ''}
                                change={this.updateInputValue}
                                name={'email'}
                                error={
                                    this.props.invalidFields.fields.indexOf('email') >= 0 ?
                                        {message: t(this.props.invalidFields.details.email)} :
                                        null
                                }
                                errorLayout={'bottom'}
                                optional={false}
                            />
                        </div>
                        <div className="cell small-5">
                            <InputField
                                t={t}
                                disabled={this.state.readOnly}
                                type="text"
                                label={t('Telefon')}
                                name={'phone'}
                                value={this.state.projectData.phone || ''}
                                change={this.updateInputValue}
                                placeholder={t('Optional')}
                                error={
                                    this.props.invalidFields.fields.indexOf('phone') >= 0 ?
                                        {message: t(this.props.invalidFields.details.phone)} :
                                        null
                                }
                                errorLayout={'bottom'}
                            />
                        </div>
                        <div className="cell small-5">
                            <InputField
                                t={t}
                                disabled={this.state.readOnly}
                                type="text"
                                label={t('Strasse')}
                                value={this.state.projectData.street || ''}
                                change={this.updateInputValue}
                                placeholder={t('Optional')}
                                name={'street'}
                                error={
                                    this.props.invalidFields.fields.indexOf('street') >= 0 ?
                                        {message: t(this.props.invalidFields.details.street)} :
                                        null
                                }
                                errorLayout={'bottom'}
                            />
                        </div>
                        <div className="location-information grid-padding-x">
                            <div className="cell small-7">
                                <InputField
                                    t={t}
                                    disabled={this.state.readOnly}
                                    type="text"
                                    label={t('Ort')}
                                    value={this.state.projectData.city || ''}
                                    change={this.updateInputValue}
                                    placeholder={t('Optional')}
                                    name={'city'}
                                    error={
                                        this.props.invalidFields.fields.indexOf('city') >= 0 ?
                                            {message: t(this.props.invalidFields.details.city)} :
                                            null
                                    }
                                    errorLayout={'bottom'}
                                />
                            </div>

                            <div className="cell auto project-zip">
                                <InputField
                                    t={t}
                                    disabled={this.state.readOnly}
                                    type="text"
                                    label={t('PLZ')}
                                    value={this.state.projectData.zip || ''}
                                    change={this.updateInputValue}
                                    optional={false}
                                    name={'zip'}
                                    error={
                                        this.props.invalidFields.fields.indexOf('zip') >= 0 ?
                                            {message: t(this.props.invalidFields.details.zip)} :
                                            null
                                    }
                                    errorLayout={'bottom'}
                                />
                            </div>
                        </div>
                        <div className="cell small-5">
                            <InputField
                                t={t}
                                disabled={this.state.readOnly}
                                type="text"
                                name={'delivery_date'}
                                value={this.state.projectData.delivery_date || ''}
                                change={this.updateInputValue}
                                label={t('Liefertermin')}
                                placeholder={t('Optional')}
                                error={
                                    this.props.invalidFields.fields.indexOf('delivery_date') >= 0 ?
                                        {message: t(this.props.invalidFields.details.delivery_date)} :
                                        null
                                }
                                errorLayout={'bottom'}
                            />
                        </div>
                        <div className="cell small-5">
                            <InputField
                                t={t}
                                disabled={this.state.readOnly}
                                type="text"
                                label={t('Lieferadresse')}
                                value={this.state.projectData.delivery_address || ''}
                                change={this.updateInputValue}
                                name={'delivery_address'}
                                error={
                                    this.props.invalidFields.fields.indexOf('delivery_address') >= 0 ?
                                        {message: t(this.props.invalidFields.details.delivery_address)} :
                                        null
                                }
                                errorLayout={'bottom'}
                                optional={false}
                            />
                        </div>
                        <div className="cell small-5">
                            <InputField
                                t={t}
                                disabled={this.state.readOnly}
                                type='text'
                                label={t('Firma')}
                                value={this.state.projectData.company || ''}
                                name={'company'}
                                change={this.updateInputValue}
                                error={
                                    this.props.invalidFields.fields.indexOf('company') >= 0 ?
                                        {message: t(this.props.invalidFields.details.company)} :
                                        null
                                }
                                errorLayout={'bottom'}
                                optional={false}
                            />
                        </div>
                        <div className="cell small-5">
                            <InputField
                                t={t}
                                disabled={this.state.readOnly}
                                type='text'
                                name={'customer_number'}
                                label={t('Kundennummer')}
                                value={this.state.projectData.customer_number || ''}
                                change={this.updateInputValue}
                                placeholder={t('Optional')}
                                error={
                                    this.props.invalidFields.fields.indexOf('customer_number') >= 0 ?
                                        {message: t(this.props.invalidFields.details.customer_number)} :
                                        null
                                }
                                errorLayout={'bottom'}
                            />
                        </div>
                    </div>
                    <div className={'cell small-10'}>
                        <InputTextarea
                            t={t}
                            type="text"
                            label='Bemerkung'
                            placeholder={t('Optional')}
                            onChange={(newValue) => this.updateInputValue({target: {name: 'comment', value: newValue}})}
                            name={'comment'}
                            value={this.state.projectData.comment || ''}
                            error={
                                this.props.invalidFields.fields.indexOf('comment') >= 0 ?
                                    {message: t(this.props.invalidFields.details.comment)} :
                                    null
                            }
                            errorLayout={'bottom'}
                            disabled={this.state.readOnly}
                        />
                    </div>

                    <div className="main-content-footer">
                        <div className="cell small-5 container">
                            <button
                                className="button button--object-back"
                                onClick={this.backToProject}>
                            </button>

                            <button
                                disabled={this.state.readOnly || this.state.disableSaveButton}
                                onClick={this.submit}
                                className="button button--save-object-edit">{t('Projektangaben speichern')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ProjectInfos.propTypes = {
    t: PropTypes.func,
    dispatch: PropTypes.func.isRequired,
    i18n: PropTypes.any,
    projectData: PropTypes.any,
    history: PropTypes.any.isRequired,
    match: PropTypes.any,
    invalidFields: PropTypes.any,
    shared: PropTypes.shape({
        ability: PropTypes.shape({
            can: PropTypes.func
        })
    }),
    showSideProtocol: PropTypes.bool,
    showProgressBar: PropTypes.bool,
    toggleProgressBar: PropTypes.func,
    toggleSideProtocol: PropTypes.func
};

const mapStateToProps = state => {
    return merge({}, state.projectInfo, {shared: state.shared});
};

export default connect(mapStateToProps)(translate(ProjectInfos));