/* React starting point */
import React, {Suspense}                       from 'react';
import {createRoot}                            from 'react-dom/client';
import {Router}                                from 'react-router-dom';
import {createStore, applyMiddleware, compose} from 'redux';
import {Provider}                              from 'react-redux';
import reducers                                from './lib/reducer';
import promise                                 from 'redux-promise-middleware';
import {createLogger}                          from 'redux-logger';
import es6Promise                              from 'es6-promise';
import 'whatwg-fetch';// polyfill for Fetch API
import Promise                                 from 'promise-polyfill';// Add Promises support for IE11
import '@babel/polyfill';
import ErrorBoundary                           from './components/error/errorBoudary';
import Loader                                  from './components/loader';
import './components/i18n';

es6Promise.polyfill();

if (!window.Promise) {
    window.Promise = Promise;
}

//Import components here
import Configurator           from './components/configurator';
import {createBrowserHistory} from 'history';

const customHistory = createBrowserHistory();

// Listen for changes to the current location and send them to Matomo.
customHistory.listen((location) => {
    if (typeof _paq !== 'undefined') {
        _paq.push(['setCustomUrl', location.pathname]);
        _paq.push(['setDocumentTitle', location.pathname]);
        _paq.push(['trackPageView']);
    }
});

/* eslint-disable no-underscore-dangle */
const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;
/* eslint-enable */


const middlewares = [];
middlewares.push(promise);

if (process.env.NODE_ENV !== 'production') {
    middlewares.push(createLogger());
}

const store = createStore(
    reducers,
    composeEnhancers(
        applyMiddleware(
            ...middlewares
        )
    )
);

if (document.getElementById('app-content')) {

    const root = createRoot(document.getElementById('app-content'));
    root.render(
        <ErrorBoundary>
            <Suspense fallback={<Loader/>}>
                <Provider store={store}>
                    <Router history={customHistory}>
                        <Configurator/>
                    </Router>
                </Provider>
            </Suspense>
        </ErrorBoundary>
    );
}
