'use strict';

class Step {

    constructor(props) {
        props = props || {};

        const translateDefault = () => console.warn('no translation function set for step');

        this.config = props.config;
        this.pathName = props.pathName;
        this.stepName = props.stepName;
        this.projectId = props.projectId || null;
        this.t = props.t || translateDefault;
    }

    getConfig() {
        return this.config;
    }

    getStepConfig(){
        return this.config[this.getStepName()]
    }

    getStepName(){
        return this.stepName;
    }

    getPathName(data) {
        if (!data || !data.product) {
            return '/';
        }

        let config = this.config[this.getStepName()];

        return `/${data.product}/${config.url}`;
    }

    precondition() {
        throw new Error('You have to implement this method in a subclass');
    }

    validate() {
        throw new Error('You have to implement this method in a subclass');
    }

    isComplete(data){
        return this.validate(data);
    }

    addStepInputToData(data, input) { //eslint-disable-line no-unused-vars
        throw new Error('You have to implement this method in a subclass');
    }

    getInvalidsFieldsMessages(){
        return [];
    }

    /**
     * Check completeness of the previous steps.
     *
     * @param {Array<Step>} previousSteps
     * @param {object} data
     * @return {null | string}
     */
    static checkPreviousSteps(previousSteps, data){
        for(let i = 0; i < previousSteps.length; i++) {
            let step = previousSteps[i];
            if (!step.isComplete(data))
                return step.getPathName(data);
        }
    }

    /**
     * Get step important data. With a simplified format that can be easily understood.
     *
     * @param {object} data
     * @return {*}
     */
    getStepData(data) { //eslint-disable-line no-unused-vars
        throw new Error('You have to implement this method in a subclass');
    }

    next(data) {
        let nextStep = this.config[this.getStepName()].nextStep;

        return `/${data.product}/${this.config[nextStep].url}`;
    }

    previous(data){
        let previousStep = this.config[this.getStepName()].previousStep;

        return `/${data.product}/${this.config[previousStep].url}`;
    }

    removeDataOfStep(session){ //eslint-disable-line no-unused-vars
        throw new Error('this is to implement in the sub class');
    }

    getPreviousStepName(sessionData){
        return this.getStepConfig(sessionData).previousStep;
    }

    setProjectId(id) {
        id = parseInt(id);
        if (id > 0) {
            this.projectId = id;
        }
    }

    setTranslateFunction(t) {
        if (typeof t !== 'function') {
            throw Error('Parameter t must be a function');
        }

        this.t = t;
    }
}


module.exports = Step;