'use strict';

import _ from 'lodash';
import 'whatwg-fetch';

let fetchAndCheck = (url, options) => {
    return fetch(url, options)
        .then((res) => {
            if (res.ok) {
                return res
                    .json()
                    .catch(() => {
                        if (res.status === 204) {
                            return Promise.resolve({});
                        }
                    })
                    .then(body => {
                        let status = _.get(body, 'error.status');

                        if (status && status > 200) {
                            return Promise.reject(body);
                        }

                        return body;
                    });
            } else {
                let error = new Error(res.statusText);
                error.status = res.status;

                return res
                    .json()
                    .catch(() => {
                        if (res.status === 403 && res.statusText === 'Forbidden')
                            return Promise.reject(new Error('Rejected by WAF'));

                        return Promise.reject(error);
                    })
                    .then(err => {
                        return Promise.reject(err);
                    });
            }
        });
};


export const fetchAndCheckWitHeaders = (url, options) => {
    return fetch(url, options)
        .then((res) => {
            if (res.ok) {
                return res
                    .json()
                    .then(body => {
                        let status = _.get(body, 'error.status');

                        if (status && status > 200) {
                            return Promise.reject(body);
                        }

                        return {
                            headers: res.headers,
                            body
                        };
                    });
            } else {
                let error = new Error(res.statusText);
                error.status = res.status;

                return res
                    .json()
                    .catch(() => {
                        if (res.status === 403 && res.statusText === 'Forbidden')
                            return Promise.reject(new Error('Rejected by WAF'));

                        return Promise.reject(error);
                    })
                    .then(err => {
                        return Promise.reject(err);
                    });
            }
        });
};

export default fetchAndCheck;