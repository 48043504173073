'use strict';

import React from 'react';
import PropTypes from 'prop-types';

class ResetConfirm extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {t} = this.props;

        return (
            <div id={this.props.id}>
                <p className="lead">{this.props.message}</p>

                <ul className="button-bar">
                    <li className="button-bar__item">
                        <button className="button button--red"
                            aria-label={t(this.props.resetButtonLabel)}
                            onClick={this.props.onResetButtonClicked}
                            type="button">
                            {t(this.props.resetButtonLabel)}
                        </button>
                    </li>

                    {
                        this.props.closebuttonLabel &&
                            <li className="button-bar__item">
                                <button className="button"
                                    onClick={this.props.onAbortReset}
                                    aria-label={t(this.props.closebuttonLabel)}
                                    type="button">
                                    {t(this.props.closebuttonLabel)}
                                </button>
                            </li>
                    }
                </ul>
            </div>
        )
    }
}

ResetConfirm.propTypes = {
    t: PropTypes.func,
    id: PropTypes.string,
    message: PropTypes.string,
    resetButtonLabel: PropTypes.string,
    onResetButtonClicked: PropTypes.func,
    closebuttonLabel: PropTypes.string,
    onAbortReset: PropTypes.func
};

export default ResetConfirm;