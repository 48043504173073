import React from 'react';
import PropTypes from 'prop-types';
import advancedFetch from '../advanced-fetch';
import translate from '../translate';
import DashboardView from '../dashboard';
import ModalNewProject from '../modal-new-project';
import cfg from '../../conf';

class DashboardData extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            projects: [],
            newProjectModal: false,
            newProjectCreated: false,
            createdProjectId: null
        };

        this.loadProjects = this.loadProjects.bind(this);
        this.createProject = this.createProject.bind(this);
        this.deleteProject = this.deleteProject.bind(this);
        this.closeProtocolModal = this.closeProtocolModal.bind(this);
        this.setNewProjectName = this.setNewProjectName.bind(this);
        this.proceedAfterProjectSave = this.proceedAfterProjectSave.bind(this);
    }

    componentDidMount() {
        this.props.toggleProgressBar(this.props.showProgressBar);
        this.props.toggleSideProtocol(this.props.showSideProtocol);
        this.loadProjects();
    }

    closeProtocolModal() {
        this.setState(prev => {
            prev.newProjectModal = !prev.newProjectModal;

            return prev;
        });
    }

    loadProjects(){
        advancedFetch(cfg.api.projectsApiUrlTemplate(), {credentials: 'include'})
            .then(result => {
                this.setState({projects: result});
            })
            .catch(err => {
                if (err.error.status === 404) this.props.history.replace('/404')
            });
    }

    setNewProjectName(projectName) {
        return advancedFetch(cfg.api.projectsApiUrlTemplate(), {
            method: 'POST',
            body: JSON.stringify({projectName: projectName}),
            credentials: 'include',
            projectName: projectName,
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(({projectId}) => {
                return new Promise(resolve => {
                    this.setState(prev => {
                        prev.createdProjectId = projectId;
                        prev.newProjectName = projectName;

                        return prev;
                    },
                    () => {
                        return resolve();
                    });
                })
            })
            .catch(err => {
                if (err.error.status === 404) this.props.history.replace('/404');
            });
    }

    proceedAfterProjectSave() {
        this.closeProtocolModal();
        if (this.state.createdProjectId) {
            this.props.history.push(`/project/${this.state.createdProjectId}`);
        }
    }

    createProject(){
        this.setState({newProjectModal: !this.state.newProjectModal});
    }


    deleteProject(id){
        advancedFetch(`/api/projects/${id}`, {
            method: 'DELETE',
            credentials: 'include'
        })
            .then(() => {
                this.loadProjects();
            })
            .catch(err => {
                console.error(err);
                if (err.error.status === 404) this.props.history.replace('/404')
            });
    }

    render() {
        const {t} = this.props;

        return (
            <div className="dashboard-data-comp">
                <h2>{t('Projektübersicht')}</h2>
                { this.state.projects.length === 0 && <div>{t('Es konnten keine Projekte gefunden werden')}</div> }
                <DashboardView
                    t={t}
                    projects={this.state.projects}
                    history={this.props.history}
                    onDelete={this.deleteProject}
                    onCreateProject={this.createProject}
                />
                {this.state.newProjectModal &&
                <ModalNewProject
                    projects={this.state.projects}
                    closeModalNewProject={this.closeProtocolModal}
                    saveConfiguration={this.proceedAfterProjectSave}
                    showModal={this.state.newProjectModal}
                    setNewProjectName={this.setNewProjectName}
                    history={this.props.history}
                    t={t}
                />
                }
            </div>
        )
    }
}

DashboardData.propTypes = {
    t: PropTypes.func.isRequired,
    history: PropTypes.any,
    eventHandler: PropTypes.object,
    showSideProtocol: PropTypes.bool,
    showProgressBar: PropTypes.bool,
    toggleProgressBar: PropTypes.func,
    toggleSideProtocol: PropTypes.func
};

export default translate(DashboardData);
