'use strict';

import React     from 'react';
import PropTypes from 'prop-types';

const Loader = ({size}) => {

    let className = `loader loader--${size}`;

    return <div className={className} />;
}

Loader.propTypes = {
    size: PropTypes.oneOf([
        'medium',
        'large',
        'small'
    ])
};

Loader.defaultProps = {
    size: 'small'
};

export default Loader;
