import React     from 'react';
import PropTypes from 'prop-types';
import moment    from 'moment';
import merge     from 'lodash.merge';
import {connect} from 'react-redux';
import {subject} from '@casl/ability';

import translate             from '../translate';
import Project               from '../project';
import {syncProjectInfoData} from '../project-infos/actions';

import cfg           from './../../conf';
import advancedFetch from './../advanced-fetch';
import dbField       from '../../lib/db-field';
import Modal         from 'sfp-react-modal';


class ProjectData extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalMailIsOpened: false,
            project: {},
            configurations: {},
            errorMessage: null,
            sendMailText: ''
        };

        this.loadData = this.loadData.bind(this);
        this.onRemoveConfiguration = this.onRemoveConfiguration.bind(this);
        this.onNewConfigurationClick = this.onNewConfigurationClick.bind(this);
        this.onEditConfiguration = this.onEditConfiguration.bind(this);
        this.onProjectInfoClick = this.onProjectInfoClick.bind(this);
        this.onOfferProject = this.onOfferProject.bind(this);
        this.onOrderProject = this.onOrderProject.bind(this);
        this.closeModalMail = this.closeModalMail.bind(this);
    }

    componentDidMount() {
        this.props.toggleProgressBar(this.props.showProgressBar);
        this.props.toggleSideProtocol(this.props.showSideProtocol);
        this.loadData();
    }

    render() {
        const {t} = this.props;
        let project = {
            configurations: this.state.configurations,
            object: this.props.projectData
        };

        return (
            <div className="projects-data-comp">
                <h2>{project.object.project_name}</h2>
                <Project
                    t={t}
                    project={project}
                    onNewConfigurationClick={this.onNewConfigurationClick}
                    onProjectInfoClick={this.onProjectInfoClick}
                    errorMessage={this.state.errorMessage}
                    onRemoveConfiguration={this.onRemoveConfiguration}
                    onEditConfiguration={this.onEditConfiguration}
                    ability={this.props.ability}
                />
                <div className="main-content-footer">
                    <ul className="button-bar">
                        <li className="button-bar__item">
                            <button
                                title={t('Projektübersicht')}
                                className="button button--object-back"
                                onClick={() => this.props.history.push('/dashboard')}/>
                        </li>

                        <li className="button-bar__item">
                            <button
                                title={t('Unverbindliche Offerte anfordern')}
                                className="button button--red"
                                disabled={!this.props.ability.can('offer', subject('Project', this.props.projectData))}
                                onClick={this.onOfferProject}>
                                {t('Unverbindliche Offerte anfordern')}
                            </button>
                        </li>

                        <li className="button-bar__item">
                            <button
                                title={t('Verbindliche Bestellung absenden')}
                                disabled={!this.props.ability.can('order', subject('Project', this.props.projectData))}
                                className="button button--red"
                                onClick={this.onOrderProject}>
                                {t('Verbindliche Bestellung absenden')}
                            </button>
                        </li>
                    </ul>
                </div>
                {this.state.sendMailText &&
                <Modal
                    id={'mail-modal'}
                    isOpen={this.state.modalMailIsOpened}
                    escClose={true}
                    backgroundClickClose={false}
                    customClasses={'mail-modal'}
                    closeHandler={this.closeModalMail}>
                    <div className="grid-container">
                        <span onClick={this.closeModalMail} className="close-container">
                            <div className="close"/>
                        </span>
                        <div className="grid-x grid-margin-x">
                            <div className="cell full-width mail-text">
                                <h2>{t(this.state.sendMailText)}</h2>
                            </div>

                            <button
                                className="button button--red close-mail-text"
                                onClick={this.closeModalMail}>
                                {t('Schliessen')}
                            </button>
                        </div>
                    </div>
                </Modal>
                }
            </div>
        );
    }

    closeModalMail() {
        this.setState({modalMailIsOpened: false});
        this.props.history.push('/dashboard');
    }

    loadData() {
        const {match: {params: {projectId: id}}, history} = this.props;

        advancedFetch(cfg.api.projectApiUrlTemplate(id), {credentials: 'include'})
            .then(project => {
                this.props.dispatch(syncProjectInfoData(project));
            })
            .then(() => {
                advancedFetch(cfg.api.configurationsAdditionalDataApiUrlTemplate(id), {credentials: 'include'})
                    .then(configurations => {
                        configurations.map(configuration => {
                            let lastModified = configuration.config[dbField.configuration.MODIFIED_AT];

                            let modifiedAt = configuration.config[dbField.configuration.MODIFIED_AT];

                            let createdAt = configuration.config[dbField.configuration.CREATED_AT];

                            if (lastModified) {
                                lastModified = moment(lastModified).format('DD.MM.YYYY');
                            }

                            if (modifiedAt) {
                                modifiedAt = moment(modifiedAt).format('hh:mm:ss DD.MM.YYYY');
                            }

                            if (createdAt) {
                                createdAt = moment(createdAt).format('hh:mm:ss DD.MM.YYYY');
                            }
                            let config = {
                                name: configuration.config[dbField.configuration.CONFIGURATION_NAME] || '',
                                price: configuration.config[dbField.configuration.PRICE] || '',
                                weight: configuration.config[dbField.configuration.WEIGHT] || '',
                                lastchange: lastModified || '',
                                modified: modifiedAt || '',
                                created: createdAt || '',
                                product: configuration.config[dbField.configuration.PRODUCT] || '',
                                main_dimension: (configuration.config[dbField.configuration.MAINMEASUREMENT] || 0),
                                floor_type: configuration.config[dbField.configuration.FLOOR] || '',
                                height: (configuration.config[dbField.configuration.HEIGHT_ABOVE_DATUM] || 0) + ' mm',
                                pipe_type: configuration.config[dbField.configuration.MATERIAL] || '',
                                diameter: (configuration.config[dbField.configuration.DIAMETER] || 0) + ' mm',
                                offerStatus: configuration.config[dbField.configuration.OFFER_STATUS] || 1,
                                hs: `${(configuration.config[dbField.configuration.HS] || 0)} mm`,
                                ts: configuration.config[dbField.configuration.TS] || '',
                                konus: configuration.config[dbField.configuration.KONUS] || '',
                                weightclass: configuration.config[dbField.configuration.WEIGHTCLASS] || '',
                                ladder: configuration.config[dbField.configuration.LADDER] || '',
                                comment: configuration.config[dbField.configuration.COMMENT] || '',
                                material: configuration.config[dbField.configuration.MATERIAL] || '',
                                fall: configuration.config[dbField.configuration.FALL] || '',
                                outfalldifference:
                                    configuration.config[dbField.configuration.DIFFERENCE_TO_OUTFALL] || ''
                            };

                            this.setState(prev => {
                                prev.configurations[configuration.config[dbField.configuration.ID]] = {
                                    config: config,
                                    sessionData: configuration.sessionData
                                };

                                return prev;
                            });
                        });
                    });
            })
            .catch(err => {
                if (err.error.status === 404) {
                    history.replace('/error');
                }
            });
    }

    onNewConfigurationClick() {
        advancedFetch(cfg.api.projectInitSession(), {
            credentials: 'include',
            method: 'POST'
        })
            .then(() => {
                this.props.history.push('/');
            });
    }

    onProjectInfoClick() {
        this.props.history.push(`/edit-project-info/${this.props.projectData[dbField.project.ID]}`);
    }

    onEditConfiguration(configurationId) {
        const {history} = this.props;
        configurationId = parseInt(configurationId);

        advancedFetch(cfg.api.configurationApiUrlTemplate(configurationId), {
            credentials: 'include'
        })
            .then(() => {
                history.push('/');
            });
    }

    onRemoveConfiguration(configurationId) {
        const {history} = this.props;
        configurationId = parseInt(configurationId);

        advancedFetch(cfg.api.configurationApiUrlTemplate(configurationId), {
            method: 'DELETE',
            credentials: 'include'
        })
            .then(() => {
                this.setState(prev => {
                    delete prev.configurations[configurationId];

                    return prev;
                });
            })
            .then(() => {
                // Fetch again to keep hasConfigurations in sync
                advancedFetch(
                    cfg.api.projectApiUrlTemplate(this.props.projectData[dbField.project.ID]),
                    {credentials: 'include'}
                )
                    .then(project => {
                        this.props.dispatch(syncProjectInfoData(project));
                    })
            })
            .catch(err => {
                console.error(err);
                if (err.error.status === 404) history.replace('/error')
            });
    }


    onOfferProject() {
        const {match: {params: {projectId: projectId}}} = this.props;

        return advancedFetch(
            cfg.api.projectOfferApiUrlTemplate(projectId),
            {
                credentials: 'include',
                method: 'post'
            }
        )
            .then(res => {
                this.setState({sendMailText: res.message});
                this.setState({modalMailIsOpened: true});
            })
            .catch(err => {
                console.error(err);

                this.setState({sendMailText: 'Es ist ein Fehler aufgetreten'});
                if (err.error && err.error.status === 403) document.location.href = '/dashboard'
            });
    }


    onOrderProject() {
        const {match: {params: {projectId: projectId}}} = this.props;

        return advancedFetch(
            cfg.api.projectOrderApiUrlTemplate(projectId),
            {
                credentials: 'include',
                method: 'post'
            }
        )
            .then((res) => {
                this.setState({sendMailText: res.message});
                this.setState({modalMailIsOpened: true});
            })
            .catch(err => {
                console.error(err);

                this.setState({sendMailText: 'Es ist ein Fehler aufgetreten'});
                if (err.error && err.error.status === 403) document.location.href = '/dashboard'
            });
    }
}

ProjectData.propTypes = {
    t: PropTypes.func.isRequired,
    match: PropTypes.any,
    history: PropTypes.any,
    response: PropTypes.any,
    ability: PropTypes.shape({
        can: PropTypes.func
    }),
    showSideProtocol: PropTypes.bool,
    showProgressBar: PropTypes.bool,
    toggleProgressBar: PropTypes.func,
    toggleSideProtocol: PropTypes.func,
    dispatch: PropTypes.func.isRequired,
    projectData: PropTypes.shape()
};

const mapStateToProps = state => {
    return merge({}, state.projectInfo, state.shared);
};

export default connect(mapStateToProps)(translate(ProjectData));
