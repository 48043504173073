'use strict';
const _ = require('lodash');

const Step = require('./../../steps/step');
const ProductSelectorStep = require('./../../steps/product');
const config = require('./../config/komplettschacht-configuration.json');

class StepMeasurement extends Step {

    constructor(props){
        props = props || {};

        props.config = props.config || config;
        props.stepName = 'mainMeasurementSelector';

        super(props);

        this.productSelector = new ProductSelectorStep();
    }

    getStepConfig(data){
        //If there is no session data then just return default config
        if(!data) return super.getStepConfig();

        let config = _.cloneDeep(super.getStepConfig());

        if(_.get(data, 'measurement') != null){
            _.set(config, ['input', 'measurement'], _.get(data, 'measurement'));
        }

        return config;
    }

    precondition(data) {
        if(!this.productSelector.isComplete(data)) return '/';

        return null;
    }

    validate(data) {
        if (data == null) return false;
        if (data.measurement == null) return false;

        return true;
    }


    addStepInputToData(data, input) {
        if (data.measurement && data.measurement !== input.measurement) {
            data.brunnenring = null;
            data.brunnenring600 = false;
            data.brunnenring800 = false;
        }
        data.measurement = input.measurement;

        return data;
    }

    removeDataOfStep(session) {
        _.set(session, ['data', 'measurement'], null);
        _.set(session, ['steps', this.getStepConfig().previousStep], false);
        _.set(session, ['steps', this.getStepName()], false)
    }

    /**
     * Get measure type string. Eg. 900/11000
     *
     * @param data
     * @return {string}
     */
    getStepData(data) {
        if(!data) return;

        let config = this.getStepConfig(data);

        let input = config.inputs.filter((input) => {
            return parseInt(input.key) === parseInt(data.measurement);
        });

        if (!input || input.length === 0) return null;

        if (input[0].data.width !== input[0].data.height)
            return input[0].data.width + '/' + input[0].data.height;

        return input[0].data.width + '';
    }

    previous(){
        return '/';
    }


}

module.exports = StepMeasurement;