'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import InputField from '../input-field';
import translate from '../translate';
import * as Actions from './../register/actions';
import merge from 'lodash.merge';
import {connect} from 'react-redux';
import {subject} from '@casl/ability';

class UserProfile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userData: {
                email: '',
                first_name: '',
                last_name: '',
                street: '',
                city: '',
                phone: ''
            }
        };

        this.backToProject = this.backToProject.bind(this);
        this.submit = this.submit.bind(this);
        this.load = this.load.bind(this);
        this.updateInputValue = this.updateInputValue.bind(this);
    }

    componentDidMount() {
        this.props.toggleProgressBar(this.props.showProgressBar);
        this.props.toggleSideProtocol(this.props.showSideProtocol);
        this.load();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userData !== this.props.userData) {
            this.setState({userData: this.props.userData});
        }
    }

    render() {
        const {t} = this.props;
        const disableFields = !this.props.shared.ability.can('update', subject('User', this.props.userData));

        return (
            <div className="project-info-comp">
                <div className="grid-container">
                    <h2>{t('Benutzerprofil')}</h2>
                    <div className="grid-x grid-margin-x">
                        <div className="cell small customer-number">
                            <InputField
                                t={t}
                                disabled={true}
                                type='text'
                                name={'email'}
                                label={t('Benutzername (kann nicht geändert werden)')}
                                value={this.state.userData.email}
                                change={this.updateInputValue}
                                error={
                                    this.props.invalidFields.fields.indexOf('email') >= 0 ?
                                        {message: t(this.props.invalidFields.details.customer_number)} :
                                        null
                                }
                                errorLayout={'bottom'}
                            />
                        </div>
                        <div className="cell small-5">
                            <InputField
                                t={t}
                                disabled={disableFields}
                                type='text'
                                name={'first_name'}
                                value={this.state.userData.first_name}
                                label={t('Vorname')}
                                change={this.updateInputValue}
                                placeholder={t('Optional')}
                                error={
                                    this.props.invalidFields.fields.indexOf('first_name') >= 0 ?
                                        {message: t(this.props.invalidFields.details.customer_number)} :
                                        null
                                }
                                errorLayout={'bottom'}
                            />
                        </div>
                        <div className="cell small-5">
                            <InputField
                                t={t}
                                disabled={disableFields}
                                type='text'
                                label={t('Nachname')}
                                value={this.state.userData.last_name}
                                name={'last_name'}
                                change={this.updateInputValue}
                                placeholder={t('Optional')}
                                error={
                                    this.props.invalidFields.fields.indexOf('last_name') >= 0 ?
                                        {message: t(this.props.invalidFields.details.company)} :
                                        null
                                }
                                errorLayout={'bottom'}
                            />
                        </div>
                        <div className="cell small-5">
                            <InputField
                                t={t}
                                disabled={disableFields}
                                type="text"
                                label={t('Strasse')}
                                value={this.state.userData.street}
                                placeholder={t('Optional')}
                                change={this.updateInputValue}
                                name={'street'}
                                error={
                                    this.props.invalidFields.fields.indexOf('street') >= 0 ?
                                        {message: t(this.props.invalidFields.details.first_name)} :
                                        null
                                }
                                errorLayout={'bottom'}
                            />
                        </div>
                        <div className="cell small-5">
                            <InputField
                                t={t}
                                disabled={disableFields}
                                type="text"
                                label={t('Ort')}
                                name={'city'}
                                placeholder={t('Optional')}
                                value={this.state.userData.city}
                                change={this.updateInputValue}
                                error={
                                    this.props.invalidFields.fields.indexOf('city') >= 0 ?
                                        {message: t(this.props.invalidFields.details.last_name)} :
                                        null
                                }
                                errorLayout={'bottom'}
                            />
                        </div>
                        <div className="cell small-5">
                            <InputField
                                t={t}
                                disabled={disableFields}
                                type="text"
                                label={t('Telefon')}
                                value={this.state.userData.phone}
                                change={this.updateInputValue}
                                placeholder={t('Optional')}
                                name={'phone'}
                                error={
                                    this.props.invalidFields.fields.indexOf('phone') >= 0 ?
                                        {message: t(this.props.invalidFields.details.street)} :
                                        null
                                }
                                errorLayout={'bottom'}
                            />
                        </div>
                        <div className="cell small-5">

                        </div>
                    </div>

                    <div className="main-content-footer">
                        <div className="cell small-5 container">
                            <button
                                className="button button--object-back"
                                onClick={this.backToProject}>
                            </button>

                            {
                                !disableFields &&
                                <button
                                    onClick={this.submit}
                                    className="button button--save-object-edit">{t('Änderungen speichern')}
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    load() {
        this.props.dispatch(Actions.getUserData(this.props.i18n.language));
    }

    backToProject() {
        this.props.history.push('/dashboard');
    }

    submit() {
        this.setState(prev => {
            prev.loading = true;
            return prev;
        },
        () => {
            this.props.dispatch(Actions.updateUserData(this.props.i18n.language, this.state.userData))
                .then(() => {
                    return this.setState(prev => {
                        prev.loading = false;

                        return prev;
                    });
                })
                .then(() => {
                    // back to project page
                    this.props.history.push('/dashboard');
                })
                .catch(err => {
                    console.error(err, 'error');
                });
        });
    }

    updateInputValue(event) {
        const data = {
            name: event.target.name,
            value: event.target.value
        };

        this.setState(prev => {
            prev.userData[data.name] = data.value;

            return prev;
        });
    }

}

UserProfile.propTypes = {
    t: PropTypes.func,
    dispatch: PropTypes.func.isRequired,
    i18n: PropTypes.any,
    projectData: PropTypes.any,
    history: PropTypes.any.isRequired,
    match: PropTypes.any,
    invalidFields: PropTypes.any,
    userData: PropTypes.any,
    shared: PropTypes.shape({
        ability: PropTypes.shape({
            can: PropTypes.func
        })
    }),
    showSideProtocol: PropTypes.bool,
    showProgressBar: PropTypes.bool,
    toggleProgressBar: PropTypes.func,
    toggleSideProtocol: PropTypes.func
};

const mapStateToProps = state => {
    return merge({}, state.projectInfo, {shared: state.shared, userData: state.userReducer.user});
};

export default connect(mapStateToProps)(translate(UserProfile));