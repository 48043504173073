import React     from 'react';
import PropTypes from 'prop-types';

class ProgressBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            itemsLoaded: false,
            navItems: []
        };

        // Trigger a change in the progress-bar by sending an object with these properties:
        // id: Id of the subStep according to nav-config
        // isCompleted: true or false.
        this.props.eventHandler.bind('update-progress-bar', this.updateItems.bind(this));
    }

    prepareItems(json) {
        let key = -1,
            items = json.map((step) => (
                {
                    key: ++key,
                    isActive: key === 0,    // Sets the first step as active
                    isCompleted: false,
                    stepName: step.stepName,
                    subStepsCount: step.subSteps.length,
                    subSteps: step.subSteps.map((subStep) => ({id: subStep.id, isCompleted: false}))
                }
            ));

        this.setState({
            navItems: items
        }, () => this.setState({
            itemsLoaded: true
        }));
    }

    load() {
        fetch('/api/navigation', {credentials: 'include'})
            .then((response) => {
                return response.json();
            })
            .then(json => {
                this.prepareItems(json);
            })
            .catch(err => {
                console.error(err);
            });
    }

    updateItems(data) {
        this.props.eventHandler.trigger('protocol');
        if (this.state.itemsLoaded) {

            let itemFound = false,
                setNextItemActive = true,   // This sets the first item always as active.
                items = this.state.navItems.map((item) => {

                    let completedSubStepsCount = 0;

                    item.subSteps.forEach((subStep) => {
                        if (subStep.id === data.id) {
                            itemFound = true;
                            subStep.isCompleted = data.isCompleted;
                        } else {
                            subStep.isCompleted = !itemFound;
                        }

                        if (subStep.isCompleted) {
                            completedSubStepsCount++;
                        }
                    });

                    if (setNextItemActive) {
                        item.isActive = true;
                        setNextItemActive = false;
                    } else {
                        item.isActive = false;
                    }

                    // Check if step is completed
                    if (item.subStepsCount === completedSubStepsCount) {
                        item.isActive = false;
                        item.isCompleted = true;

                        setNextItemActive = true;
                    } else {
                        item.isCompleted = false;
                    }

                    return item;
                });

            this.setState({
                navItems: items
            });
        } else {
            requestAnimationFrame(this.updateItems.bind(this, data));
        }
    }

    componentDidMount() {
        this.load();
    }

    render() {
        const {t, isVisible} = this.props;

        if (!isVisible) {
            return null;
        }

        let items = this.state.navItems.map((item) => {
            let linkClasses = ['progress-bar__link'],
                subStepsCount = item.subStepsCount,
                progress = '/' + subStepsCount;

            if (item.isActive) {
                linkClasses.push('is-active');

                progress = item.subSteps.reduce((count, subStep) => {
                    return subStep.isCompleted ? count + 1 : count;
                }, 0) + progress;
            }

            if (item.isCompleted) {
                linkClasses.push('is-completed');
            }

            return (
                <li key={item.key} className="progress-bar__item">
                    <span className={linkClasses.join(' ')}
                        data-progress={progress}><span>{t(item.stepName)}</span></span>
                </li>
            );
        });

        return (
            <ol className="progress-bar">
                {items}
            </ol>
        );
    }
}

ProgressBar.propTypes = {
    eventHandler: PropTypes.object,
    t: PropTypes.func,
    isVisible: PropTypes.bool
};

export default ProgressBar;
