'use strict';

import React         from 'react';
import PropTypes     from 'prop-types';
import advancedFetch from './../advanced-fetch';
import cfg           from '../../conf.js';
import ModalSchacht  from './../project-data/lib/modal-schacht';

const {
    api: {
        currentSessionApiUrlTemplate
    }
} = cfg;

class SaveConfiguration extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showModal: true,
            projectId: null,
            configurationName: '',
            configurationId: null,
            loadAllProjects: true,
            projects: []
        };

        this.closeModal = this.closeModal.bind(this);
        this.loadData = this.loadData.bind(this);
        this.saveConfiguration = this.saveConfiguration.bind(this);
        this.onBack = this.onBack.bind(this);
        this.updateListSelectedProject = this.updateListSelectedProject.bind(this);
        this.updateConfigurationName = this.updateConfigurationName.bind(this);
    }

    componentDidMount() {
        this.props.toggleProgressBar(this.props.showProgressBar);
        this.props.toggleSideProtocol(this.props.showSideProtocol);
        this.loadData();
    }

    render() {
        return (
            <ModalSchacht
                t={this.props.t}
                i18n={this.props.i18n}
                showModal={this.state.showModal}
                closeModal={this.closeModal}
                history={this.props.history}
                projectId={this.state.projectId}
                projects={this.state.projects}
                updateSelectedProject={this.updateListSelectedProject}
                saveConfiguration={this.saveConfiguration}
                updateConfigurationName={this.updateConfigurationName}
                configurationName={this.state.configurationName}
            />
        );
    }

    closeModal(goBack = false) {
        this.setState(prev => {
            prev.showModal = false;

            return prev;
        },
        () => {
            if (goBack === true) {
                this.onBack()
            }
        });
    }

    onBack() {
        fetch(`/api${this.props.location.pathname}/back`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((result) => {
                result.json().then(body => {
                    this.props.history.push(body.previous);
                });
            });
    }

    loadData() {
        advancedFetch(`/api${this.props.location.pathname}`, {credentials: 'include'})
            .then(response => {
                if (response.next) {
                    this.props.history.push(response.next);
                    return Promise.reject(new Error('done'));
                }
            })
            .then(() => {
                advancedFetch(currentSessionApiUrlTemplate(), {credentials: 'include'})
                    .then(config => {
                        const {
                            data: {
                                configurationName: configurationName = '',
                                configurationId: configurationId = null,
                                projectId: configurationProjectId = null
                            },
                            project: {
                                id: projectId = null
                            }
                        } = config;

                        this.setState({
                            configurationName: configurationName,
                            configurationId: configurationId,
                            projectId: configurationProjectId || projectId
                        });
                    })
                    .then(() => {
                        if (this.state.projectId) {
                            return Promise.reject(new Error('done'));
                        }
                        return advancedFetch(cfg.api.projectsApiUrlTemplate(), {credentials: 'include'})
                            .then(result => this.setState({projects: result}))
                            .catch(err => {
                                if (err.error.status === 404) {
                                    console.error('No projects found');
                                }
                            });
                    }).catch(err => {
                        if (err.message !== 'done') {
                            console.error(err);

                            this.props.history.push('/error');
                        }
                    });
            })
            .catch(err => {
                if (err.message !== 'done') {
                    console.error(err);

                    this.props.history.push('/error');
                }
            });
    }

    saveConfiguration() {
        let method = 'POST';
        let body = {
            configurationName: this.state.configurationName,
            projectId: this.state.projectId
        };
        if (this.state.configurationId) {
            method = 'PUT';
            delete body.projectId;
        }

        if (!this.state.projectId) {
            // cannot save at this point
            return Promise.resolve();
        }

        return advancedFetch(`/api${this.props.location.pathname}`, {
            credentials: 'include',
            method: method,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
            .then(response => {
                this.props.history.push(response.next);
                if (!response.error) {
                    return Promise.reject(new Error('done'));
                }
            });
    }

    updateListSelectedProject(id, cb = null) {
        id = parseInt(id);
        if (id > 0) {
            this.setState(prev => {
                prev.projectId = id;

                return prev;
            }, () => {
                if (cb) {
                    cb();
                }
            });
        }
    }

    updateConfigurationName(name) {
        if (name.trim()) {
            this.setState({
                configurationName: name
            });
        }
    }
}

SaveConfiguration.propTypes = {
    t: PropTypes.func.isRequired,
    history: PropTypes.any.isRequired,
    i18n: PropTypes.object,
    location: PropTypes.object,
    showSideProtocol: PropTypes.bool,
    showProgressBar: PropTypes.bool,
    toggleProgressBar: PropTypes.func.isRequired,
    toggleSideProtocol: PropTypes.func.isRequired
};

export default SaveConfiguration;