import React     from 'react';
import translate from '../translate';
import PropTypes from 'prop-types';
const {version} = require('../../../package.json');

class Footer extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        const {t} = this.props;

        return <footer className="app-footer">
            <ul className="footer-items">
                <li>{t('ACO AG')}</li>
                <li>Industrie Kleinzaun</li>
                <li>CH-8754 Netstal</li>
                <li>Helpdesk ACO Schachtkonfigurator: schacht@aco.ch</li>
                <li className="version-align-left">Version: {version}</li>
            </ul>
        </footer>
    }
}

Footer.propTypes = {
    t: PropTypes.func
};

export default (translate(Footer));