'use strict';

const Measurement = require('./measurement');
const FloorType = require('./floortype');
const config = require('./../config/schlammsammler-configuration.json');
const _ = require('lodash');

const KomplettSchachtStepTypeDimension = require('../../komplettschacht-configurator/steps/typeOfDimension');

class StepTypeDimension extends KomplettSchachtStepTypeDimension {

    constructor(props){
        props = props || {};

        props.config = config;

        super(props);

        this.measurement = new Measurement();
        this.floorType = new FloorType();
    }

    next(data) {
        let nextStep = this.config[this.getStepName()].nextStep;

        return `/${data.product}/${this.config[nextStep].url}`;
    }

    /**
     *
     * @param sessionData
     * @private
     */
    _getMinMax(sessionData) {
        let min, max;
        let NW = this.measurement.getStepData(sessionData);

        min = Math.min.apply(null, _.get(config, ['outfallSelector', 'diametersConfig', NW, 'min']).filter(Boolean));
        max = Math.max.apply(null, _.get(config, ['outfallSelector', 'diametersConfig', NW, 'max']).filter(Boolean));

        return {
            min: min,
            max: max
        }
    }
}

module.exports = StepTypeDimension;