'use strict';
import _ from 'lodash';

const {createReducer} = require('./../../lib/reducer');

let initialState = {
    invalidFields: [],
    error: null,
    loading: false,
    isSaving: false,
    success: null,
    result: null
};

export const resetPasswordRequestReducer = createReducer(initialState, {

    //Update Farm data to server
    'reset-password-request_PENDING': (state) => {
        state.success = undefined;
        state.error = undefined;

        return _.merge({}, state, {isSaving: true});
    },


    'reset-password-request_FULFILLED': (state, action) => {

        //Reset invalid fields
        state.invalidFields = [];

        //Reset general error
        state.error = undefined;

        return _.merge(
            {},
            state,
            {
                isSaving: false,
                success: true,
                result: action.payload
            }
        );
    },

    'reset-password-request_REJECTED': (state, action) => {

        //Reset invalid fields
        state.invalidFields = {
            fields: [],
            details: {}
        };

        let error = action.payload.error;
        let message = action.payload.message;
        let invalidFields = _.get(error, 'details.codes', {});

        return _.merge({}, state, {
            isSaving: false,
            error: message,
            invalidFields: Object.keys(invalidFields).map(error => {
                return {
                    fieldName: error,
                    message: invalidFields[error][0]
                }
            }),
            success: false
        });
    }
});