'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';

const translate = (WrappedComponent) => {

    class TranslatedComponent extends React.Component {

        static get component(){
            return WrappedComponent;
        }

        constructor(props) {
            super(props);
        }


        render() {
            if (!this.props.i18n.isInitialized) {
                return <div className="loader-container"><span className="loader loader--medium"></span></div>;
            }

            return <WrappedComponent {...this.props} />
        }
    }

    TranslatedComponent.propTypes = {
        i18n: PropTypes.object
    };

    return withTranslation([])(TranslatedComponent);
};

export default translate;

