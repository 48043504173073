'use strict';

import React from 'react';
import PropTypes from 'prop-types';

import InputRadioGroup from '../input-radio-group';
import InputSelect from '../input-select/index';
import InputTextarea from '../input-textarea/index';
import InputField from '../input-field';
import InputRadioFieldCombo from '../input-radio-field-combo';

class Inlet extends React.Component {
    constructor(props) {
        super(props);

        this.onChangePipetype = this.onChangePipetype.bind(this);
        this.onChangeDiameter = this.onChangeDiameter.bind(this);
        this.onChangePipeSeries = this.onChangePipeSeries.bind(this);
        this.onChangeFall = this.onChangeFall.bind(this);
        this.onChangeWinkel = this.onChangeWinkel.bind(this);
        this.onChangeComment = this.onChangeComment.bind(this);
    }

    filterOutputOptions(options) {
        return options.filter(option => option.key !== 'no_inlet');
    }

    render() {
        const {t} = this.props;

        return (
            <div className="comp-inlet">

                <div className="grid-container">
                    <div className="grid-x grid-padding-x">
                        <div className="cell small-6 xlarge-5">
                            <h2>
                                {t('Definieren Sie den Schachtboden mit Gerinne:')}
                                <strong>{t('Einlauf')} {this.props.nameIndex}</strong></h2>
                            <form>
                                <div className="grid-x">
                                    <div className="cell">
                                        <InputRadioGroup
                                            t={t}
                                            options={ this.props.index > 0 ?
                                                this.filterOutputOptions(this.props.pipetypeOptions) :
                                                this.props.pipetypeOptions
                                            }
                                            value={this.props.activePipetype || ''}
                                            name={'pipetype' + this.props.index}
                                            onChange={this.onChangePipetype}
                                            id={this.props.index}
                                            optional={false}
                                        />

                                        <InputSelect
                                            t={t}
                                            label={t('Durchmesser')}
                                            disabled={
                                                this.props.activePipetype !== 'no_inlet'
                                                && this.props.diameterOptions
                                                && this.props.diameterOptions.length > 0 ?
                                                    '' : 'disabled'
                                            }
                                            options={this.props.diameterOptions}
                                            value={this.props.activeDiameter || ''}
                                            name={'diameter' + this.props.index}
                                            id={'diameter' + this.props.index}
                                            onChange={this.onChangeDiameter}
                                            optional={false}
                                        />

                                        {
                                            this.props.pipeSeriesOptions.length === 0 ? '' :
                                                <InputSelect
                                                    t={t}
                                                    label={t('Rohrserie')}
                                                    disabled={
                                                        this.props.activePipetype &&
                                                        this.props.activePipetype !== 'no_inlet' ?
                                                            '' :
                                                            'disabled'
                                                    }
                                                    options={this.props.pipeSeriesOptions}
                                                    value={this.props.pipeseries || ''}
                                                    name={'pipeseries'}
                                                    onChange={this.onChangePipeSeries}
                                                    error={
                                                        this.props.fieldsWithErrors.filter(field => {
                                                            return field.fieldName === 'pipeseries' &&
                                                                field.index_in_array === this.props.index
                                                        })[0]
                                                    }
                                                    optional={false}
                                                />
                                        }

                                        <InputField
                                            t={t}
                                            label={t('Gefälle')}
                                            disabled={this.props.activePipetype !== 'no_inlet'
                                            && this.props.activePipetype ? '' : 'disabled'}
                                            placeholder="0"
                                            type="number"
                                            value={this.props.fallInput || ''}
                                            change={this.onChangeFall}
                                            unit="permil"
                                            error={
                                                this.props.fieldsWithErrors.filter(field => {
                                                    return field.fieldName === 'gefalle' &&
                                                        field.index_in_array === this.props.index
                                                })[0]
                                            }
                                        />

                                        <InputField
                                            t={t}
                                            label={t('Winkel')}
                                            type="number"
                                            placeholder="0-359"
                                            disabled={
                                                this.props.activePipetype &&
                                                this.props.activePipetype !== 'no_inlet' ? '' : 'disabled'}
                                            change={this.onChangeWinkel}
                                            value={this.props.winkelInput || ''}
                                            unit="degree"
                                            error={
                                                this.props.fieldsWithErrors.filter(field => {
                                                    return field.fieldName === 'winkel' &&
                                                        field.index_in_array === this.props.index
                                                })[0]
                                            }
                                            optional={false}
                                        />
                                    </div>

                                    { this.props.activePipetype && this.props.activePipetype !== 'no_inlet'  &&
                                    <div className="cell">
                                        {
                                            this.props.abstand ? <InputRadioFieldCombo
                                                t={t}
                                                onRadioSelectChange={this.props.onRadioSelectChangeAbstandAndHohenkote}
                                                onInputChange={this.props.onInputChangeAbstandAndHohenkote}
                                                type={this.props.abstand}
                                                id={this.props.index}
                                                typeSelected={this.props.abstandHohenkoteSelected}
                                                hasError={!!this.props.fieldsWithErrors.filter(field => {
                                                    return (
                                                        field.fieldName === 'abstand_zur_sohle_auslauf' &&
                                                        field.index_in_array === this.props.index
                                                    );
                                                })[0]}
                                                errorMessage={
                                                    this.props.fieldsWithErrors.filter(field => {
                                                        return (
                                                            field.fieldName === 'abstand_zur_sohle_auslauf' &&
                                                            field.index_in_array === this.props.index
                                                        );
                                                    })[0] ?
                                                        this.props.fieldsWithErrors.filter(field => {
                                                            return (
                                                                field.fieldName === 'abstand_zur_sohle_auslauf' &&
                                                                field.index_in_array === this.props.index
                                                            );
                                                        })[0].message :
                                                        ''
                                                }
                                                optional={false}
                                            /> : ''
                                        }
                                        {
                                            this.props.hohenkote ? <InputRadioFieldCombo
                                                t={t}
                                                onRadioSelectChange={this.props.onRadioSelectChangeAbstandAndHohenkote}
                                                onInputChange={this.props.onInputChangeAbstandAndHohenkote}
                                                type={this.props.hohenkote}
                                                id={this.props.index}
                                                typeSelected={this.props.abstandHohenkoteSelected}
                                                hasError={!!this.props.fieldsWithErrors.filter(field => {
                                                    return (
                                                        field.fieldName === 'hohenkote_ab_ok_schacht' &&
                                                        field.index_in_array === this.props.index
                                                    );
                                                })[0]}
                                                errorMessage={
                                                    this.props.fieldsWithErrors.filter(field => {
                                                        return (
                                                            field.fieldName === 'hohenkote_ab_ok_schacht' &&
                                                            field.index_in_array === this.props.index
                                                        );
                                                    })[0] ?
                                                        this.props.fieldsWithErrors.filter(field => {
                                                            return (
                                                                field.fieldName === 'hohenkote_ab_ok_schacht' &&
                                                                field.index_in_array === this.props.index
                                                            );
                                                        })[0].message :
                                                        ''
                                                }
                                                optional={false}
                                            /> : ''
                                        }
                                    </div>
                                    }

                                    { this.props.activePipetype && this.props.activePipetype !== 'no_inlet' &&
                                    <div className={'cell'}>
                                        <InputTextarea
                                            t={t}
                                            label={t('Kommentar')}
                                            disabled={!this.props.activePipetype}
                                            value={this.props.commenInput || ''}
                                            onChange={this.onChangeComment}
                                        />
                                    </div>
                                    }

                                </div>
                            </form>
                        </div>

                        <div className="cell small-6 xlarge-7">
                            <div className="cf">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onChangePipetype(value) {
        if (value) {
            this.props.updatePipetype(value);
        }
    }

    onChangeDiameter(e) {
        let target = e.target;

        if (target) {
            this.props.updateDiameter(target.value);
        }
    }

    onChangeFall(e) {
        let target = e.target;

        if (target) {
            this.props.updateFall(target.value)
        }
    }

    onChangePipeSeries(e) {
        let target = e.target;

        if (target) {
            this.props.updatePipeSeries(target.value)
        }
    }

    onChangeWinkel(e) {
        let target = e.target;

        if (target) {
            this.props.updateWinkel(target.value)
        }
    }

    onChangeComment(value) {
        if (value) this.props.updateComment(value);
    }
}

Inlet.propTypes ={
    updatePipetype: PropTypes.func,
    updatePipeSeries: PropTypes.func,
    updateDiameter: PropTypes.func,
    updateFall: PropTypes.func,
    updateWinkel: PropTypes.func,
    updateComment: PropTypes.func,
    onRadioSelectChangeAbstandAndHohenkote: PropTypes.func,
    onInputChangeAbstandAndHohenkote: PropTypes.func,

    index: PropTypes.number,
    nameIndex: PropTypes.number,
    noInlet: PropTypes.bool,
    pipetypeOptions: PropTypes.array,
    activePipetype: PropTypes.string,
    diameterOptions: PropTypes.array,
    activeDiameter: PropTypes.number,
    fallInput: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    title: PropTypes.string,
    winkelInput: PropTypes.number,
    commenInput: PropTypes.string,
    abstand: PropTypes.object,
    hohenkote: PropTypes.object,
    abstandHohenkoteSelected: PropTypes.string,
    fieldsWithErrors: PropTypes.array,
    pipeseries: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    pipeSeriesOptions: PropTypes.array,
    t: PropTypes.func
};

Inlet.defaultProps = {
    pipeSeriesOptions: []
};

export default Inlet;