'use strict';
const _ = require('lodash');
const Step = require('./../../steps/step');
const ProductSelectorStep = require('./../../steps/product');
const MeasurementStep = require('./measurement');

const config = require('./../config/schlammsammler-configuration.json');

class FloorTypeStep extends Step {

    constructor(props) {
        props = props || {};

        props.config = config;
        props.stepName = 'floorTypeSelector';

        super(props);

        this.productSelector = new ProductSelectorStep();
        this.measurement = new MeasurementStep();
    }

    getStepConfig(sessionData){
        let dn = this.measurement.getStepData(sessionData);

        let configCopy = _.cloneDeep(super.getStepConfig());

        if(
            dn === '700' ||
            dn === '800' ||
            dn === '1000' ||
            dn === '1200' ||
            dn === '1500' ||
            dn === '2000'
        ) {
            configCopy.floorTypes = configCopy.floorTypes.filter(floortype => {
                return floortype.key === 'gerader_boden' ||
                    floortype.key === 'wannen_boden';
            });
        }

        if(dn === '600' || dn === '2500'){
            configCopy.floorTypes = configCopy.floorTypes.filter(floortype => {
                return floortype.key === 'gerader_boden';
            });
        }

        this.addStepInputToData(configCopy.inputs, sessionData);

        return configCopy;
    }


    precondition(data) {
        if(!this.productSelector.isComplete(data)) return '/';
        if(!this.measurement.isComplete(data)) return this.measurement.getPathName(data);

        return null;
    }


    validate(data) {
        if (data == null) return false;
        return data.floortype != null;
    }


    addStepInputToData(data, input) {
        data.floortype = input.floortype;

        return data;
    }

    removeDataOfStep(session){
        _.set(session, ['data', 'floortype'], null);

        _.set(session, ['steps', this.config[this.getStepName()].previousStep], false);
        _.set(session, ['steps', this.getStepName()], false)
    }

    /**
     *
     * @param data
     * @return {{ floortype: string }}
     */
    getStepData(data) {
        let config = this.getStepConfig(data);
        let input = config.floorTypes.filter(input => {
            return input.value === parseInt(data.floortype + '');
        });

        if (!input || input.length === 0) return {};

        return {
            floortype: input[0].key
        };
    }
}

module.exports = FloorTypeStep;
