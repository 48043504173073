'use strict';

import React     from 'react';
import 'whatwg-fetch';
import PropTypes from 'prop-types';

import FetchUtils from './../../advancd-fetch';
import FloorType  from './../floortype';

class FloorTypeData extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            input: {
                floortype: null,
                productId: null
            },
            rightImageSrc: '',
            floortypes: [],
            allowNext: false,
            products: [],
            fieldsWithError: [],
            optionalChecked: false
        };

        this.load = this.load.bind(this);
        this.update = this.update.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.allowNext = this.allowNext.bind(this);
        this.onBack = this.onBack.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
        this.stepId = 'floorTypeSelector';

        // pare this. because somehow we come through the constructor relatively often
        this.props.eventHandler.unbind('next-clicked', this.handleNext);
        this.props.eventHandler.bind('next-clicked', this.handleNext);
        this.props.eventHandler.unbind('back-clicked', this.onBack);
        this.props.eventHandler.bind('back-clicked', this.onBack);
    }

    _handleKeyDown(event) {
        let ENTER_CODE = 13;

        if (event.keyCode === ENTER_CODE) {
            event.preventDefault();

            if (this.allowNext(this.state)) {
                this.props.eventHandler.trigger('next-clicked');
            }

            return false;
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this._handleKeyDown);
    }

    componentDidMount() {
        document.addEventListener('keydown', this._handleKeyDown);
        this.props.eventHandler.trigger('protocol');
        this.props.toggleProgressBar(this.props.showProgressBar);
        this.props.toggleSideProtocol(this.props.showSideProtocol);
        this.load();
    }


    handleNext() {
        this.update();
    }

    allowNext(state) {
        if (!state.input.floortype) return false;
        if (!state.floortypes.find(floortype => parseInt(state.input.floortype + '') === floortype.value)) {
            return false;
        }

        if (state.input.floortype === 3) {
            return !(state.optionalChecked && !state.input.productId);
        }

        return true;
    }


    load() {
        fetch(`/api${this.props.location.pathname}`, {credentials: 'include'})
            .then((response) => {
                return FetchUtils.checkStatus(response);
            })
            .then(response => {
                this.props.eventHandler.trigger('update-progress-bar', {id: this.stepId, isCompleted: false});

                if (response.next) {
                    this.props.history.push(response.next);
                    return Promise.reject(new Error('done'));
                }

                if (response.rightImage) {
                    this.setState({rightImageSrc: response.rightImage.src})
                }

                if (response.floorTypes) {
                    this.setState(prev => {
                        prev.floortypes = response.floorTypes.map((option) => {
                            if (option.key) {
                                option.id = option.key;
                            }

                            return option;
                        });

                        prev.showProductTypeSelection = response.showProductTypeSelection;
                        prev.input.floortype = parseInt(response.inputs.floortype) || null;

                        const productId = parseInt(response.inputs.productId);
                        prev.input.productId = productId || null;
                        prev.optionalChecked = !!productId;

                        prev.allowNext = this.allowNext(prev);

                        return prev;
                    });
                }
            })
            .then(() => {
                return fetch('/api/komplettschacht/floortype/norm-schachtbodens', {credentials: 'include'})
                    .then((response) => response.json())
                    .then((json) => {
                        if (json) this.setState({products: json})
                    })
            })
            .catch(err => {
                if (err.message !== 'done') {
                    console.error(err);

                    this.props.history.push('/error');
                }
            });


    }

    update() {
        let dataToSend = {
            floortype: this.state.input.floortype
        };

        dataToSend.productId = null;
        if (this.state.input.floortype === 3) {
            if (this.state.optionalChecked) {
                dataToSend.optionalChecked = this.state.optionalChecked;
            }

            if (this.state.input.productId) {
                dataToSend.productId = this.state.input.productId;
            }
        }

        fetch(`/api${this.props.location.pathname}`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataToSend)
        })
            .then((result) => {
                result.json().then(body => {
                    if (body.error) {
                        this.setState(prev => {
                            prev.fieldsWithError = body.error.invalidFields;
                            return prev;
                        });

                        return;
                    }

                    this.props.history.push(body.next);
                });
            })
    }


    onUpdate(value, selectedProduct, optionalIsChecked) {
        this.setState((prev) => {
            value = parseInt(value);
            prev.input.floortype = value;
            prev.optionalChecked = optionalIsChecked;

            prev.input.productId = selectedProduct;

            prev.allowNext = this.allowNext(prev);
            return prev;
        });
    }

    onBack() {
        fetch(`/api${this.props.location.pathname}/back`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((result) => {
                result.json().then(body => {
                    this.props.history.push(body.previous);
                });
            });
    }

    render() {
        const {t} = this.props;
        return (
            <FloorType
                t={t}
                eventHandler={this.props.eventHandler}
                location={this.props.location}
                history={this.props.history}

                update={this.onUpdate}
                options={this.state.floortypes}

                activeId={this.state.input.floortype}
                productId={this.state.input.productId}
                optionalChecked={this.state.optionalChecked}
                allowNext={this.state.allowNext}

                products={this.state.products}
                showProductTypeSelection={this.state.showProductTypeSelection}

                fieldsWithError={this.state.fieldsWithError}
                rightImageSrc={this.state.rightImageSrc}
            />
        );
    }
}

FloorTypeData.propTypes = {
    t: PropTypes.func,
    history: PropTypes.object,
    location: PropTypes.object,
    eventHandler: PropTypes.object,
    showSideProtocol: PropTypes.bool,
    showProgressBar: PropTypes.bool,
    toggleProgressBar: PropTypes.func,
    toggleSideProtocol: PropTypes.func
};

export default FloorTypeData;