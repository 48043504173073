'use strict';

import React     from 'react';
import PropTypes from 'prop-types';

class InputTextarea extends React.Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }


    render() {
        const {t} = this.props;
        let inputClasses = 'input',
            requiredOptionalClass = 'input--optional',
            labelClass = 'label--content';

        if (!this.props.optional) {
            requiredOptionalClass = 'input--required';
            labelClass += this.props.showRequiredSign ? '' : ' hide-sign';
        }

        let labelOutput = '';
        if (this.props.label !== '' && this.props.label !== undefined) {
            labelOutput = (
                <span className={labelClass}>{t(this.props.label)}</span>
            );
        }

        if (this.props.disabled) {
            inputClasses += ' disabled';
        }

        return (
            <label className={`${inputClasses} ${requiredOptionalClass}`}>
                {labelOutput}
                <span className="input__control">
                    <textarea name={this.props.name}
                        disabled={this.props.disabled}
                        onChange={this.onChange}
                        value={this.props.value || ''}
                        placeholder={this.props.placeholder || ''}
                    />
                    <span className="input__error-right">{t('Error message')}</span>
                </span>
            </label>
        )
    }

    onChange(e) {
        let target = e.target;

        if (target) {
            this.props.onChange(target.value);
        }
    }
}

InputTextarea.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.any,
    onChange: PropTypes.func,
    t: PropTypes.func.isRequired,
    optional: PropTypes.bool,
    showRequiredSign: PropTypes.bool
};

InputTextarea.defaultProps = {
    disabled: false,
    optional: true,
    showRequiredSign: true
};

export default InputTextarea;