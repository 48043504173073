import _ from 'lodash';

class FetchUtils {
    static checkStatus(res) {
        if (res.ok) {
            return res
                .json()
                .then(body => {
                    let status = _.get(body, 'error.status');
                    if (status && status > 200) {

                        return Promise.reject(body);
                    }

                    return body;
                });
        } else {
            return res
                .json()
                .then(err => {
                    return Promise.reject(err);
                });
        }
    }
}

export default FetchUtils;