import React from 'react';
import PropTypes from 'prop-types';

const ImgContainer = (props) => {
    return (
        <div className="image-container">
            {
                !!props.src && <img
                    src={props.src}
                    alt={props.alt}
                    title={props.title} />
            }

        </div>
    );
};

ImgContainer.defaultProps = {
    alt: '',
    title: ''
};

ImgContainer.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    title: PropTypes.string
};

export default ImgContainer;