'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'sfp-react-modal';
import InputField from '../../input-field'
import ProjectList from '../../project-list'
import advancedFetch from '../../advanced-fetch';
import cfg from '../../../conf';
import NewProjectModal from './../../modal-new-project';

class ModalSchacht extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            saveButtonDisabled: true,
            showSelectionModal: false,
            showNewProjectModal: false,
            showProjectList: false,
            customModalClass: 'project-list-modal--hide',
        };

        this.updateInputValue = this.updateInputValue.bind(this);
        this.showSelectionModal = this.showSelectionModal.bind(this);
        this.showNewProjectModal = this.showNewProjectModal.bind(this);
        this.showProjectList = this.showProjectList.bind(this);
        this.createNewProject = this.createNewProject.bind(this);
        this.updateListSelectedProject = this.updateListSelectedProject.bind(this);
        this.closeNewProjectModal = this.closeNewProjectModal.bind(this);
        this.renderProjectListModalContent = this.renderProjectListModalContent.bind(this);
        this.saveSchacht = this.saveSchacht.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.configurationName !== this.props.configurationName) {
            this.setState((prev, props) => {
                return {saveButtonDisabled: props.configurationName === ''};
            })
        }
    }

    showSelectionModal() {
        this.setState({
            showSelectionModal: true
        });
    }

    closeNewProjectModal() {
        this.setState(prev => {
            return {showNewProjectModal: !prev.showNewProjectModal}
        });
    }

    showProjectList() {
        this.setState({
            showProjectList: true,
            customModalClass: 'project-list-modal'
        });
    }

    createNewProject(projectName, cb = null) {
        return advancedFetch(cfg.api.projectsApiUrlTemplate(), {
            method: 'POST',
            body: JSON.stringify({projectName: projectName}),
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                this.updateListSelectedProject(response.projectId, cb);
                this.closeNewProjectModal();

                return Promise.resolve();
            })
            .catch(err => {
                if (err.error.status === 404) this.props.history.replace('/404');
            });
    }

    updateListSelectedProject(id, cb = null) {
        id = parseInt(id);
        if (id > 0) {
            this.props.updateSelectedProject(id, cb);
        }
    }

    showNewProjectModal() {
        this.setState({
            showNewProjectModal: true
        });
    }

    updateInputValue(event) {
        event.preventDefault();

        if(event.target.value.length === 0) {
            this.setState({
                saveButtonDisabled: true
            });
        } else {
            this.setState({
                saveButtonDisabled: false
            });
            this.props.updateConfigurationName(event.target.value);
        }
    }

    renderConfigNameModalContent(t) {
        return (
            <div className='schacht-config-content'>
                <div className="grid-container">
                    <span onClick={() => {this.props.closeModal(true)}} className='close-container'>
                        <div className='close'/>
                    </span>
                    <div className="schacht-modal">
                        {!this.state.showSelectionModal && !this.state.showProjectList
                        && !this.state.showNewProjectModal &&
                        <div className="grid-x grid-margin-x">
                            <div className="cell full-width">
                                <h2>{t('Bitte geben Sie einen Namen für Ihre Schachtkonfiguration ein:')}</h2>
                            </div>
                            <div className="cell small-5">
                                <InputField
                                    t={t}
                                    disabled={false}
                                    name={'schachtName'}
                                    tabIndex="0"
                                    type="text"
                                    change={this.updateInputValue}
                                    value={this.props.configurationName}
                                />
                            </div>
                            <div className="cell full-width">
                                <button
                                    onClick={() => {
                                        this.saveSchacht();
                                    }}
                                    disabled={this.state.saveButtonDisabled}
                                    className="button button--save-schacht-config">{t('Schacht speichern')}
                                </button>
                            </div>
                            <div className="cell small-5">
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        );
    }

    saveSchacht() {
        this.props.saveConfiguration()
            .then(() => {
                return this.showSelectionModal();
            })
            .catch(err => {
                if (err.message !== 'done') {
                    console.error(err);

                    this.props.history.push('/error');
                }
            });
    }

    renderProjectSelectionModalContent(t) {
        return (
            <div className='schacht-project-list-content'>
                <div className="grid-container">
                    <span onClick={() => {this.props.closeModal(true)}} className="close-container">
                        <div className="close"/>
                    </span>
                    <div className="schacht-modal">
                        <div className="grid-x grid-margin-x">
                            <div className="cell full-width select-project-list-modal">
                                <h2>
                                    {t('Möchten Sie Ihre Schachtkonfiguration ' +
                                        'einem bestehenden Projekt zuordnen ' +
                                        'oder dafür ein neues Projekt erstellen?')}</h2>
                            </div>
                            {this.props.projects.length > 0 &&
                                <div className="cell small-6">
                                    <button
                                        onClick={this.showProjectList}
                                        className="button button--existing-project">
                                        {t('Bestehendes Projekt auswählen')}
                                    </button>
                                </div>
                            }
                            <div className="cell small-4">
                                <button
                                    onClick={this.showNewProjectModal}
                                    className="button button--new-project">{t('Neues Projekt')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderProjectListModalContent(t) {
        return (
            <div className='schacht-project-list-content'>
                <div className="grid-container">
                    <span onClick={() => {this.props.closeModal(true)}} className="close-container">
                        <div className="close"/>
                    </span>
                    <div className="schacht-modal">
                        <div className="grid-x grid-margin-x">
                            <div className="cell full-width">
                                <h2>{t('Bitte wählen Sie ein Projekt aus, welchem Sie Ihren Schacht ' +
                                    'zuordnen möchten')}</h2>
                            </div>
                            <div className="cell full-width">
                                <ProjectList
                                    updateSelectedProject={this.updateListSelectedProject}
                                    selectedProjectId={this.props.projectId}
                                    projects={this.props.projects}
                                    t={t}
                                />
                            </div>
                            <div className="cell small-5">
                                <button
                                    className={'button button--choose-project'}
                                    onClick={this.props.saveConfiguration}
                                >
                                    {t('Auswählen')}
                                </button>
                            </div>
                            <div className="cell small-5">
                                <div className="cell small-5">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderSchachtnameBoxInstance() {
        const {t} = this.props;

        return <div>
            <span className='modal-box'/>
            <div className={'modal-box-content'}>
                <Modal
                    id={'schacht-modal'}
                    isOpen={this.props.showModal}
                    escClose={true}
                    backgroundClickClose={false}
                    customClasses={this.state.customModalClass}
                    closeHandler={() => {this.props.closeModal(true)}}>
                    {this.renderConfigNameModalContent(t)}
                    {this.state.showSelectionModal && !this.state.showProjectList && !this.state.showNewProjectModal &&
                        this.renderProjectSelectionModalContent(t)
                    }
                    {this.state.showProjectList &&
                        this.renderProjectListModalContent(t)
                    }
                </Modal>
                <NewProjectModal
                    t={t}
                    showModal={this.state.showNewProjectModal}
                    closeModalNewProject={this.closeNewProjectModal}
                    saveConfiguration={this.props.saveConfiguration}
                    projects={this.props.projects}
                    setNewProjectName={this.createNewProject}
                    history={this.props.history}
                    i18n={this.props.i18n}
                >
                </NewProjectModal>
            </div>
        </div>;
    }

    render() {
        return (
            <div className="modal-box-cont">
                {this.renderSchachtnameBoxInstance()}
            </div>
        )
    }
}

ModalSchacht.defaultProps = {
    projects: []
};

ModalSchacht.propTypes = {
    t: PropTypes.func,
    showModal: PropTypes.bool,
    closeModal: PropTypes.func,
    i18n: PropTypes.any,
    history: PropTypes.object,
    projects: PropTypes.array,
    updateSelectedProject: PropTypes.func,
    projectId: PropTypes.number,
    saveConfiguration: PropTypes.func,
    updateConfigurationName: PropTypes.func,
    configurationName: PropTypes.string
};

export default (ModalSchacht);
