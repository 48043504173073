import React from 'react';
import PropTypes from 'prop-types';
import ConfigurationsTable from './lib/configurations-table';
import {subject} from '@casl/ability';

class Project extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            status: null
        };

        this.getError = this.getError.bind(this);
    }

    getError() {
        const {t} = this.props;

        if (!this.props.errorMessage) {
            return;
        }

        return <div className="grid-container">
            <div className="grid-x">
                <div className="cell small-8 xlarge-7">
                    <div className="alert callout" id="error-alert">
                        <h5>Error</h5>
                        <p>{this.props.errorMessage}</p>
                    </div>
                </div>
                <div className="cell small-8 xlarge-7">
                    <h2>{t('Optionale Angaben')}</h2>
                </div>
            </div>
        </div>
    }

    render() {
        const {t} = this.props;

        return (
            <div className='projects-table-comp'>
                {this.getError()}
                <ConfigurationsTable
                    t={t}
                    data={this.props.project.configurations}
                    onRemoveConfiguration={this.props.onRemoveConfiguration}
                    onEditConfiguration={this.props.onEditConfiguration}
                    ability={this.props.ability}
                />
                <button
                    className='button button--objectinfos'
                    onClick={this.props.onProjectInfoClick}
                    disabled={
                        !this.props.ability.can('updateInformation', subject('Project', this.props.project.object))
                    }
                >{t('Projektangaben')}</button>
                <button
                    className='button button--addproject'
                    disabled={
                        !this.props.ability.can('update', subject('Project', this.props.project.object)) ||
                        !this.props.ability.can('create', subject('Configuration', {}))
                    }
                    onClick={this.props.onNewConfigurationClick}>{t('Schacht hinzufügen')}</button>
            </div>
        )
    }
}

Project.propTypes = {
    t: PropTypes.func,
    project: PropTypes.shape({
        object: PropTypes.object,
        configurations: PropTypes.object,
    }).isRequired,
    onProjectInfoClick: PropTypes.func.isRequired,
    onNewConfigurationClick: PropTypes.func.isRequired,
    onRemoveConfiguration: PropTypes.func.isRequired,
    onEditConfiguration: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    ability: PropTypes.shape({
        can: PropTypes.func
    })
};

export default Project;