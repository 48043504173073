import React         from 'react';
import PropTypes     from 'prop-types';
import translate     from '../translate';
import Modal         from 'sfp-react-modal';
import InputField    from '../input-field';
import fetchAndCheck from '../../lib/advanced-fetch';

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.closeModal = this.closeModal.bind(this);
        this.getError = this.getError.bind(this);
        this.checkError = this.checkError.bind(this);
        this.submitButtonDisabled = this.submitButtonDisabled.bind(this);
        this.updateInputValue = this.updateInputValue.bind(this);
        this.onLogin = this.onLogin.bind(this);

        this.state = {
            username: '',
            password: '',
            mailError: false,
            passwordError: false
        };
    }

    updateInputValue(event) {
        event.preventDefault();

        const data = {
            name: event.target.name,
            value: event.target.value
        };

        this.setState(prev => {
            prev[data.name] = data.value;

            return prev;
        });
    }

    submitButtonDisabled() {
        if (this.state.username.length === 0) {
            return true;
        }

        return this.state.password.length === 0;
    }

    onLogin(event) {
        event.preventDefault();

        fetchAndCheck(
            '/login',
            {
                method: 'POST',
                body: JSON.stringify({
                    username: this.state.username,
                    password: this.state.password
                }),
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        ).then(() => {
            this.closeModal();

            this.props.checkAuthorised();
            if (this.props.loginSuccessRedirect !== '') {
                this.props.history.push(this.props.loginSuccessRedirect);
            }
        }).catch(err => {
            if (err.error && err.error.status === 403) {
                this.setState({
                    mailError: true,
                    passwordError: true
                });
            } else {
                console.error(err);
            }
        });
    }

    checkError() {
        if (this.state.username.length === 0) {
            this.setState({mailError: true});
        } else {
            this.setState({mailError: false});
        }

        if (!this.state.password.length) {
            this.setState({passwordError: true});
        } else {
            this.setState({passwordError: false});
        }
    }

    getError() {
        if (this.state.mailError || this.state.passwordError) {
            const {t} = this.props;

            return <div className="cell full-width">
                <div className="email-error">
                    <p>{t('Bitte überprüfen Sie Ihre E-Mail Adresse und Passwort')}</p>
                </div>
            </div>
        }

        return null;
    }

    renderLoginBoxInstance() {
        const {t} = this.props;
        return <Modal
            id={'login-modal'}
            isOpen={this.props.isModalOpen}
            escClose={true}
            backgroundClickClose={false}
            customClasses={'login-modal'}
            closeHandler={() => this.closeModal(true)}>
            <div className="login-content">
                <div className="grid-container">
                    <span onClick={() => this.closeModal(true)} className="close-container">
                        <div className="close"/>
                    </span>
                    <div className="grid-x grid-margin-x">
                        <div className="cell full-width">
                            <h2>{t('Anmeldung')}</h2>
                        </div>
                        <div className="cell full-width">
                            <p>{t('Login')}</p>
                        </div>
                        {this.getError()}
                        <div className="cell small-5">
                            <InputField
                                t={t}
                                disabled={false}
                                name={'username'}
                                id={'username'}
                                type={'text'}
                                placeholder={t('Benutzername')}
                                change={this.updateInputValue}
                                error={this.state.mailError ? {} : null}
                            />
                        </div>
                        <div className="cell small-5">
                            <InputField
                                t={t}
                                disabled={false}
                                name={'password'}
                                id={'password'}
                                placeholder={t('Passwort')}
                                type={'password'}
                                change={this.updateInputValue}
                                error={this.state.passwordError ? {} : null}
                            />
                        </div>
                        <button
                            onClick={this.onLogin}
                            disabled={this.submitButtonDisabled()}
                            className="button button--login"
                        >
                            Login
                        </button>
                        <div className="cell small-5">
                            <p>{t('Noch nicht als Benutzer registriert? Hier kostenlos')} <a
                                className="login-link"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    this.closeModal();
                                    this.props.openRegistrationModal(e);
                                }}
                                href={'#'}>{t('registrieren')}</a>
                            </p>
                            <p>{t('Passwort vergessen? Hier')} <a
                                className="password-reset-link"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    this.closeModal();
                                    this.props.openPasswordRequestModal();
                                }}
                                href={'#'}>{t('zurücksetzen')}</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    }

    render() {
        return (
            <>
                {this.props.isModalOpen && this.renderLoginBoxInstance()}
            </>
        )
    }

    closeModal(redirectToRoot = false) {
        this.setState({
            username: '',
            password: '',
            mailError: false,
            passwordError: false
        },
        () => {
            this.props.closeModal(redirectToRoot);
        });
    }
}

Login.defaultProps = {
    loginSuccessRedirect: ''
};

Login.propTypes = {
    t: PropTypes.func.isRequired,
    history: PropTypes.any.isRequired,
    checkAuthorised: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    loginSuccessRedirect: PropTypes.string,
    openRegistrationModal: PropTypes.func,
    openPasswordResetModal: PropTypes.func,
    openPasswordRequestModal: PropTypes.func
};

export default translate(Login);
