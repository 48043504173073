'use strict';

import React     from 'react';
import PropTypes from 'prop-types';

import AngleView from '../index';
import imagesHelper from '../../../lib/images-helper';

const arrowWidth = 35;

class OutfallAngleView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            diameterAvg: 0,
            widthFactor: 0
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.diameterOptions !== this.props.diameterOptions) {
            this.setState(prev => {
                prev.diameterAvg = 0;
                if (this.props.diameterOptions.length) {
                    prev.diameterAvg = this.props.diameterOptions
                        .reduce((prev, curr) => prev + curr.value, 0) / this.props.diameterOptions.length;
                }

                prev.widthFactor = prev.diameterAvg / arrowWidth;

                return prev;
            });
        }
    }

    render() {
        return(
            <AngleView
                wrapperClass={''}
                outerCircleClass={'angle-view-circle-outfall'}
                innerCircleClass={'angle-view-circle-values-outfall'}
            >
                <div
                    className={
                        'angle-view-circle angle-view-circle-static angle-view-circle-static-outfall'
                    }
                    style={{
                        width: this.state.widthFactor
                            ? (this.props.outfallDiameter / this.state.widthFactor) + 'px'
                            : '0px',
                    }}
                >
                    <img
                        className={'angle-view-circle-static-outfall-arrow'}
                        src={imagesHelper['/static/assets/images/arrow.svg']}
                        alt={''}
                    />
                </div>
            </AngleView>
        );
    }
}

OutfallAngleView.propTypes = {
    diameterOptions: PropTypes.array,
    outfallDiameter: PropTypes.number
};

OutfallAngleView.defaultProps = {
    diameterOptions: []
};

export default OutfallAngleView;
