'use strict';

import React     from 'react';
import PropTypes from 'prop-types';

import AngleView from '../index';

class CoverEntranceAngleView extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <AngleView
                wrapperClass={'angle-view-wrapper-cover-entrance'}
                outerCircleClass={'angle-view-circle-cover-entrance'}
                innerCircleClass={'angle-view-circle-values-cover-entrance'}
            >
                {
                    this.props.selection !== 0 && this.props.selection !== '0' && this.props.selection !== '' &&
                    <div
                        className={`angle-view-dot entrance-at-${this.props.selection}`}
                    />
                }
            </AngleView>
        );
    }
}

CoverEntranceAngleView.propTypes = {
    selection: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};

CoverEntranceAngleView.defaultProps = {
    selection: 0
};

export default CoverEntranceAngleView;
