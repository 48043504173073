'use strict';

import React from 'react';
import PropTypes from 'prop-types'
import debounce from 'debounce';
import ButtonNavigation from './../button-navigation';
import InputRadioGroup from '../input-radio-group';
import InputAdvancedSelect from '../input-advanced-select';
import ImageContainer from '../image-container';
import imagesHelper from '../../lib/images-helper';

class FloorType extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showOptionalInput: false,
            optionalInputDisabled: true,
            productName: '',
            productId: '',
            // optionalChecked: false,
        };

        this.onChange = this.onChange.bind(this);
        this.onOptionalChange = this.onOptionalChange.bind(this);
        this.onProductChange = this.onProductChange.bind(this);
        this.getProduct = this.getProduct.bind(this);

        this.checkProduct = debounce(this.checkProduct.bind(this), props.inputDebounceTimeout);
    }

    UNSAFE_componentWillReceiveProps(nextProp) {

        if (nextProp.showProductTypeSelection) {

            const product = nextProp.products.find(product => product.productId === nextProp.productId);

            if (product) {
                this.setState(prev => {
                    prev.optionalInputDisabled= false;
                    prev.productName= product.name;
                    prev.showOptionalInput= true;
                    prev.productId= product.productId;
                    // prev.optionalChecked= nextProp.optionalChecked;

                    return prev;
                });
            }
        }

        this.setState({
            showOptionalInput: nextProp.activeId === parseInt(nextProp.showOptionalValue)
        });
    }

    render() {
        const {t} = this.props;

        const noProductLabel = 'Kein Artikel ausgewählt';

        // TODO: get the right err object in fieldsWithError.
        let optionalInput = (
            <div>
                <div className="checkbox">
                    <input type="checkbox"
                        id="optional"
                        checked={this.props.optionalChecked ? 'checked' : ''}
                        onChange={this.onOptionalChange}
                    />
                    <label
                        htmlFor="optional">{t('Ich kenne die Artikelnummer vom Boden')}</label>
                </div>

                { !this.state.optionalInputDisabled &&
                    <InputAdvancedSelect
                        suggestions={this.props.products}
                        noOptionsMessage={''}
                        pleaseChoose={t('Bitte wählen')}
                        options={[]}
                        t={t}
                        onChange={this.onProductChange}
                        value={this.state.productName === noProductLabel ? '' : this.state.productName}
                        error={this.props.fieldsWithError[0]}
                        name={'schachtbodenName'}
                        showSuggestionsOnFocus={true}
                    />
                }
            </div>
        );

        return (
            <div className="comp-floortype">
                <form>
                    <div className="grid-container">
                        <div className="grid-x">
                            <div className="cell small-6">
                                <h2>{t('Wählen Sie die Art des Schachtbodens')}</h2>
                                <InputRadioGroup
                                    t={t}
                                    options={this.props.options}
                                    value={this.props.activeId}
                                    name="floortype"
                                    onChange={this.onChange}
                                />

                                {
                                    this.state.showOptionalInput && this.props.showProductTypeSelection ?
                                        optionalInput : null
                                }
                            </div>

                            <div className="cell small-6">
                                <div className="cf right margin-top--4">
                                    <ImageContainer
                                        alt="Schachtbodens"
                                        title={t('Schachtbodens')}
                                        src={imagesHelper[this.props.rightImageSrc]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <div className="main-content-footer">
                    <ButtonNavigation
                        history={this.props.history}
                        enableNext={this.props.allowNext}
                        location={this.props.location}
                        eventHandler={this.props.eventHandler}/>
                </div>
            </div>
        )
    }

    onChange(value) {
        // Show optional input for 'Boden mit Gerinne'.
        if (value === this.props.showOptionalValue) {
            this.setState({
                showOptionalInput: this.props.products.length > 0,
                optionalInputDisabled: !this.props.optionalChecked
            }, () => {
                // Update allowNext prop
                this.props.update(value, this.props.productId, this.props.optionalChecked)
            });
        } else {
            this.setState({
                showOptionalInput: false,
                optionalInputDisabled: true,
            }, () => {
                this.props.update(value, this.props.productId, this.props.optionalChecked)
            });
        }
    }

    onOptionalChange() {
        const checked = !this.props.optionalChecked;

        this.setState({
            productId: !checked ? '' : this.state.productId,
            optionalInputDisabled: !checked,
            productName: checked && this.props.productId ? this.props.productName :
                checked ? this.props.t(this.props.noProductText) : '',
        }, () => {
            // Update allowNext prop
            this.props.update(this.props.showOptionalValue, !checked ? '' : this.state.productId, checked)
        });
    }

    onProductChange(e) {
        this.checkProduct(e.item);
    }

    getProduct(value, products) {
        products = this.props.products.length > 0 ? this.props.products : products;

        if (!products || !value) return;

        for (let i = products.length; i--;) {
            let productName = products[value].name ? products[value].name : '';
            let productId = products[value].productId;

            return {
                productName,
                productId
            };
        }


        return;
    }

    checkProduct(value) {
        //let product = this.getProduct(value);
        let product = this.props.products[value.id];

        if(product){
            this.setState({
                productName: product.productName,
                productId: product.id
            }, () => {
                this.props.update(
                    this.props.showOptionalValue,
                    parseInt(product.productId),
                    !this.state.optionalInputDisabled)
            });

            return;
        }
        this.setState({
            productId: null,
            productName: this.props.t(this.props.noProductText)
        }, () => {
            this.props.update(this.props.showOptionalValue, 0, !this.state.optionalInputDisabled)
        });
    }
}

FloorType.propTypes = {
    history: PropTypes.any,
    location: PropTypes.any,
    t: PropTypes.any,
    activeId: PropTypes.number,
    allowNext: PropTypes.any,
    eventHandler: PropTypes.object,
    inputDebounceTimeout: PropTypes.number,
    noProductText: PropTypes.string,
    options: PropTypes.array,
    products: PropTypes.any,
    showOptionalValue: PropTypes.string,
    update: PropTypes.func,
    fieldsWithError: PropTypes.array,
    rightImageSrc: PropTypes.string,
    productId: PropTypes.any,
    onProductChange: PropTypes.func,
    optionalChecked: PropTypes.bool,
    productName: PropTypes.string,
    showProductTypeSelection: PropTypes.bool
};

FloorType.defaultProps = {
    showOptionalValue: '3',  // This is the 'Boden mir Gerinne' option.
    inputDebounceTimeout: 50,
    noProductText: 'Kein Artikel ausgewählt'
};

export default FloorType;

