'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'sfp-react-modal';
import InputField from '../input-field'
import dbField from '../../lib/db-field';

class Index extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            saveButtonDisabled: false,
            projectName: '',
            duplicateProjectName: false
        };

        this.updateProjectName = this.updateProjectName.bind(this);
        this.checkDuplicateProjectName = this.checkDuplicateProjectName.bind(this);
        this.getDuplicateNameError = this.getDuplicateNameError.bind(this);
    }

    updateProjectName(event) {
        event.preventDefault();

        this.setState({projectName: event.target.value});
    }

    checkDuplicateProjectName() {
        let str = JSON.stringify(this.state.projectName.trim()),
            index = this.props.projects.findIndex(item => {
                return str === JSON.stringify(item[dbField.project.PROJECT_NAME].trim());
            });

        if(index !== -1) {
            this.setState({duplicateProjectName: true});
        } else if (index === -1) {
            this.setState({duplicateProjectName: false});

            let cb = null;
            if (this.props.saveConfiguration) {
                cb = this.props.saveConfiguration;
            }
            this.props.setNewProjectName(this.state.projectName, cb)
                .catch(err => {
                    if (err.message !== 'done') {
                        console.error(err);

                        this.props.history.push('/error');
                    }
                });
        }
    }

    getDuplicateNameError() {
        if (this.state.duplicateProjectName) {
            const {t} = this.props;

            return <div className="cell full-width">
                <div className="project-name-error">
                    <p>{t('Diese Projektbezeichnung ist bereits vorhanden')}</p>
                </div>
            </div>
        }

        return null;
    }

    renderNewProjectBoxInstance() {
        const {t} = this.props;

        return <div>
            <span className='modal-box'/>
            <div className={'modal-box-content'}>
                <Modal
                    id={'schacht-modal'}
                    isOpen={this.props.showModal}
                    escClose={true}
                    backgroundClickClose={false}
                    customClasses={'project-list-modal--hide'}
                    closeHandler={this.props.closeModalNewProject}>
                    <div className='schacht-config-content'>
                        <div className="grid-container">
                            <span onClick={this.props.closeModalNewProject} className='close-container'>
                                <div className='close'/>
                            </span>
                            <div className="schacht-modal">
                            </div>
                        </div>
                    </div>
                    <div className="grid-x grid-margin-x">
                        <div className="cell full-width">
                            <h2>{t('Bitte geben Sie eine übergeordnete Projektbezeichnung ein:')}</h2>
                        </div>
                        <div className="cell small-5">
                            {this.getDuplicateNameError()}
                            <InputField
                                t={t}
                                disabled={false}
                                name={'projekttName'}
                                placeholder={t('z.B. Baustelle Manesstrasse')}
                                tabIndex="0"
                                type="text"
                                change={this.updateProjectName}
                            />
                        </div>
                        <div className="cell full-width">
                            <button
                                onClick={this.checkDuplicateProjectName}
                                disabled={this.state.projectName.length < 4}
                                className="button button--save-schacht-config">{t('Projekt anlegen')}
                            </button>
                        </div>
                        <div className="cell small-5">
                        </div>
                    </div>
                </Modal>
            </div>
        </div>;
    }

    render() {
        return (
            <div className="modal-box-cont">
                {this.renderNewProjectBoxInstance()}
            </div>
        )
    }
}

Index.propTypes = {
    t: PropTypes.func,
    showModal: PropTypes.bool,
    closeModalNewProject: PropTypes.func,
    saveConfiguration: PropTypes.func,
    projects: PropTypes.arrayOf(PropTypes.shape({
        [dbField.project.PROJECT_NAME]: PropTypes.string,
        [dbField.project.STATUS]: PropTypes.number,
        [dbField.project.MODIFIED_AT]: PropTypes.string,
    })),
    setNewProjectName: PropTypes.func,
    history: PropTypes.any,
    i18n: PropTypes.any,
};

export default (Index);
