import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'sfp-react-modal';

class LegendModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            orderStates: [
                'Konfiguriert',
                'Verändert',
                'Offeriert',
                'Bestellt'
            ]
        };

        this.renderLegend = this.renderLegend.bind(this);
    }

    render() {
        const {t} = this.props;

        return <Modal
            id={`legend-modal-${this.props.listId}`}
            isOpen={this.props.opened}
            escClose={true}
            backgroundClickClose={false}
            customClasses={'legend-modal'}
            closeHandler={this.props.closeHandler}>
            <div className="grid-container">
                <span onClick={this.props.closeHandler} className="close-container">
                    <div className="close"/>
                </span>
                <div className="grid-x grid-margin-x">
                    <div className="legend-container">
                        <div
                            className="cell full-width title">
                            {t('Status Legende')}
                        </div>
                        {this.renderLegend()}
                    </div>
                </div>
            </div>
        </Modal>
    }

    renderLegend() {
        const {t} = this.props;

        return <ol>
            {
                this.state.orderStates.map((state, index) =>  {
                    return (
                        <li key={index}
                            className="cell full-width">
                            {t(state)}
                        </li>
                    )
                })
            }
        </ol>;
    }
}

LegendModal.defaultProps = {
    listId: '1'
};

LegendModal.propTypes = {
    t: PropTypes.func,
    opened: PropTypes.bool,
    closeHandler: PropTypes.func,
    listId: PropTypes.string
};

export default LegendModal;