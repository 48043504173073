'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import translate from '../translate';
import Modal from 'sfp-react-modal';

class EmailVerify extends React.Component {
    constructor(props) {
        super(props);

        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        this.props.toggleProgressBar(this.props.showProgressBar);
        this.props.toggleSideProtocol(this.props.showSideProtocol);
    }

    getSuccessMessage(t) {
        return <div className="cell full-width">
            <h2>
                {
                    t('Vielen Dank für die Validierung Ihrer E-Mail Adresse')
                }
            </h2>
        </div>;
    }

    getErrorMessage(t) {
        return <div className="cell full-width">
            <h2>
                {
                    t('Dieser Link ist nicht mehr gültig')
                }
            </h2>
        </div>;
    }

    render() {
        const {status} = this.props.match.params;
        const {t} = this.props;

        return <div>
            <Modal
                id={'verify-email'}
                isOpen={true}
                escCloase={true}
                backgroundClickClose={false}
                closeHandler={this.closeModal}
            >
                <div className="grid-container">
                    <span onClick={this.closeModal} className="close-container">
                        <div className="close"/>
                    </span>
                    <div className="grid-x grid-margin-x">
                        {
                            status === 'success' ?
                                this.getSuccessMessage(t) :
                                this.getErrorMessage(t)
                        }
                        <div className="cell full-width">
                            <button
                                onClick={this.closeModal}
                                className="button button--red">{t('Hier geht es Weiter')}
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>;
    }

    closeModal() {
        this.props.history.push('/dashboard');
    }
}

EmailVerify.propTypes = {
    t: PropTypes.func.isRequired,
    history: PropTypes.any.isRequired,
    match: PropTypes.object.isRequired,
    showSideProtocol: PropTypes.bool,
    showProgressBar: PropTypes.bool,
    toggleProgressBar: PropTypes.func,
    toggleSideProtocol: PropTypes.func
};

export default translate(EmailVerify);