'use strict';

import React from 'react';
import PropTypes from 'prop-types';

class InputSelect extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {t} = this.props;

        let inputClasses = 'dropdown',
            requiredOptionalClass = 'input--optional',
            labelClass = 'label--content';

        if (!this.props.optional) {
            requiredOptionalClass = 'input--required';
            labelClass += this.props.showRequiredSign ? '' : ' hide-sign';
        }

        let labelOutput = '';
        if (this.props.label !== '' && this.props.label !== undefined) {
            labelOutput = (
                <span className={labelClass}>{t(this.props.label)}</span>
            );
        }

        if (this.props.disabled) {
            inputClasses += ' disabled';
        }

        if (this.props.error) {
            inputClasses += ' error';
        }

        return (
            <label
                className={
                    `${inputClasses} ${requiredOptionalClass}`
                }
            >
                {labelOutput}
                <span className="dropdown__control">
                    <select id={this.props.id}
                        name={this.props.name}
                        onChange={this.props.onChange}
                        disabled={this.props.disabled}
                        value={this.props.value || ''}
                    >
                        <option value="0">{t('Bitte wählen...')}</option>
                        {this.props.options && this.props.options.map((option) => {
                            return (<option key={option.key}
                                value={option.key}
                                disabled={option.disabled ? 'disabled' : undefined}
                            >{option.label}</option>)
                        })}
                    </select>
                    <span className="input__error-bottom">{this.props.error ? this.props.error.message : ''}</span>
                </span>
            </label>
        )
    }
}

InputSelect.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string
    ]),
    options: PropTypes.array,
    error: PropTypes.object,
    t: PropTypes.func,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    optional: PropTypes.bool,
    showRequiredSign: PropTypes.bool
};

InputSelect.defaultProps = {
    disabled: false,
    optional: true,
    showRequiredSign: true
};


export default InputSelect;