'use strict';

import fetchAndCheck from '../../lib/advanced-fetch';
import cfg           from '../../conf';

export default function () {
    let apiUrl = `${cfg.api.userSingleApiUrlTemplate('de')}`;
    return {
        type: 'check-user-authorised',
        payload: fetchAndCheck(apiUrl, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
    };
}