'use strict';

import React     from 'react';
import PropTypes from 'prop-types';

import Autosuggest from 'react-autosuggest';

/**
 *
 */
class InputAdvancedSelect extends React.Component {

    /**
     *
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            helpIsHidden: true,
            fieldIsHidden: this.props.value === -1,
            search: '',
            suggestions: []
        };

        this.toggleInfoBlock = this.toggleInfoBlock.bind(this);
        this.toggleOptionalBlock = this.toggleOptionalBlock.bind(this);
        this.hideOptionalBlock = this.hideOptionalBlock.bind(this);
        this.deleteFieldAndHideOptionalBlock = this.deleteFieldAndHideOptionalBlock.bind(this);
        this.onInputChange = this.onInputChange.bind(this);

        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);

        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    }

    componentDidMount() {
        if (this.props.value) {
            const search =
                this.props.suggestions[this.props.value] ?
                    this.props.suggestions[this.props.value].name : this.props.value;
            this.setState({search})
        }
    }


    /**
     *
     * @param prevProps
     */
    componentDidUpdate(prevProps) {
        if(prevProps.value !== this.props.value) {
            this.setState({search: this.props.value});
        }

        if (prevProps.value !== this.props.value && this.props.value === -1) {
            this.setState(prev => {
                prev.search = '';
                prev.suggestions = [];

                return prev;
            })
        }
    }


    /**
     *
     * @returns {*}
     */
    render() {
        const {t} = this.props;

        let hasError = this.props.error ? this.props.error[this.props.name] : false,
            inputClasses = 'form-field',
            error;

        if (hasError) {
            error = this.props.t(this.props.error[this.props.name]);
        }

        if (hasError) {
            inputClasses += ' ' + 'error';
        }

        if (this.props.disabled) {
            inputClasses += ' ' + 'disabled';
        }

        let iconOffset = '';
        if (this.props.infoText) {
            iconOffset = 'iconOffset';
        }

        let renderOptionalFormLink = '';
        let renderLabel = '';
        let renderOptionalCloseButton = '';
        let optionalFieldClass = this.state.fieldIsHidden ? 'optional-form-field hidden' : 'optional-form-field',
            optionalLabelClass = this.state.fieldIsHidden ? '' : 'label__icon-hidden';

        if (this.props.optional) {
            renderOptionalFormLink = (
                <div
                    className={`optional-form-link label-container ${optionalLabelClass}`}
                    onClick={this.toggleOptionalBlock}>
                    <h3>{this.props.label}</h3>
                    <span className={'opt-flag ' + iconOffset}>
                        {t('Optional')}
                    </span>
                </div>
            );
            renderOptionalCloseButton = (
                <span className={'close-optional'} onClick={this.deleteFieldAndHideOptionalBlock}>
                    <a href="javascript: void(0)">{t('Delete field')}</a>
                </span>
            );

        } else {
            optionalFieldClass = 'optional-form-field';
            renderLabel = (
                <div className={'label-container'} data-equalize>
                    <label className={'label ellipsis'} htmlFor={this.props.id}>{this.props.label}</label>
                </div>
            );
        }

        const inputProps = {
            placeholder: this.props.pleaseChoose,
            value: this.state.search.toString(),
            onChange: this.onInputChange,
            disabled: this.props.disabled
        };

        const getSuggestionValue = suggestion => {
            if (this.props.suggestionValueKey && suggestion[this.props.suggestionValueKey]) {
                return suggestion[this.props.suggestionValueKey];
            }

            return suggestion.name;
        }

        const renderSuggestion = suggestion => {
            return <div className={'react-select__option'}>{getSuggestionValue(suggestion)}</div>;
        };

        return (
            <div className={`comp-input-advanced-select ${inputClasses}`}>

                {renderOptionalFormLink}

                <div className={optionalFieldClass}>

                    {renderLabel}

                    <span className={'advanced-select-wrapper'}>
                        <Autosuggest
                            suggestions={this.state.suggestions}
                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                            getSuggestionValue={getSuggestionValue}
                            renderSuggestion={renderSuggestion}
                            inputProps={inputProps}
                            onSuggestionSelected={this.onSuggestionSelected}
                            shouldRenderSuggestions={() => true}
                        />
                        <span className={'error-message'}>{hasError ? error : ''}</span>
                    </span>

                    {renderOptionalCloseButton}

                </div>
            </div>
        );
    }


    /**
     *
     * @param e
     */
    toggleInfoBlock(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState(function (prevState) {
            return {helpIsHidden: !prevState.helpIsHidden};
        });
    }


    /**
     *
     * @param e
     */
    toggleOptionalBlock(e) {
        e.preventDefault();
        this.setState(function (prevState) {
            return {fieldIsHidden: !prevState.fieldIsHidden};
        });
    }


    /**
     *
     * @param e
     */
    hideOptionalBlock(e) {
        e.preventDefault();
        this.setState(function (prevState) {
            return {fieldIsHidden: !prevState.fieldIsHidden};
        });
    }


    /**
     *
     * @param e
     */
    deleteFieldAndHideOptionalBlock(e) {
        this.props.onChange(e, this.props.name, -1);
        this.hideOptionalBlock(e);
    }


    /**
     *
     * @param event
     * @param newValue
     */
    onInputChange(event, {newValue}) {
        event.preventDefault();
        this.setState((prev) => {
            prev.search = newValue;

            return prev;
        });
    }

    getSuggestions(options) {
        const inputValue = options.value.toString().trim().toLowerCase();
        const inputLength = inputValue.length;

        if (inputLength === 0 && this.props.showSuggestionsOnFocus) {
            if (options.reason && options.reason === 'input-focused') {
                return Object.entries(this.props.suggestions).map(([key , suggestion]) => {
                    return {name: suggestion.name, id: key};
                }).filter(Boolean)
            }
        }

        return Object.entries(this.props.suggestions).map(([key , suggestion]) => {
            return suggestion[this.props.keyToSearchFor].toLowerCase().indexOf(inputValue) >= 0 ?
                {name: suggestion.name, id: key} :
                false;
        }).filter(Boolean)
    }


    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested(options) {
        this.setState(prev => {
            prev.suggestions = this.getSuggestions(options);

            return prev;
        });
    }


    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested() {
        this.setState({
            suggestions: []
        });
    }


    onSuggestionSelected(event, {suggestion}) {
        this.props.onChange({
            item: {
                id: suggestion.id
            }
        });
    }
}

InputAdvancedSelect.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    options: PropTypes.array.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    pleaseChoose: PropTypes.string.isRequired,
    enablePleaseChoose: PropTypes.bool,
    t: PropTypes.func.isRequired,
    error: PropTypes.object,
    optional: PropTypes.bool,
    infoText: PropTypes.string,
    sortByProperty: PropTypes.string,
    noOptionsMessage: PropTypes.string,
    showSuggestionsOnFocus: PropTypes.bool,
    suggestionValueKey: PropTypes.string,
    keyToSearchFor: PropTypes.string,

    suggestions: PropTypes.any
};

InputAdvancedSelect.defaultProps = {
    disabled: false,
    value: '',
    optional: false,
    infoText: null,
    sortByProperty: 'name',
    suggestionValueKey: 'name',
    keyToSearchFor: 'name'
};

export default InputAdvancedSelect;
