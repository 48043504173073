import React from 'react';
import PropTypes from 'prop-types';
import ProtocolModal from './protocol-modal';
import LegendModal from './legend-modal';
import Modal from 'sfp-react-modal';
import {subject} from '@casl/ability';

class ConfigurationsTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            protocolModalIsOpened: {},
            legendModalIsOpen: false,
            deleteConfirmationModalOpen: false,
            configurationIdToRemove: null,
            status: null
        };

        this.showProtocol = this.showProtocol.bind(this);
        this.showLegend = this.showLegend.bind(this);
        this.closeProtocolModal = this.closeProtocolModal.bind(this);
        this.closeLegendModal = this.closeLegendModal.bind(this);
        this.openDeleteConfirmationModal = this.openDeleteConfirmationModal.bind(this);
        this.closeDeleteConfirmationModal = this.closeDeleteConfirmationModal.bind(this);
        this.onRemoveConfiguration = this.onRemoveConfiguration.bind(this);
    }

    render() {
        const {t, data} = this.props;

        return (
            <div>
                <Modal
                    id={'are-you-sure-modal'}
                    isOpen={this.state.deleteConfirmationModalOpen}
                    escClose={true}
                    backgroundClickClose={false}
                    customClasses={'are-you-sure-modal'}
                    closeHandler={this.closeDeleteConfirmationModal}
                >
                    <div className="grid-containe are-you-sure-modal-content">
                        <span onClick={this.closeDeleteConfirmationModal} className='close-container'>
                            <div className='close'/>
                        </span>
                        <div className='grid-x grid-margin-x'>
                            <div className='cell full-width'>
                                <h2>{t('Sind Sie sicher?')}</h2>
                            </div>
                            <div className='cell full-width'>
                                <p>{t('Ihre Daten werden unwiderruflich gelöscht.')}</p>
                            </div>
                            <button
                                onClick={this.closeDeleteConfirmationModal}
                                className='button cancel-button'>{t('Cancel')}
                            </button>
                            <button
                                onClick={() => {
                                    this.onRemoveConfiguration();
                                    this.closeDeleteConfirmationModal();
                                }}
                                className='button delete-button'>{t('Löschen')}
                            </button>
                        </div>
                    </div>
                </Modal>
                <table>
                    <thead>
                        <tr>
                            <th className='schacht'>{t('Schacht')}</th>
                            <th>{t('Preis')}</th>
                            <th>{t('Gewicht')}</th>
                            <th>{t('Letzte Änderung')}</th>
                            <th className="header-status"
                                onClick={this.showLegend}>{t('Status')}</th>
                            <th className="header-edit">{t('Öffnen')}</th>
                            <th className="header-delete">{t('Löschen')}</th>
                        </tr>
                    </thead>
                    <tbody className="projects-border">
                        {
                            Object.keys(data).length === 0 &&
                                <tr>
                                    <td colSpan="7" className="empty-config">
                                        {t('Es wurden keine Konfigurationen gefunden.')}
                                    </td>
                                </tr>
                        }
                        {
                            Object.entries(data).map(([id, {config: item}]) => {

                                let disabledEditClass = '';
                                let disabledDeleteClass = '';
                                if (!this.props.ability.can('delete', subject('Configuration', item))) {
                                    disabledDeleteClass = 'disabled';
                                }
                                if (!this.props.ability.can('update', subject('Configuration', item))) {
                                    disabledEditClass = 'disabled';
                                }

                                return (
                                    <tr className="projects" key={id}>
                                        <td onClick={() => this.showProtocol(id)} className="item-name">
                                            <span className="item-name-text">{item.name}</span>
                                        </td>
                                        <td className="item-price">{item.price}</td>
                                        <td className="item-weight">{item.weight}</td>
                                        <td className="item-lastchange">{item.lastchange}</td>
                                        <td className="item-status">{item.offerStatus}</td>
                                        <td className={`edit-project ${disabledEditClass}`}
                                            onClick={() => this.onEditConfiguration(id, item)}/>
                                        <td className={`delete-project ${disabledDeleteClass}`}
                                            onClick={() => this.openDeleteConfirmationModal(id, item)}/>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

                {Object.entries(data).map(([id, {sessionData}]) => (
                    <ProtocolModal
                        key={id}
                        listId={id}
                        data={sessionData}
                        closeHandler={this.closeProtocolModal}
                        opened={this.state.protocolModalIsOpened[id] || false}
                        t={t}/>
                ))}
                <LegendModal
                    t={t}
                    opened={this.state.legendModalIsOpen}
                    closeHandler={this.closeLegendModal}
                />
            </div>
        )
    }

    showProtocol(id) {
        this.setState({protocolModalIsOpened: {[id]: true}});
    }

    showLegend() {
        this.setState({legendModalIsOpen: true});
    }

    closeProtocolModal(id) {
        this.setState({protocolModalIsOpened: {[id]: false}})
    }

    closeLegendModal() {
        this.setState({legendModalIsOpen: false});
    }

    openDeleteConfirmationModal(id, configuration) {
        if (this.props.ability.can('delete', subject('Configuration', configuration))) {
            this.setState({
                deleteConfirmationModalOpen: true,
                configurationIdToRemove: id
            });
        }
    }

    onEditConfiguration(id, configuration) {
        if (this.props.ability.can('update', subject('Configuration', configuration))) {
            this.props.onEditConfiguration(parseInt(id));
        }
    }

    closeDeleteConfirmationModal() {
        this.setState(prev => {
            prev.deleteConfirmationModalOpen = false;
            prev.configurationIdToRemove = null;

            return prev;
        });
    }

    onRemoveConfiguration() {
        const configurationId = this.state.configurationIdToRemove;
        this.setState(prev => {
            prev.configurationIdToRemove = null;

            return prev;
        });

        return this.props.onRemoveConfiguration(configurationId);
    }
}

ConfigurationsTable.propTypes = {
    t: PropTypes.func,
    data: PropTypes.object.isRequired,
    onRemoveConfiguration: PropTypes.func.isRequired,
    onEditConfiguration: PropTypes.func.isRequired,
    ability: PropTypes.shape({
        can: PropTypes.func
    })
};

export default ConfigurationsTable;