'use strict';

import React     from 'react';
import PropTypes from 'prop-types';
import imagesHelper from '../../lib/images-helper';

class AngleView extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className={`angle-view-wrapper ${this.props.wrapperClass}`}>
                <img
                    className={`angle-view-circle ${this.props.outerCircleClass}`}
                    src={imagesHelper['/static/assets/images/circle.svg']}
                    alt={''}
                />
                <img
                    className={`angle-view-circle angle-view-circle-values ${this.props.innerCircleClass}`}
                    src={imagesHelper['/static/assets/images/circle-with-angle-values.svg']}
                    alt={''}
                />

                {this.props.children}

            </div>
        );
    }
}

AngleView.propTypes = {
    wrapperClass: PropTypes.string,
    outerCircleClass: PropTypes.string,
    innerCircleClass: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};

AngleView.defaultProps = {
    wrapperClass: '',
    outerCircleClass: '',
    innerCircleClass: ''
};

export default AngleView;
