'use strict';

import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';
import backend from 'i18next-http-backend';
import detector from 'i18next-browser-languagedetector';

import i18nextConf from './conf/conf';

i18next
    .use(backend)
    .use(detector)
    .use(initReactI18next)
    .init(
        i18nextConf.i18n
    );

export default i18next;
