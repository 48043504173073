'use strict';

import merge from 'lodash.merge';
import {Ability} from '@casl/ability';

const {createReducer} = require('./../../lib/reducer');

const initialState = {
    userAuthorised: false,
    checkUserAuthorised: false,
    ability: {}
};

export default createReducer(initialState, {
    'check-user-authorised_PENDING': state => {
        return merge({}, state, {checkUserAuthorised: false});
    },

    'check-user-authorised_FULFILLED': (state, action) => {
        const {rules = {}} = action.payload;
        return merge(
            {},
            state,
            {
                userAuthorised: true,
                checkUserAuthorised: true,
                ability: new Ability(rules)
            }
        );
    },

    'check-user-authorised_REJECTED': state => {
        return merge(
            {},
            state,
            {
                userAuthorised: false,
                checkUserAuthorised: true,
                ability: {}
            }
        );
    }
});
