import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'sfp-react-modal';
import moment from 'moment';
import {connect} from 'react-redux';
import merge from 'lodash.merge';
import {subject} from '@casl/ability';

import translate from '../translate';
import dbField from '../../lib/db-field';
import LegendModal from '../project/lib/legend-modal';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            projectIdToDelete: null,
            legendModalIsOpen: false,
            areYouSureModalOpen: false
        };

        this.renderTable = this.renderTable.bind(this);
        this.onButtonDeleteClick = this.onButtonDeleteClick.bind(this);
        this.onModalButtonDeleteClick = this.onModalButtonDeleteClick.bind(this);
        this.closeAreYouSureModal = this.closeAreYouSureModal.bind(this);
        this.openAreYouSureModal = this.openAreYouSureModal.bind(this);
        this.loadProject = this.loadProject.bind(this);
        this.showLegend = this.showLegend.bind(this);
        this.closeLegendModal = this.closeLegendModal.bind(this);
    }

    loadProject(id) {
        return this.props.history.push(`/project/${id}`);
    }

    renderTable() {
        const {t} = this.props;

        return (
            <table>
                {this.props.projects.length !== 0 &&
                <thead>
                    <tr>
                        <th width="420">{t('Name')}</th>
                        <th className="last-change" width="189">{t('Letzte Änderung')}
                            <div className="arrow-down"/>
                        </th>
                        <th className="header-status"
                            onClick={this.showLegend}>{t('Status')}</th>
                        <th className="header-edit">{t('Öffnen')}</th>
                        <th className="header-delete">{t('Löschen')}</th>
                    </tr>
                </thead>
                }
                <tbody>
                    {this.props.projects.map((project, index)  => {
                        let name = project[dbField.project.PROJECT_NAME];
                        let status = project[dbField.project.STATUS];
                        let dateModified = moment(project[dbField.project.MODIFIED_AT]).format('DD.MM.YYYY');
                        let disabledClass = '';
                        if (!this.props.ability.can('delete', subject('Project', project))) {
                            disabledClass = 'disabled';
                        }

                        return <tr className="projects" key={index}>
                            <td className="item-name" width="455"
                                onClick={() => this.loadProject(project[dbField.project.ID], status)}>{name}</td>
                            <td className="item-lastchange" width="215"
                                onClick={() => this.loadProject(project[dbField.project.ID],
                                    status)}>{dateModified}</td>
                            <td className="item-status"
                                onClick={() => this.loadProject(project[dbField.project.ID], status)}>{status}</td>
                            <td className={'edit-project'}
                                width="80"
                                onClick={() => this.loadProject(project[dbField.project.ID], status)}>
                            </td>
                            <td className={`delete-project ${disabledClass}`} width="50"
                                onClick={() => this.onButtonDeleteClick(project)}>

                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
        )
    }

    render() {
        const {t} = this.props;

        return (
            <div className='dashboard-view-comp'>
                <Modal
                    id={'are-you-sure-modal'}
                    isOpen={this.state.areYouSureModalOpen}
                    escClose={true}
                    backgroundClickClose={false}
                    customClasses={'are-you-sure-modal'}
                    closeHandler={this.closeAreYouSureModal}
                >
                    <div className="grid-container are-you-sure-modal-content">
                        <span onClick={this.closeAreYouSureModal} className='close-container'>
                            <div className='close'/>
                        </span>
                        <div className='grid-x grid-margin-x'>
                            <div className='cell full-width'>
                                <h2>{t('Sind Sie sicher?')}</h2>
                            </div>
                            <div className='cell full-width'>
                                <p>{t('Ihre Daten werden unwiderruflich gelöscht.')}</p>
                            </div>
                            <button onClick={this.closeAreYouSureModal}
                                className='button cancel-button'>{t('Cancel')}
                            </button>
                            <button onClick={this.onModalButtonDeleteClick}
                                className='button delete-button'>{t('Löschen')}
                            </button>
                        </div>
                    </div>
                </Modal>
                <div className="projects-border">
                    {this.renderTable()}
                </div>
                <button
                    onClick={this.props.onCreateProject}
                    className='button button--newproject'>{t('Neues Projekt')}
                </button>
                <LegendModal
                    t={t}
                    opened={this.state.legendModalIsOpen}
                    closeHandler={this.closeLegendModal}
                />
            </div>
        )
    }

    onButtonDeleteClick(project){
        if (project && !this.props.ability.can('delete', subject('Project', project))) {
            return;
        }

        this.openAreYouSureModal();
        this.setState({projectIdToDelete: project[dbField.project.ID]})
    }

    onModalButtonDeleteClick(){
        this.closeAreYouSureModal();
        this.props.onDelete(this.state.projectIdToDelete);
    }

    openAreYouSureModal(){
        this.setState({areYouSureModalOpen: true});
    }

    closeAreYouSureModal(){
        this.setState({
            areYouSureModalOpen: false,
            projectIdToDelete: null
        });
    }

    showLegend() {
        this.setState({legendModalIsOpen: true});
    }

    closeLegendModal() {
        this.setState({legendModalIsOpen: false});
    }
}

Dashboard.propTypes = {
    t: PropTypes.func.isRequired,
    history: PropTypes.any.isRequired,
    projects: PropTypes.arrayOf(PropTypes.shape({
        [dbField.project.PROJECT_NAME]: PropTypes.string,
        [dbField.project.STATUS]: PropTypes.number,
        [dbField.project.MODIFIED_AT]: PropTypes.string,
    })).isRequired,
    onDelete: PropTypes.func.isRequired,
    onCreateProject: PropTypes.func.isRequired,
    ability: PropTypes.shape({
        can: PropTypes.func
    })
};

const mapStateToProps = state => {
    return merge({}, state.shared);
};

export default connect(mapStateToProps)(translate(Dashboard));
