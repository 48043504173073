'use strict';

import React       from 'react';
import PropTypes   from 'prop-types';
import translate   from '../translate';
import Modal       from 'sfp-react-modal';
import InputField  from '../input-field'
import * as Action from './actions';
import merge       from 'lodash.merge';
import {connect}   from 'react-redux';

class PasswordReset extends React.Component {
    constructor(props) {
        super(props);

        this.closeModalConfirm = this.closeModalConfirm.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.updateInputValue = this.updateInputValue.bind(this);
        this.showConfirmModal = this.showConfirmModal.bind(this);
        this.checkEmptyPassword = this.checkEmptyPassword.bind(this);
        this.buttonSaveDisabled = this.buttonSaveDisabled.bind(this);
        this.submitPasswordReset = this.submitPasswordReset.bind(this);

        this.state = {
            modalConfirmIsOpened: false,
            isPasswordResetModalOpen: false,
            password: '',
            save: true,
            passwordError: false,
            loading: false,
            error_msg: ''
        };
    }

    updateInputValue(event) {
        event.preventDefault();
        const data = {
            name: event.target.name,
            value: event.target.value
        };

        this.setState(prev => {
            prev[data.name] = data.value;

            return prev;
        },
        );
    }

    buttonSaveDisabled() {
        return this.state.password.length === 0;
    }

    checkEmptyPassword() {
        if (this.state.password.length !== 0) {
            this.submitPasswordReset();
        } else {
            this.setState({passwordError: true});
        }
    }

    getError() {
        const {t} = this.props;

        if (this.state.passwordError) {
            return <div className="cell full-width">
                <div className="email-error">
                    <p>{t('Bitte überprüfen Sie Ihr Passwort')}</p>
                </div>
            </div>
        } else {
            return null;
        }
    }

    renderPasswordResetBoxInstance() {
        const {t} = this.props;

        return <Modal
            id={'check-email-modal'}
            isOpen={this.state.isPasswordResetModalOpen}
            escClose={true}
            backgroundClickClose={false}
            customClasses={'password-reset-box-modal'}
            closeHandler={() => this.closeModal(true)}>

            <div className='password-reset-content'>
                <div className="grid-container">
                    <span onClick={() => this.closeModal(true)} className="close-container">
                        <div className="close"/>
                    </span>

                    <div className="password-reset">
                        <div className="grid-x grid-margin-x">

                            <div className="cell full-width">
                                <h2>{t('Neues Passwort setzen')}</h2>

                                {this.state.error_msg.length !== 0 &&
                                        <div className='email-error '>
                                            <p>{t(this.state.error_msg)}</p>
                                        </div>
                                }
                            </div>

                            <div className="cell small-5">
                                <div className="cell">
                                    <p>{t('Passwort')}</p>
                                </div>

                                <InputField
                                    t={t}
                                    disabled={false}
                                    placeholder={t('Mind. 8 Zeichen')}
                                    change={this.updateInputValue}
                                    type={'password'}
                                    name={'password'}
                                    error={
                                        this.props.changePassword.invalidFields.filter(error => {
                                            return error.fieldName === 'password';
                                        })[0]
                                    }
                                    errorLayout={'bottom'}
                                />

                                {this.state.password.length > 0 &&
                                        <InputField
                                            t={t}
                                            disabled={false}
                                            placeholder={t('Bitte Passwort wiederholen!')}
                                            type={'password'}
                                            change={this.updateInputValue}
                                            name={'password_repeat'}
                                            error={
                                                this.props.changePassword.invalidFields.filter(error => {
                                                    return error.fieldName === 'password_repeat';
                                                })[0]
                                            }
                                            errorLayout={'bottom'}
                                        />
                                }
                                <button onClick={this.checkEmptyPassword} disabled={this.buttonSaveDisabled()}
                                    className="button button--password-reset">
                                    {t('Passwort zurücksetzen')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    }

    renderPasswordResetConfirmBoxInstance() {
        const {t} = this.props;

        return <Modal
            id={'register-modal'}
            isOpen={this.state.modalConfirmIsOpened}
            escClose={true}
            backgroundClickClose={false}
            customClasses={'register-modal'}
            closeHandler={this.closeModalConfirm}>
            <div className="grid-container">
                <span onClick={this.closeModalConfirm} className="close-container">
                    <div className="close"/>
                </span>
                <div className="grid-x grid-margin-x">
                    <div className="cell full-width">
                        <h2>{t('Vielen Dank!')}</h2>
                    </div>
                    <div
                        className="cell full-width">
                        {t('Ihr Passwort wurde erfolgreich zurückgesetzt.')}
                    </div>
                    <br/>
                    <div
                        className="cell full-width">
                        {t('Sie können sich nun mit dem neuen Passwort anmelden.')}</div>
                    <br/>
                    <div className="cell large">
                        <br/><br/>
                        <button
                            onClick={this.closeModalConfirm}
                            className="button button--register"
                        >
                            {t('Weiter')}
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    }

    componentDidMount() {
        if (this.props.isPasswordResetModalOpen) {
            this.setState({isPasswordResetModalOpen: this.props.isPasswordResetModalOpen})
        }
    }

    render() {
        return (
            <>
                {this.state.isPasswordResetModalOpen && this.renderPasswordResetBoxInstance()}
                {this.state.modalConfirmIsOpened && this.renderPasswordResetConfirmBoxInstance()}
            </>
        );
    }

    closeModalConfirm() {
        this.setState(prev => {
            prev.modalConfirmIsOpened = false;
            return prev;
        }, () => {
            this.props.history.push('/dashboard');
        });
    }

    closeModal(redirect = false) {
        this.setState(prev => {
            prev.isPasswordResetModalOpen = false;
            return prev;
        }, () => {
            if (redirect) {
                this.props.history.push('/');
            }
        });
    }

    showConfirmModal() {

        this.setState({
            error_msg: '',
            modalConfirmIsOpened: true
        })

        this.closeModal();
    }

    submitPasswordReset() {
        const {t} = this.props;

        this.props.dispatch(
            Action.changePassword(
                this.props.i18n.language,
                this.state.password,
                this.state.password_repeat,
                this.props.match.params.token
            )
        )
            .then(this.showConfirmModal)
            .catch(() => {
                this.setState({error_msg: t('Der Benutzer konnte nicht gefunden werden.')});
            })
    }
}

PasswordReset.propTypes = {
    t: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    i18n: PropTypes.any,
    history: PropTypes.any.isRequired,
    isPasswordResetModalOpen: PropTypes.bool,
    changePassword: PropTypes.shape(),
    match: PropTypes.shape({
        params: PropTypes.shape({
            token: PropTypes.string
        })
    })
};

const mapStateToProps = state => {
    return merge({}, {changePassword: state.changePassword});
};

export default connect(mapStateToProps)(translate(PasswordReset));

