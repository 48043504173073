import React            from 'react';
import PropTypes        from 'prop-types';
import ButtonNavigation from '../button-navigation';
import FetchUtils       from '../../advancd-fetch';
import get              from 'lodash.get';

class PriceVisualization extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            input: {
                price: null,
                weight: null
            },
            allowNext: false,
            errorMessage: null
        };

        this.allowNext = this.allowNext.bind(this);
        this.onCalculatePrice = this.onCalculatePrice.bind(this);
        this.getError = this.getError.bind(this);
        this.update = this.update.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.onBack = this.onBack.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
        this.proceedToNextStep = this.proceedToNextStep.bind(this);

        this.props.eventHandler.unbind('next-clicked', this.handleNext);
        this.props.eventHandler.bind('next-clicked', this.handleNext);
        this.props.eventHandler.unbind('back-clicked', this.onBack);
        this.props.eventHandler.bind('back-clicked', this.onBack);
    }

    _handleKeyDown(event) {
        let ENTER_CODE = 13;

        if (event.keyCode === ENTER_CODE) {
            event.preventDefault();

            if (this.allowNext(this.state)) {
                this.props.eventHandler.trigger('next-clicked');
            }

            return false;
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this._handleKeyDown);
    }

    componentDidMount() {
        document.addEventListener('keydown', this._handleKeyDown);
        this.props.eventHandler.trigger('protocol');
        this.props.toggleProgressBar(this.props.showProgressBar);
        this.props.toggleSideProtocol(this.props.showSideProtocol);
        this.proceedToNextStep()
            .then(response => {
                if (response === 'done') {
                    return Promise.resolve();
                }

                this.onCalculatePrice();
            });
    }

    allowNext(state) {
        return state.input.price != null && state.input.weight != null;
    }

    proceedToNextStep() {
        return fetch(`/api/${this.props.location.pathname}`, {credentials: 'include'})
            .then((response) => {
                return FetchUtils.checkStatus(response);
            })
            .then(response => {
                if (response.next) {
                    this.props.history.push(response.next);
                    return 'done'
                }

                let errorMessage = get(response, 'error.message');

                if (errorMessage) {
                    this.setState(prev => {
                        prev.errorMessage = errorMessage;

                        return prev;
                    });
                }

                this.setState(prev => {
                    prev.input.price = response.price;
                    prev.input.weight = response.weight;
                    prev.allowNext = this.allowNext(prev);

                    return prev;
                });
            });
    }

    onCalculatePrice() {
        this.setState(prev => {
            prev.errorMessage = null;
        });

        fetch(`/api/${this.props.productName}/price-calculation`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state.input)
        })
            .then(FetchUtils.checkStatus)
            .then((responseBody) => {
                this.props.eventHandler.trigger('update-progress-bar', {id: this.stepId, isCompleted: false});

                if (responseBody.error) {
                    let error = responseBody.error;
                    this.setState(prev => {
                        prev.errorMessage = error.message;
                        prev.fieldsWithError = error.invalidFields;
                    });
                    if (responseBody.next) {
                        this.props.history.push(responseBody.next);
                        return Promise.reject(new Error('done'));
                    }
                    return;
                }

                this.setState(prev => {
                    prev.input.price = responseBody.price;
                    prev.input.weight = responseBody.weight;
                    prev.allowNext = this.allowNext(prev);

                    return prev;
                });
            })
            .catch(err => {
                if (err.message !== 'done') {

                    this.setState(prev => {
                        prev.errorMessage = err.message
                    });
                    console.error(err.message, err);
                }
            });
    }

    getError() {
        const {t} = this.props;

        if (this.state.errorMessage) {
            return <div className="cell small-8 xlarge-7">
                <div className="alert callout" id="error-alert">
                    <h5>{t('Error')}</h5>
                    <p>{t(this.state.errorMessage)}</p>
                </div>
            </div>
        }
    }

    handleNext() {
        this.update();
    }

    onBack() {
        fetch(`/api${this.props.location.pathname}/back`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((result) => {
                result.json().then(body => {
                    this.props.history.push(body.previous);
                });
            });
    }

    update() {
        this.setState(prev => {
            prev.errorMessage = null;
        });
        fetch(`/api${this.props.location.pathname}`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state.input)
        })
            .then(FetchUtils.checkStatus)
            .then(body => {
                if (body.error) {
                    this.setState(prev => {
                        prev.errorMessage = body.error.message;
                    });
                }

                this.props.history.push(body.next)
            })
            .catch(err => {
                let errorMessage = get(err, 'error.message');

                this.setState(prev => {
                    prev.errorMessage = errorMessage;

                    return prev;
                });

                console.error(err);
            });
    }

    render() {
        const {t} = this.props;
        return <div className='comp-optional-information'>
            <div className="grid-container">
                <div className="grid-x">
                    {this.getError()}
                    <div className="cell small-8 xlarge-7">
                        <h2>{t('Preis Ausgabe')}</h2>
                    </div>
                </div>
            </div>

            <div id="price-result">
                {
                    this.state.input.price != null && this.state.input.weight != null &&
                    <div className="grid-container">
                        <div className="grid-x">
                            <div className="cell">
                                <form>
                                    <p className="price">{this.state.input.price} CHF</p>
                                    <p className="price-caption">{t('zzgl. Mwst. Bruttopreis, Ex. Werk Gunzgen')}</p>
                                    <p className="price-caption-inflation">
                                        {t('zzgl. Materialteuerungszuschlag von 7%')}
                                    </p>
                                    <br/>
                                    <p className="weight">{this.state.input.weight} kg</p>
                                </form>
                            </div>
                        </div>
                    </div>

                }
            </div>

            <div className="main-content-footer">
                <ButtonNavigation
                    t={t}
                    history={this.props.history}
                    enableNext={this.state.allowNext}
                    location={this.props.location}
                    eventHandler={this.props.eventHandler}/>
            </div>
        </div>
    }
}

PriceVisualization.propTypes = {
    eventHandler: PropTypes.object,
    productName: PropTypes.string,
    history: PropTypes.object,
    location: PropTypes.object,
    t: PropTypes.func,
    showSideProtocol: PropTypes.bool,
    showProgressBar: PropTypes.bool,
    toggleProgressBar: PropTypes.func,
    toggleSideProtocol: PropTypes.func
};

export default PriceVisualization;