'use strict';

import React         from 'react';
import {connect} from 'react-redux';
import checkAuthorised from './actions';
import merge from 'lodash.merge';
import PropTypes from 'prop-types';

export default WrappedElement => {
    class WithAuthorization extends React.Component {
        constructor(props) {
            super(props);

            this.checkAuthorised = this.checkAuthorised.bind(this);
        }

        componentDidMount() {
            this.checkAuthorised();
        }

        checkAuthorised() {
            this.props.dispatch(checkAuthorised())
                .catch(err => {
                    if (err.status !== 404 && err.status !== 403) {
                        console.error(err);
                    }
                });
        }

        render() {
            return (
                <div>
                    <WrappedElement
                        {...this.props}
                        checkAuthorised={this.checkAuthorised}
                    />
                </div>
            );
        }
    }

    WithAuthorization.propTypes = {
        i18n: PropTypes.any,
        dispatch: PropTypes.func.isRequired
    };

    const mapStateToProps = state => {
        return merge({}, state.shared);
    };

    return connect(mapStateToProps)(WithAuthorization);
};
