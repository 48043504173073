import fetchAndCheck from '../../lib/advanced-fetch';
import cfg from '../../conf';

export function changePassword(language, password, passwordRepeat, token) { //eslint-disable-line camelcase
    return {
        type: 'change-password',
        payload: fetchAndCheck(cfg.api.changePasswordApiUrl(language), {
            method: 'PUT',
            body: JSON.stringify({password, passwordRepeat, token}),
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }
}
