'use strict';

import React from 'react';
import PropTypes from 'prop-types';

class InputRadioFieldCombo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            radioFocused: false
        };

        this.radioInputRef = React.createRef();
        this.handleRadioFocus = this.handleRadioFocus.bind(this);
        this.handleRadioBlur = this.handleRadioBlur.bind(this);

        this.onRadioChange = this.onRadioChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);

    }

    componentDidMount() {
        this.updateEventListeners('add');
    }

    componentWillUnmount() {
        this.updateEventListeners('remove');
    }

    updateEventListeners(action) {
        const radioInput = this.radioInputRef.current;
        if (radioInput) {
            const method = action === 'add' ? 'addEventListener' : 'removeEventListener';
            radioInput[method]('focus', this.handleRadioFocus);
            radioInput[method]('blur', this.handleRadioBlur);
        }
    }

    onRadioChange() {
        this.props.onRadioSelectChange(this.props.type.key);
    }

    onInputChange(event) {
        const value = event.target.value;
        const id = event.target.id;
        this.props.onInputChange(this.props.type.key, id, value);
    }

    render() {
        const {t, hided} = this.props;
        const { radioFocused } = this.state;

        //eslint-disable-next-line
        const radioContainerClasses = `radio-container ${this.props.radioContainerClasses ? this.props.radioContainerClasses : ''} ${radioFocused ? 'focused' : ''}`;

        let inputClasses = `input input--${this.props.inputSize}`,
            requiredOptionalClass = 'input--optional',
            labelClass = 'label--content';
        const valueDisabled = !((this.props.type.key === this.props.typeSelected) || this.props.activateValue);


        if (valueDisabled) {
            inputClasses += ' disabled';
        }

        if (!this.props.optional) {
            requiredOptionalClass = 'input--required';
            labelClass += this.props.showRequiredSign ? '' : ' hide-sign';
        }

        if (this.props.hasError) {
            inputClasses += ' error';
        }

        if(hided){
            return null;
        }

        return (
            <div className="radio-field-combo">
                <div
                    className={radioContainerClasses}
                >
                    {
                        this.props.showCheckbox && <div className="radio">

                            <input
                                id={`${this.props.type.key}${this.props.id == null ? '' : this.props.id}`}
                                type="radio"
                                name={this.props.name}
                                ref={this.radioInputRef}
                                checked={this.props.type.key === this.props.typeSelected}
                                onChange={this.onRadioChange}
                            />

                            <label
                                htmlFor={`${this.props.type.key}${this.props.id == null ? '' : this.props.id}`}
                                className={requiredOptionalClass}
                            >
                                <span className={labelClass}>{t(this.props.type.label)}</span>
                            </label>
                        </div>
                    }
                    {
                        !this.props.showCheckbox && <div className='radio no-checkbox'><label
                            htmlFor={`${this.props.type.key}${this.props.id == null ? '' : this.props.id}`}>
                            {t(this.props.type.label)}
                        </label></div>
                    }
                </div>

                {this.props.showInput &&
                <div
                    className={
                        `input-container ${this.props.inputContainerClasses ? this.props.inputContainerClasses : ''}`
                    }
                >
                    {
                        this.props.type.additionalInfo ? this.props.type.additionalInfo.map(info => {
                            return (
                                <label
                                    className={`${inputClasses} ${requiredOptionalClass} additional-info`}
                                    key={info.key}
                                >
                                    <input type={this.props.inputType}
                                        value={info.value}
                                        id={info.key}
                                        disabled={valueDisabled === false ? null : true}
                                        onChange={this.onInputChange}
                                    />
                                    <span className="input__error-bottom">{this.props.errorMessage}</span>
                                    <span className={labelClass}>{info.unit}</span>
                                    <span className="input-label-bottom">{t(info.label)}</span>
                                </label>
                            )
                        }) :
                            (
                                <label className={`${inputClasses} ${requiredOptionalClass}`}>
                                    <input type={this.props.inputType}
                                        value={this.props.type.value}
                                        id={`${this.props.type.key}${this.props.id == null ? '' : this.props.id}`}
                                        disabled={valueDisabled === false ? null : true}
                                        onChange={this.onInputChange}
                                    />
                                    <span className="input__error-bottom">{this.props.errorMessage}</span>
                                    <span className={labelClass}>{this.props.type.unit}</span>
                                </label>
                            )
                    }
                </div>
                }
            </div>

        )
    }

    handleRadioFocus() {
        this.setState({ radioFocused: true });
    }

    handleRadioBlur() {
        this.setState({ radioFocused: false });
    }
}

InputRadioFieldCombo.propTypes = {
    onRadioSelectChange: PropTypes.func,
    onInputChange: PropTypes.func,
    name: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.shape({
        key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        unit: PropTypes.any,
        additionalInfo: PropTypes.arrayOf(PropTypes.object),
        label: PropTypes.string
    }),
    typeSelected: PropTypes.string,
    hasError: PropTypes.bool,
    t: PropTypes.func,
    errorMessage: PropTypes.string,
    hided: PropTypes.bool,
    showCheckbox: PropTypes.bool,
    showInput: PropTypes.bool,
    activateValue: PropTypes.bool,
    radioContainerClasses: PropTypes.string,
    inputContainerClasses: PropTypes.string,
    inputSize: PropTypes.oneOf(['small', 'large']),
    inputType: PropTypes.string,
    optional: PropTypes.bool,
    showRequiredSign: PropTypes.bool
};

InputRadioFieldCombo.defaultProps = {
    hided: false,
    showCheckbox: true,
    showInput: true,
    inputSize: 'small',
    inputType: 'number',
    optional: true,
    showRequiredSign: true
};

export default InputRadioFieldCombo;