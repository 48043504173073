'use strict';

const Step = require('./step');

class StepProductSelector extends Step {

    constructor() {
        super({pathName: '/'});

        this.nextConfig = {};
    }

    setNextConfig(config) {
        this.nextConfig = config;
    }

    // product preconditions are true if no product name is set
    // precondition means that it is the turn of the current step
    precondition() {
        if(this.data.productname != null) return;
    }

    validate() {
        //check if productname is in config
    }

    next() {
        return this.nextConfig[this.data.productname];
    }

    isComplete(data) {
        if (!data) return false;
        return !!data.product;
    }

    getStepData(data) {
        return data.product;
    }
}


module.exports = StepProductSelector;