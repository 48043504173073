import React        from 'react';
import PropTypes    from 'prop-types';
import FetchUtils   from '../../advancd-fetch';
import ProtocolText from './lib/protocol-text';
import translate    from '../translate';
import get          from 'lodash.get';

class Protocol extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hideProtocol: true,
            currentConfig: {}
        };

        this.handleNext = this.handleNext.bind(this);

        this.props.eventHandler.bind('protocol', this.handleNext);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.getCurrentConfig()
            .then(config => {
                this.setState({currentConfig: config});
            });
    }

    render() {
        const {t, isVisible} = this.props;

        if (!get(this.state, 'currentConfig.data')) return null;

        if (!isVisible) {
            return null;
        }

        let protocolClassName = this.state.hideProtocol ? 'close' : 'open';

        return (
            <div
                className={`protocol ${protocolClassName} ${this.state.currentConfig.data.product ? '' : 'hide'}`}
            >
                <a className="protocol-box-toggle" onClick={this.handleChange}>
                    <p className={'protocol-title'}>
                        {t('Protokoll')}
                    </p>

                    <span className={'protocol-title-arrow'}/>
                </a>

                <div className={'protocol-content-box'}>
                    <div className={'protocol-content'}>
                        <div className={'protocol-text'}>
                            <b key={'Protokoll der letzten Schritte'}
                                className={'title'}>{t('Protokoll der letzten Schritte')}</b>
                            <ProtocolText
                                t={t}
                                config={this.state.currentConfig}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    handleNext() {
        this.getCurrentConfig()
            .then(config => {
                this.setState(prev => {
                    prev.currentConfig = config;

                    return prev;
                });
            });
    }

    getCurrentConfig() {
        return fetch('/api/current-config', {credentials: 'include'})
            .then((response) => {
                return FetchUtils.checkStatus(response);
            });
    }

    handleChange() {
        this.setState(prev => {
            prev.hideProtocol = !prev.hideProtocol;

            return prev;
        });
    }
}

Protocol.propTypes = {
    t: PropTypes.func,
    eventHandler: PropTypes.object,
    isVisible: PropTypes.bool
};

export default translate(Protocol);
