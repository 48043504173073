import get from 'lodash.get';
import merge from 'lodash.merge';

const {createReducer} = require('../../lib/reducer');

let initialState = {
    projectData: {
        project_name: '',
        customer_number: '',
        company: '',
        first_name: '',
        last_name: '',
        street: '',
        city: '',
        email: '',
        phone: '',
        delivery_date: '',
        delivery_address: '',
        comment: '',
        zip: ''
    },
    invalidFields: {
        fields: [],
        details: {}
    },
    error: {},
    loading: false,
    isSaving: {},
    success: {}
};

export const projectInfoDataReducer = createReducer(initialState, {
    //Save project data to server
    'save-project-info-data_PENDING': (state) => {
        state.success = undefined;
        state.error = undefined;

        return merge({}, state, {isSaving: true});
    },

    'save-project-info-data_FULFILLED': (state, action) => {
        //Reset invalid fields
        state.invalidFields = {
            fields: [],
            details: {}
        };

        // reset general error
        state.error = undefined;

        return merge(
            {},
            state,
            {
                isSaving: false,
                success: true,
                user: action.payload.user
            }
        );
    },

    'save-project-info-data_REJECTED': (state, action) => {
        //Reset invalid fields
        state.invalidFields = {
            fields: [],
            details: {}
        };

        let error = action.payload.error;
        let message = action.payload.message;
        let invalidFields = {};

        if (error.status === 422) {
            invalidFields = get(error, 'details.codes', {});
        }

        return merge({}, state, {
            isSaving: false,
            error: message,
            invalidFields: {
                fields: Object.keys(invalidFields),
                details: invalidFields
            },
            success: false
        });
    },

    'sync-project-info-data': (state, action) => {
        return merge({}, state, {
            projectData: action.data,
            disableSaveButton: !action.data.first_name ||
                !action.data.last_name ||
                !action.data.email ||
                !action.data.delivery_address ||
                !action.data.company ||
                !action.data.zip
        });
    }
});
