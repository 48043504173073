let cnf = {};

cnf.i18n = {
    //ns: ['common', 'portal'],
    ns: ['translation'],
    defaultNS: 'translation',
    locales: ['de', 'fr', 'it'],
    preload: ['de', 'fr', 'it'],
    whitelist: ['de', 'fr', 'it'],
    fallbackLng: 'de',
    saveMissing: false,
    nsSeparator: ':::',
    keySeparator: '::',
    detection: {
        order: ['htmlTag']
    },
    react: {
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default',
        useSuspense: true
    },
    backend: {
        loadPath: '/static/locales/{{lng}}/{{ns}}.json',
        addPath: '/debug/locales/add/{{lng}}/{{ns}}'
    }
};

export default cnf;
