import React                     from 'react';
import Login                     from '../login';
import Register                  from '../register';
import PasswordResetEmailRequest from '../password-reset-email-request';
import {withRouter}              from 'react-router-dom';
import PropTypes                 from 'prop-types';
import {connect}                 from 'react-redux';
import merge                     from 'lodash.merge';
import {subject}                 from '@casl/ability';
import imagesHelper              from '../../lib/images-helper';

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoginModalOpen: false,
            isRegistrationModalOpen: false,
            isPasswordRequestModalOpen: false,
            showLoginRegistrationHeader: (props.checkUserAuthorised && !props.userAuthorised),
            showLogoutRegistrationHeader: (props.checkUserAuthorised && props.userAuthorised)
        };

        this.openLoginModal = this.openLoginModal.bind(this);
        this.closeLoginModal = this.closeLoginModal.bind(this);
        this.openRegistrationModal = this.openRegistrationModal.bind(this);
        this.closeRegistrationModal = this.closeRegistrationModal.bind(this);
        this.openPasswordRequestModal = this.openPasswordRequestModal.bind(this);
        this.closePasswordRequestModal = this.closePasswordRequestModal.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.checkUserAuthorised !== this.props.checkUserAuthorised) {
            return this.setState(prev => {
                prev.showLoginRegistrationHeader = !this.props.userAuthorised;
                prev.showLogoutRegistrationHeader = this.props.userAuthorised;

                return prev;
            });
        }
    }

    render() {
        return <header className="app-header">
            {this.state.showLoginRegistrationHeader && this.getLoginFragment()}
            {this.state.showLogoutRegistrationHeader && this.getLogoutFragment()}
            <img className="logo" src={imagesHelper['/static/assets/images/logo.png']}/>
            <h1 className="app-title">Schachtkonfigurator</h1>
        </header>
    }

    getLoginFragment() {
        return <span>
            <Login
                {...this.props}
                closeModal={this.closeLoginModal}
                isModalOpen={this.state.isLoginModalOpen}
                openRegistrationModal={this.openRegistrationModal}
                openPasswordRequestModal={this.openPasswordRequestModal}
            />
            <Register
                {...this.props}
                closeModal={this.closeRegistrationModal}
                isModalOpen={this.state.isRegistrationModalOpen}
            />
            <PasswordResetEmailRequest
                {...this.props}
                closePasswordRequestModal={this.closePasswordRequestModal}
                isModalOpen={this.state.isPasswordRequestModalOpen}
            />
            <p className="beta-label">
                <a href={'#'} onClick={this.openRegistrationModal}>Register</a>
            </p>
            <p className="beta-label">
                <a href={'#'} onClick={this.openLoginModal}>Login</a>
            </p>
        </span>;
    }

    getLogoutFragment() {
        return <span>
            <p className="beta-label">
                <a href={'/logout'}><b>Logout</b></a>
            </p>
            <p className="beta-label">
                <a href={'/edit-user'}>Benutzerprofil</a>
            </p>
            { this.props.ability.can('read', subject('UserData', {})) &&
                <p className="beta-label">
                    <a href={'/user-overview'}>Benutzerübersicht</a>
                </p>
            }
            <p className="beta-label">
                <a href={'/dashboard'}>Projektübersicht</a>
            </p>
        </span>;
    }

    openLoginModal(e) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({isLoginModalOpen: true});
    }

    closeLoginModal() {
        this.setState({isLoginModalOpen: false});
    }

    openRegistrationModal(e) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({isRegistrationModalOpen: true});
    }

    closeRegistrationModal() {
        this.setState({isRegistrationModalOpen: false});
    }

    openPasswordRequestModal() {
        this.setState({isPasswordRequestModalOpen: true});
    }

    closePasswordRequestModal() {
        this.setState({isPasswordRequestModalOpen: false});
    }
}

Header.propTypes = {
    userAuthorised: PropTypes.bool,
    checkUserAuthorised: PropTypes.bool,
    history: PropTypes.object,
    ability: PropTypes.shape({
        can: PropTypes.func
    })
};

const mapStateToProps = state => {
    return merge({}, state.shared);
};

export default withRouter(connect(mapStateToProps)(Header));
