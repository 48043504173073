'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import ButtonNavigation from '../button-navigation/index';
import Product from '../product-card';
import ImageContainer from '../image-container';
import imagesHelper from '../../lib/images-helper';


class ProductSelector extends React.Component {
    constructor(props) {
        super(props);

        this.eventHandler = props.eventHandler;
    }

    render() {
        const {t} = this.props;
        return (
            <div className="comp-product-selector">

                <div className="grid-container">
                    <div className="grid-x">
                        <div className="cell small-8 xlarge-7">
                            <h2>{t('Wählen Sie Ihren Schachttyp')}</h2>
                        </div>
                    </div>
                </div>
                <div className="grid-container">

                    <div className="grid-x">
                        <div className="cell small-6">
                            <ul className="product-list">
                                {this.props.products.map((product) => {
                                    return (
                                        <li key={product.key}>
                                            <Product
                                                name={t(product.value)}
                                                isActive={product.key === this.props.activeId}
                                                productKey={product.key}
                                                click={this.props.update}/>

                                        </li>

                                    )
                                })}
                            </ul>
                        </div>
                        <div className="cell small-6">
                            <div className="product-image">
                                <ImageContainer
                                    title={t('Skizze Komplettschacht')}
                                    src={imagesHelper['/static/assets/images/01.png']}
                                />
                            </div>
                        </div>
                    </div>


                </div>



                <div className="main-content-footer">
                    <ButtonNavigation
                        hideBackButton={true}
                        history={this.props.history}
                        enableNext={this.props.allowNext}
                        location={this.props.location}
                        eventHandler={this.eventHandler} />
                </div>
            </div>
        )
    }
}

export default ProductSelector;

ProductSelector.propTypes = {
    activeId: PropTypes.any,
    allowNext: PropTypes.any,
    eventHandler: PropTypes.any,
    history: PropTypes.any,
    location: PropTypes.any,
    products: PropTypes.any,
    t: PropTypes.any,
    update: PropTypes.any
};