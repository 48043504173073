'use strict';

import image01 from '../assets/images/01.png';
import image02 from '../assets/images/02.png';
import image03 from '../assets/images/03.png';
import image04 from '../assets/images/04.png';
import image05 from '../assets/images/05.png';
import image06 from '../assets/images/06.png';
import image07 from '../assets/images/07.png';
import image08 from '../assets/images/08.png';
import image09 from '../assets/images/09.png';
import image10 from '../assets/images/10.png';
import image11 from '../assets/images/11.png';
import image12 from '../assets/images/12.png';
import image13 from '../assets/images/13.png';

import angleDownGray from '../assets/images/angle-down-gray.png';
import arrow from '../assets/images/arrow.svg';
import arrowDown from '../assets/images/arrow-down.svg';
import arrowUp from '../assets/images/arrow-up.svg';
import circle from '../assets/images/circle.svg';
import circleWithAngleValues from '../assets/images/circle-with-angle-values.svg';
import floorType from '../assets/images/floor-type.png';
import inOutKomplettschacht from '../assets/images/in-out-komplettschacht.png';
import inOutKomplettschachtMitGerinne from '../assets/images/in-out-komplettschacht-mit-gerinne.png';
import inOutKomplettschachtNoAdapter from '../assets/images/in-out-komplettschacht-no-adapeter.png';
import introKs from '../assets/images/intro_ks.png';
import introSs from '../assets/images/intro_ss.png';
import komplettschachtJpg from '../assets/images/komplettschacht.jpg';
import komplettschachtPng from '../assets/images/komplettschacht.png';
import logo from '../assets/images/logo.png';
import measurement from '../assets/images/measurement.png';
import productSelection from '../assets/images/product-selection.png';
import schlammsammlerJpg from '../assets/images/schlammsammler.jpg';
import schlammsammlerPng from '../assets/images/schlammsammler.png';
import schlammsammlerDiameter from '../assets/images/schlammsammler-diameter.png';
import schlammsammlerMeasurements from '../assets/images/schlammsammler-measurements.png';
import skizzeKomplettschacht from '../assets/images/skizze-komplettschacht.png';

export default {
    '/static/assets/images/01.png': image01,
    '/static/assets/images/02.png': image02,
    '/static/assets/images/03.png': image03,
    '/static/assets/images/04.png': image04,
    '/static/assets/images/05.png': image05,
    '/static/assets/images/06.png': image06,
    '/static/assets/images/07.png': image07,
    '/static/assets/images/08.png': image08,
    '/static/assets/images/09.png': image09,
    '/static/assets/images/10.png': image10,
    '/static/assets/images/11.png': image11,
    '/static/assets/images/12.png': image12,
    '/static/assets/images/13.png': image13,

    '/static/assets/images/angle-down-gray.png': angleDownGray,
    '/static/assets/images/arrow.svg': arrow,
    '/static/assets/images/arrow-down.svg': arrowDown,
    '/static/assets/images/arrow-up.svg': arrowUp,
    '/static/assets/images/circle.svg': circle,
    '/static/assets/images/circle-with-angle-values.svg': circleWithAngleValues,
    '/static/assets/images/floor-type.png': floorType,
    '/static/assets/images/in-out-komplettschacht.png': inOutKomplettschacht,
    '/static/assets/images/in-out-komplettschacht-mit-gerinne.png': inOutKomplettschachtMitGerinne,
    '/static/assets/images/in-out-komplettschacht-no-adapeter.png': inOutKomplettschachtNoAdapter,
    '/static/assets/images/intro_ks.png': introKs,
    '/static/assets/images/intro_ss.png': introSs,
    '/static/assets/images/komplettschacht.jpg': komplettschachtJpg,
    '/static/assets/images/komplettschacht.png': komplettschachtPng,
    '/static/assets/images/logo.png': logo,
    '/static/assets/images/measurement.png': measurement,
    '/static/assets/images/product-selection.png': productSelection,
    '/static/assets/images/schlammsammler.jpg': schlammsammlerJpg,
    '/static/assets/images/schlammsammler.png': schlammsammlerPng,
    '/static/assets/images/schlammsammler-diameter.png': schlammsammlerDiameter,
    '/static/assets/images/schlammsammler-measurements.png': schlammsammlerMeasurements,
    '/static/assets/images/skizze-komplettschacht.png': skizzeKomplettschacht
};
