'use strict';

import React     from 'react';
import PropTypes from 'prop-types';
import imagesHelper from '../../../lib/images-helper';

import AngleView from '../index';

const arrowWidth = 35;

class InletAngleView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            diameterAvg: 0,
            widthFactor: 0
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.diameterOptions !== this.props.diameterOptions) {
            this.setState(prev => {
                prev.diameterAvg = 0;
                if (this.props.diameterOptions.length) {
                    prev.diameterAvg = this.props.diameterOptions
                        .reduce((prev, curr) => prev + curr.value, 0) / this.props.diameterOptions.length;
                }

                prev.widthFactor = prev.diameterAvg / arrowWidth;

                return prev;
            });
        }
    }

    render() {
        return (
            <AngleView
                wrapperClass={'angle-view-wrapper-inlet'}
                outerCircleClass={'angle-view-circle-inlet'}
                innerCircleClass={'angle-view-circle-values-inlet'}
            >
                {
                    this.props.angleInputs.map((angleInput, index) => (
                        <div
                            className={
                                'angle-view-circle angle-view-circle-needle angle-view-circle-needle-inlet'
                            }
                            key={index}
                            style={{
                                width: this.state.widthFactor
                                    ? (this.props.activeDiameters[index] / this.state.widthFactor) + 'px'
                                    : '0px',
                                transform: `rotate(-${angleInput}deg)`,
                            }}
                        >
                            <img
                                className={'angle-view-inlet-arrow'}
                                src={imagesHelper['/static/assets/images/arrow.svg']}
                                alt={''}
                            />
                        </div>
                    ))
                }
                    
                <div
                    className={
                        'angle-view-circle angle-view-circle-static angle-view-circle-static-inlet'
                    }
                    style={{
                        width: this.state.widthFactor
                            ? (this.props.outfallDiameter / this.state.widthFactor) + 'px'
                            : '0px',
                    }}
                >
                    <img
                        className={'angle-view-circle-static-outfall-arrow'}
                        src={imagesHelper['/static/assets/images/arrow.svg']}
                        alt={''}
                    />
                </div>
                <div
                    className={'angle-view-dot'}
                />
            </AngleView>
        );
    }
}

InletAngleView.propTypes = {
    angleInputs: PropTypes.array,
    activeDiameters: PropTypes.array,
    diameterOptions: PropTypes.array,
    outfallDiameter: PropTypes.number
};

InletAngleView.defaultProps = {
    angleInputs: [],
    activeDiameters: [],
    diameterOptions: []
}

export default InletAngleView;
