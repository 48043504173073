'use strict';

import React      from 'react';
import 'whatwg-fetch';
import _          from 'lodash';
import PropTypes  from 'prop-types';
import FetchUtils from './../../advancd-fetch';

import TypeDimension from './../type-dimension';

class TypeDimensionData extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            allowNext: false,
            typeSelected: null,
            inputValue: '',
            types: [],
            invalidFields: [],
            image: '',
            text: ''
        };

        this.load = this.load.bind(this);
        this.update = this.update.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.onRadioSelectChange = this.onRadioSelectChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.allowNext = this.allowNext.bind(this);
        this.onBack = this.onBack.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
        this.stepId = 'typeOfDimension';

        // pare this. because somehow we come through the constructor relatively often
        this.props.eventHandler.unbind('next-clicked', this.handleNext);
        this.props.eventHandler.bind('next-clicked', this.handleNext);
        this.props.eventHandler.unbind('back-clicked', this.onBack);
        this.props.eventHandler.bind('back-clicked', this.onBack);
    }

    _handleKeyDown(event) {
        let ENTER_CODE = 13;

        if (event.keyCode === ENTER_CODE) {
            event.preventDefault();

            if (this.allowNext(this.state)) {
                this.props.eventHandler.trigger('next-clicked');
            }

            return false;
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this._handleKeyDown);
    }

    componentDidMount() {
        document.addEventListener('keydown', this._handleKeyDown);
        this.props.eventHandler.trigger('protocol');
        this.props.toggleProgressBar(this.props.showProgressBar);
        this.props.toggleSideProtocol(this.props.showSideProtocol);
        this.load();
    }


    handleNext() {
        this.update();
    }

    allowNext(state) {
        let type = _.find(state.types, {key: state.typeSelected});
        let firstValue, secondvalue;

        if (state.typeSelected === 'hohenkote_ab_ok_schacht') {
            firstValue = _.get(type, 'additionalInfo[0].value');
            secondvalue = _.get(type, 'additionalInfo[1].value');
        } else {
            firstValue = _.get(type, 'value');
        }

        if (state.typeSelected == null) return false;
        if (firstValue == null || firstValue === '') return false;
        if (firstValue != null && parseInt(firstValue) < 0) return false;
        if (state.typeSelected === 'hohenkote_ab_ok_schacht') {
            if (secondvalue == null || secondvalue === '') return false;
            if (secondvalue != null && parseInt(secondvalue) < 0) return false;
        }

        return true;
    }

    load() {
        fetch(`/api${this.props.location.pathname}`, {credentials: 'include'})
            .then((response) => {
                return FetchUtils.checkStatus(response);
            })
            .then(response => {
                this.props.eventHandler.trigger('update-progress-bar', {id: this.stepId, isCompleted: false});

                if (response.next) {
                    this.props.history.push(response.next);
                    return Promise.reject(new Error('done'));
                }

                let types = response.types;
                let image = response.image;
                let text = response.text;
                if (types) {
                    this.setState(prev => {
                        prev.types = types;
                        prev.image = image;
                        prev.text = text;

                        prev.typeSelected = response.input.typeDimension;

                        let type = _.find(prev.types, {key: prev.typeSelected});
                        //let firstValue, secondvalue;

                        if (prev.typeSelected === 'hohenkote_ab_ok_schacht') {
                            //firstValue = _.get(type, 'additionalInfo[0].value');
                            //secondvalue = _.get(type, 'additionalInfo[1].value');
                            _.set(type, 'additionalInfo[0].value', response.input.valueDimension);
                            _.set(type, 'additionalInfo[1].value', response.input.secondValueDimension);
                        } else {
                            //firstValue = _.get(type, 'value');

                            _.set(type, 'value', response.input.valueDimension);
                        }

                        if (
                            response.input.hdDimension ||
                            prev.typeSelected === 'hohenkote_ab_ok_schacht' ||
                            prev.typeSelected === 'ts_tiefe_schacht'
                        ) {
                            let hdDimensionType = _.find(prev.types, {key: 'hd_hohe_deckel'});

                            _.set(hdDimensionType, 'value', response.input.hdDimension);
                            _.set(hdDimensionType, 'hided', false);
                            _.set(hdDimensionType, 'activateValue', true);
                        }

                        prev.allowNext = this.allowNext(prev);

                        return prev;
                    });
                }

                if (_.get(response, 'rightImage.src')) {
                    this.setState({image: _.get(response, 'rightImage.src')})
                }
            })
            .catch(err => {
                if (err.message !== 'done') {
                    console.error(err);

                    this.props.history.push('/error');
                }
            });
    }

    update() {
        let type = _.find(this.state.types, {key: this.state.typeSelected});
        let firstValue, secondvalue;

        if (this.state.typeSelected === 'hohenkote_ab_ok_schacht') {
            firstValue = _.get(type, 'additionalInfo[0].value');
            secondvalue = _.get(type, 'additionalInfo[1].value');
        } else {
            firstValue = _.get(type, 'value');
        }

        // Reset all errors
        this.setState(prev => {
            prev.types = prev.types.map(type => {
                delete type.error;
                delete type.errorMessage;

                return type;
            });

            return prev;
        });


        fetch(`/api${this.props.location.pathname}`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                typeDimension: this.state.typeSelected,
                valueDimension: firstValue,
                secondValueDimension: secondvalue,
                hdDimension: _.get(_.find(this.state.types, {key: 'hd_hohe_deckel'}), 'value')
            })
        })
            .then((result) => {
                result.json().then(body => {
                    if (body.error) {

                        this.setState(prev => {

                            prev.types = prev.types.map(type => {
                                let invalidFields = _.get(body, 'error.invalidFields');

                                if (invalidFields) {
                                    let invalidField = invalidFields.filter(field => field.fieldName === type.key);
                                    if (invalidField.length > 0) {
                                        type.error = true;
                                        type.errorMessage = invalidField[0].message;
                                    }
                                }

                                return type;
                            });

                            return prev;
                        })
                    }
                    this.props.history.push(body.next);
                });
            })
            .catch(err => {
                console.error(err);
            })
    }


    onUpdate() {
        this.setState((prev) => {
            prev.allowNext = this.allowNext(prev);
            return prev;
        });
    }

    onRadioSelectChange(value) {
        this.setState(prev => {
            prev.typeSelected = value;


            let type = _.find(prev.types, {key: 'hd_hohe_deckel'});

            if (!type) return prev;

            type.hided = !(prev.typeSelected === 'ts_tiefe_schacht' || prev.typeSelected === 'hohenkote_ab_ok_schacht');
            type.activateValue =
                prev.typeSelected === 'ts_tiefe_schacht' || prev.typeSelected === 'hohenkote_ab_ok_schacht';

            return prev;
        })
    }

    onInputChange(key, id, value) {
        this.setState(prev => {
            let type = _.find(prev.types, {'key': id});

            if (type) {
                type.value = value;
            } else {
                type = _.find(prev.types, {additionalInfo: [{key: id}]});
                let info = _.find(type.additionalInfo, {key: id});
                _.set(info, 'value', value);
            }

            prev.allowNext = this.allowNext(prev);

            return prev;
        })
    }

    render() {
        const {t} = this.props;

        return (
            <TypeDimension
                t={t}
                eventHandler={this.props.eventHandler}
                location={this.props.location}
                history={this.props.history}
                onRadioSelectChange={this.onRadioSelectChange}
                onInputChange={this.onInputChange}
                types={this.state.types}
                typeSelected={this.state.typeSelected}
                inputValue={this.state.inputValue}
                allowNext={this.state.allowNext}
                image={this.state.image}
                text={this.state.text}
            />
        );
    }

    onBack() {
        fetch(`/api${this.props.location.pathname}/back`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((result) => {
                result.json().then(body => {
                    this.props.history.push(body.previous);
                });
            });
    }
}

TypeDimensionData.propTypes = {
    eventHandler: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    t: PropTypes.func,
    showSideProtocol: PropTypes.bool,
    showProgressBar: PropTypes.bool,
    toggleProgressBar: PropTypes.func,
    toggleSideProtocol: PropTypes.func
};

export default TypeDimensionData;