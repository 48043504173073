'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import InputRadioButton from '../input-radio-button';

class InputRadioGroup extends React.Component {
    constructor(props) {
        super(props);

        this.update = this.update.bind(this);
    }

    render() {
        const {t} = this.props;

        let requiredOptionalClass = 'input--optional',
            labelClass = 'label--content';

        if (!this.props.optional) {
            requiredOptionalClass = 'input--required';
            labelClass += this.props.showRequiredSign ? '' : ' hide-sign';
        }

        return (
            <div className={`radio-group ${requiredOptionalClass}`}>
                {this.props.options.map((option, key) => {
                    if (option.isHeading) {
                        return <h3 key={key} className={`${key} ${labelClass}`}>{t(option.title)}</h3>;
                    }

                    return <InputRadioButton
                        t={t}
                        key={key}
                        name={this.props.name}
                        checked={option.value === this.props.value}
                        id={option.id + (this.props.id !== undefined ? this.props.id : '')}
                        value={option.value}
                        label={option.label}
                        click={this.update}
                    />

                })}
            </div>
        )
    }

    update(event) {
        let target = event.target;

        if (target.value) {
            this.props.onChange(target.value);
        }
    }
}

InputRadioGroup.propTypes = {
    id: PropTypes.any,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.any.isRequired,
    value: PropTypes.any,
    t: PropTypes.func,
    optional: PropTypes.bool,
    showRequiredSign: PropTypes.bool
};

InputRadioGroup.defaultProps = {
    optional: true,
    showRequiredSign: true
};

export default InputRadioGroup;

