'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import 'whatwg-fetch';

import ProductSelector from './../product-selector';
import cfg from './../../conf';

class ProductSelectorData extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            input: {
                product: null
            },
            products: [],
            allowNext: false
        };

        this.load = this.load.bind(this);
        this.update = this.update.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.allowNext = this.allowNext.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
        this.stepId = 'productSelector';

        // pare this. because somehow we come through the constructor relatively often
        this.props.eventHandler.unbind('next-clicked', this.handleNext);
        this.props.eventHandler.bind('next-clicked', this.handleNext);
    }

    _handleKeyDown (event) {

        let ENTER_CODE = 13;

        if (event.keyCode === ENTER_CODE) {
            event.preventDefault();

            if(this.allowNext(this.state)) {
                this.props.eventHandler.trigger('next-clicked');
            }

            return false;
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this._handleKeyDown);
    }

    componentDidMount() {
        document.addEventListener('keydown', this._handleKeyDown);
        this.props.eventHandler.trigger('protocol');
        this.props.toggleProgressBar(this.props.showProgressBar);
        this.props.toggleSideProtocol(this.props.showSideProtocol);
        this.load();
    }


    handleNext() {
        this.update();
    }

    allowNext(state) {
        return !!state.input.product;
    }


    load() {
        fetch(cfg.api.productSelectorApiUrlTemplate(), {
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                response.json()
                    .then((body) => {
                        this.props.eventHandler.trigger('update-progress-bar', {id: this.stepId, isCompleted: false});

                        if (body.next) {
                            this.props.history.push(body.next);
                            return Promise.reject(new Error('done'));
                        }

                        if (body.products) {
                            this.setState(prev => {
                                prev.products = body.products;
                                prev.input = body.input;
                                prev.allowNext = this.allowNext(prev);

                                return prev;
                            });
                        }
                    })
                    .catch(err => {
                        if (err.message !== 'done') {
                            console.error(err);

                            this.props.history.push('/error');
                        }
                    });
            });
    }

    // update server
    update() {
        fetch(cfg.api.productSelectorApiUrlTemplate(), {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                product: this.state.input.product
            })
        })
            .then((result) => {
                result.json().then(body => {
                    this.props.eventHandler.trigger('protocol');
                    this.props.history.push(body.next);
                });
            })
            .catch(err => {
                console.error(err);
                this.props.history.push('/error');
            });
    }

    // event handler
    onUpdate(productKey) {
        this.setState((prev) => {
            prev.input.product = productKey;
            prev.allowNext = true;

            return prev;
        });
    }


    render() {
        const {t} = this.props;
        return (
            <ProductSelector t={t}
                eventHandler={this.props.eventHandler}
                location={this.props.location}
                update={this.onUpdate}
                products={this.state.products}
                activeId={this.state.input.product}
                allowNext={this.state.allowNext}/>
        );
    }
}

export default ProductSelectorData;

ProductSelectorData.propTypes = {
    eventHandler: PropTypes.any,
    history: PropTypes.any,
    location: PropTypes.any,
    t: PropTypes.any,
    showSideProtocol: PropTypes.bool,
    showProgressBar: PropTypes.bool,
    toggleProgressBar: PropTypes.func,
    toggleSideProtocol: PropTypes.func
};