'use strict';

import React     from 'react';
import PropTypes from 'prop-types';
import translate from '../translate';

import ButtonNavigation     from './../button-navigation';
import InputRadioGroup      from '../input-radio-group';
import InputSelect          from '../input-select/index';
import InputField           from '../input-field';
import InputTextarea        from '../input-textarea/index';
import InputRadioFieldCombo from '../input-radio-field-combo';
import InfoText             from '../info-text';
import OutfallAngleView     from '../angle-view/lib/outfall';

class Outfall extends React.Component {
    constructor(props) {
        super(props);

        this.onChangePipetype = this.onChangePipetype.bind(this);
        this.onChangeDiameter = this.onChangeDiameter.bind(this);
        this.onChangeFall = this.onChangeFall.bind(this);
        this.onChangeComment = this.onChangeComment.bind(this);
        this.onChangePipeSeries = this.onChangePipeSeries.bind(this);
    }


    render() {
        const {t} = this.props;
        return (
            <div className="comp-outfall">
                <div className="grid-container">
                    <div className="grid-x grid-padding-x">
                        <div className="cell small-6 xlarge-5">
                            <h2>{t('Definieren Sie den Auslauf')}</h2>
                            <form>
                                <div className="grid-x">
                                    <div className="cell">
                                        <InputRadioGroup
                                            t={t}
                                            options={this.props.pipetypeOptions}
                                            value={this.props.activePipetype}
                                            name="pipetype"
                                            onChange={this.onChangePipetype}
                                            optional={false}
                                        />

                                        <InputSelect
                                            t={t}
                                            label={t('Durchmesser')}
                                            disabled={this.props.diameterOptions.length > 0 ? '' : 'disabled'}
                                            options={this.props.diameterOptions}
                                            value={this.props.activeDiameter}
                                            name="diameter"
                                            onChange={this.onChangeDiameter}
                                            optional={false}
                                        />

                                        {
                                            this.props.pipeSeriesOptions.length === 0 ? '' :
                                                <InputSelect
                                                    t={t}
                                                    label={t('Rohrserie')}
                                                    disabled={this.props.activePipetype ? '' : 'disabled'}
                                                    options={this.props.pipeSeriesOptions}
                                                    value={this.props.pipeseries || ''}
                                                    name={'pipeseries'}
                                                    onChange={this.onChangePipeSeries}
                                                    error={this.props.fieldsWithError.filter(field => {
                                                        return field.fieldName === 'pipeseries';
                                                    })[0]}
                                                    optional={false}
                                                />
                                        }

                                        <InputField
                                            t={t}
                                            label={t('Gefälle')}
                                            disabled={this.props.activePipetype ? '' : 'disabled'}
                                            placeholder="0"
                                            type="number"
                                            value={this.props.fallInput || ''}
                                            change={this.onChangeFall}
                                            unit="permil"
                                            error={this.props.fieldsWithError.filter(field => {
                                                return field.fieldName === 'fall';
                                            })[0]}
                                        />
                                    </div>
                                    {this.props.activePipetype &&
                                    <div className="cell">
                                        {
                                            this.props.abstand ? <InputRadioFieldCombo
                                                t={t}
                                                disabled={!this.props.activePipetype}
                                                onRadioSelectChange={this.props.onRadioSelectChangeAbstandAndHohenkote}
                                                onInputChange={this.props.onInputChangeAbstandAndHohenkote}
                                                type={this.props.abstand}
                                                id={this.props.abstand.key}
                                                typeSelected={this.props.abstandHohenkoteSelected}
                                                hasError={!!this.props.fieldsWithError.filter(field => {
                                                    return field.fieldName === 'abstand_zur_sohle_auslauf';
                                                })[0]}
                                                errorMessage={
                                                    this.props.fieldsWithError.filter(field => {
                                                        return field.fieldName === 'abstand_zur_sohle_auslauf';
                                                    })[0] ?
                                                        this.props.fieldsWithError.filter(field => {
                                                            return field.fieldName === 'abstand_zur_sohle_auslauf';
                                                        })[0].message :
                                                        ''
                                                }
                                                optional={false}
                                            /> : ''
                                        }
                                        {
                                            this.props.hohenkote ? <InputRadioFieldCombo
                                                t={t}
                                                disabled={!this.props.activePipetype}
                                                onRadioSelectChange={this.props.onRadioSelectChangeAbstandAndHohenkote}
                                                onInputChange={this.props.onInputChangeAbstandAndHohenkote}
                                                type={this.props.hohenkote}
                                                id={this.props.hohenkote.key}
                                                typeSelected={this.props.abstandHohenkoteSelected}
                                                hasError={!!this.props.fieldsWithError.filter(field => {
                                                    return field.fieldName === 'hohenkote_ab_ok_schacht';
                                                })[0]}
                                                errorMessage={
                                                    this.props.fieldsWithError.filter(field => {
                                                        return field.fieldName === 'hohenkote_ab_ok_schacht';
                                                    })[0] ?
                                                        this.props.fieldsWithError.filter(field => {
                                                            return field.fieldName === 'hohenkote_ab_ok_schacht';
                                                        })[0].message :
                                                        ''
                                                }
                                                optional={false}
                                            /> : ''
                                        }
                                    </div>
                                    }

                                    <div className="cell">
                                        <InputTextarea
                                            t={t}
                                            label={t('Kommentar')}
                                            disabled={!this.props.activePipetype}
                                            value={this.props.commentInput || ''}
                                            onChange={this.onChangeComment}
                                        />
                                    </div>
                                </div>
                            </form>

                            {this.props.productName === 'schlammsammler' &&
                            <InfoText>
                                {t('Dem Auslauf wird automatisch ein passender Tauchbogen PP hinzugefügt')}
                            </InfoText>
                            }
                        </div>

                        <div className="cell small-6 xlarge-7">
                            <div className="cf margin-top--2 angle-view-wrapper-outfall">
                                {
                                    <OutfallAngleView
                                        diameterOptions={this.props.diameterOptions}
                                        outfallDiameter={this.props.activeDiameter}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="main-content-footer">
                    <ButtonNavigation
                        t={t}
                        history={this.props.history}
                        enableNext={this.props.allowNext}
                        location={this.props.location}
                        eventHandler={this.props.eventHandler}/>
                </div>
            </div>
        )
    }

    onChangePipetype(value) {
        if (value) {
            this.props.updatePipetype(value);
        }
    }

    onChangeDiameter(e) {
        let target = e.target;

        if (target) {
            this.props.updateDiameter(target.value);
        }
    }

    onChangeFall(e) {
        let target = e.target;

        if (target) {
            this.props.updateFall(target.value)
        }
    }

    onChangeComment(value) {
        if (value) this.props.updateComment(value);
    }

    onChangePipeSeries(e) {
        let target = e.target;

        if (target) {
            this.props.updatePipeSeries(target.value)
        }
    }
}

Outfall.propTypes = {
    title: PropTypes.string,
    abstand: PropTypes.object,
    hohenkote: PropTypes.object,
    abstandHohenkoteSelected: PropTypes.string,
    commentInput: PropTypes.string,

    fieldsWithError: PropTypes.array,
    updateDiameter: PropTypes.func.isRequired,
    updatePipetype: PropTypes.func.isRequired,
    updateFall: PropTypes.func.isRequired,
    updateComment: PropTypes.func.isRequired,
    updatePipeSeries: PropTypes.func.isRequired,
    productName: PropTypes.string,

    onRadioSelectChangeAbstandAndHohenkote: PropTypes.func,
    onInputChangeAbstandAndHohenkote: PropTypes.func,

    eventHandler: PropTypes.object,
    location: PropTypes.any,
    allowNext: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    history: PropTypes.object,

    diameterOptions: PropTypes.array,
    pipetypeOptions: PropTypes.any,
    activePipetype: PropTypes.any,
    activeDiameter: PropTypes.any,
    fallInput: PropTypes.any,
    pipeseries: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    pipeSeriesOptions: PropTypes.array,

    t: PropTypes.func,
};

export default (translate(Outfall));