'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import {Route, Switch} from 'react-router-dom';

import events from '../event-handler';
import Footer from '../footer';
import Header from '../header';
import authorisation from '../authorisation';

import translate from '../translate';
import ProgressBar from '../progress-bar';
import Protocol from '../protocol';
import ProductSelectorData from '../product-selector-data';
import MeasurementData from '../measurement-data';
import FloorTypeData from '../floortype-data';
import TypeDimensionData from '../type-dimension-data';
import OutfallData from '../outfall-data';
import InletData from '../inlet-data';
import OptionalInformationData from '../optional-information-data';
import PriceVisualization from '../price-visualization-data';
import Error from '../error';
import ProjectInfos from '../project-infos';
import UserProfile from '../user-profile';
import UserOverviewData from '../user-overview-data';
import Restart from '../restart';
import EmailVerify from '../email-verify';
import PasswordReset from '../password-reset';
import OpenLoginModalForNotAuthorised from '../open-login-modal';
import DashboardData from '../dashboard-data';
import ProjectData from '../project-data';
import SaveConfiguration from '../save-configuration';


class Configurator extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showSideProtocol: true,
            showProgressBar: true
        }

        this.toggleSideProtocol = this.toggleSideProtocol.bind(this);
        this.toggleProgressBar = this.toggleProgressBar.bind(this);
    }

    render() {
        const {t} = this.props;
        const HeaderComponent = authorisation(Header);

        return (
            <div className="grid-container">
                <div className="grid-y grid-frame">
                    <div className="cell shrink">
                        <HeaderComponent/>
                    </div>
                    <div className="auto cell relative">
                        <main className="app-content">
                            <ProgressBar
                                eventHandler={events}
                                isVisible={this.state.showProgressBar}
                                t={t}
                            />
                            <div className="main-content scroll-y">
                                <Switch>
                                    <Route exact path="/" render={
                                        (props) => <ProductSelectorData
                                            {...props}
                                            eventHandler={events}
                                            showSideProtocol={true}
                                            showProgressBar={true}
                                            toggleSideProtocol={this.toggleSideProtocol}
                                            toggleProgressBar={this.toggleProgressBar}
                                            t={t}
                                        />
                                    }/>
                                    <Route exact path="/komplettschacht/measurement" render={
                                        (props) => <MeasurementData
                                            {...props}
                                            eventHandler={events}
                                            showSideProtocol={true}
                                            showProgressBar={true}
                                            toggleSideProtocol={this.toggleSideProtocol}
                                            toggleProgressBar={this.toggleProgressBar}
                                            t={t}
                                        />
                                    }/>
                                    <Route exact path="/schlammsammler/measurement" render={
                                        (props) => <MeasurementData
                                            {...props}
                                            eventHandler={events}
                                            showSideProtocol={true}
                                            showProgressBar={true}
                                            toggleSideProtocol={this.toggleSideProtocol}
                                            toggleProgressBar={this.toggleProgressBar}
                                            t={t}
                                        />
                                    }/>
                                    <Route exact path="/komplettschacht/floortype" render={
                                        (props) => <FloorTypeData
                                            {...props}
                                            eventHandler={events}
                                            showSideProtocol={true}
                                            showProgressBar={true}
                                            toggleSideProtocol={this.toggleSideProtocol}
                                            toggleProgressBar={this.toggleProgressBar}
                                            t={t}
                                        />
                                    }/>
                                    <Route exact path="/schlammsammler/floortype" render={
                                        (props) => <FloorTypeData
                                            {...props}
                                            eventHandler={events}
                                            showSideProtocol={true}
                                            showProgressBar={true}
                                            toggleSideProtocol={this.toggleSideProtocol}
                                            toggleProgressBar={this.toggleProgressBar}
                                            t={t}
                                        />
                                    }/>
                                    <Route exact path="/komplettschacht/type-of-dimension" render={
                                        (props) => <TypeDimensionData
                                            {...props}
                                            eventHandler={events}
                                            showSideProtocol={true}
                                            showProgressBar={true}
                                            toggleSideProtocol={this.toggleSideProtocol}
                                            toggleProgressBar={this.toggleProgressBar}
                                            t={t}
                                        />
                                    }/>
                                    <Route exact path="/schlammsammler/type-of-dimension" render={
                                        (props) => <TypeDimensionData
                                            {...props}
                                            eventHandler={events}
                                            showSideProtocol={true}
                                            showProgressBar={true}
                                            toggleSideProtocol={this.toggleSideProtocol}
                                            toggleProgressBar={this.toggleProgressBar}
                                            t={t}
                                        />
                                    }/>
                                    <Route exact path="/komplettschacht/outfall" render={
                                        (props) => <OutfallData
                                            {...props}
                                            productName={'komplettschacht'}
                                            eventHandler={events}
                                            showSideProtocol={true}
                                            showProgressBar={true}
                                            toggleSideProtocol={this.toggleSideProtocol}
                                            toggleProgressBar={this.toggleProgressBar}
                                            t={t}
                                        />
                                    }/>
                                    <Route exact path="/schlammsammler/outfall" render={
                                        (props) => <OutfallData
                                            {...props}
                                            productName={'schlammsammler'}
                                            eventHandler={events}
                                            showSideProtocol={true}
                                            showProgressBar={true}
                                            toggleSideProtocol={this.toggleSideProtocol}
                                            toggleProgressBar={this.toggleProgressBar}
                                            t={t}
                                        />
                                    }/>
                                    <Route exact path="/komplettschacht/inlet" render={
                                        (props) => <InletData
                                            {...props}
                                            eventHandler={events}
                                            showSideProtocol={true}
                                            showProgressBar={true}
                                            toggleSideProtocol={this.toggleSideProtocol}
                                            toggleProgressBar={this.toggleProgressBar}
                                            t={t}
                                        />
                                    }/>
                                    <Route exact path="/schlammsammler/inlet" render={
                                        (props) => <InletData
                                            {...props}
                                            eventHandler={events}
                                            showSideProtocol={true}
                                            showProgressBar={true}
                                            toggleSideProtocol={this.toggleSideProtocol}
                                            toggleProgressBar={this.toggleProgressBar}
                                            t={t}
                                        />
                                    }/>
                                    <Route exact path="/komplettschacht/optional-information" render={
                                        (props) => <OptionalInformationData
                                            {...props}
                                            productName={'komplettschacht'}
                                            eventHandler={events}
                                            showSideProtocol={true}
                                            showProgressBar={true}
                                            toggleSideProtocol={this.toggleSideProtocol}
                                            toggleProgressBar={this.toggleProgressBar}
                                            t={t}
                                        />
                                    }/>
                                    <Route exact path="/schlammsammler/optional-information" render={
                                        (props) => <OptionalInformationData
                                            {...props}
                                            productName={'schlammsammler'}
                                            eventHandler={events}
                                            showSideProtocol={true}
                                            showProgressBar={true}
                                            toggleSideProtocol={this.toggleSideProtocol}
                                            toggleProgressBar={this.toggleProgressBar}
                                            t={t}
                                        />
                                    }/>
                                    <Route exact path="/error" render={
                                        (props) => <Error
                                            {...props}
                                            eventHandler={events}
                                            showSideProtocol={false}
                                            showProgressBar={false}
                                            toggleSideProtocol={this.toggleSideProtocol}
                                            toggleProgressBar={this.toggleProgressBar}
                                            t={t}
                                        />
                                    }/>
                                    <Route exact path="/restart" render={
                                        (props) => <Restart
                                            {...props}
                                            eventHandler={events}
                                            t={t}
                                        />
                                    }/>
                                    <Route exact path="/verify-email/:status" render={
                                        (props) => <EmailVerify
                                            {...props}
                                            showSideProtocol={false}
                                            showProgressBar={false}
                                            toggleSideProtocol={this.toggleSideProtocol}
                                            toggleProgressBar={this.toggleProgressBar}
                                            t={t}
                                        />}
                                    />
                                    <Route exact path="/change-password/:token" render={
                                        (props) => <PasswordReset
                                            {...props}
                                            eventHandler={events}
                                            isPasswordResetModalOpen={true}
                                            showSideProtocol={false}
                                            showProgressBar={false}
                                            toggleSideProtocol={this.toggleSideProtocol}
                                            toggleProgressBar={this.toggleProgressBar}
                                            t={t}
                                        />
                                    }/>
                                    <Route render={
                                        (props) => <OpenLoginModalForNotAuthorised
                                            {...props}
                                            render={
                                                () => {
                                                    return (
                                                        <Switch>
                                                            <Route
                                                                exact
                                                                path="/dashboard"
                                                                render={
                                                                    (props) => <DashboardData
                                                                        {...props}
                                                                        eventHandler={events}
                                                                        showSideProtocol={false}
                                                                        showProgressBar={false}
                                                                        toggleSideProtocol={this.toggleSideProtocol}
                                                                        toggleProgressBar={this.toggleProgressBar}
                                                                        t={t}
                                                                    />
                                                                }/>
                                                            <Route exact path="/project/:projectId" render={
                                                                (props) => <ProjectData
                                                                    {...props}
                                                                    eventHandler={events}
                                                                    showSideProtocol={false}
                                                                    showProgressBar={false}
                                                                    toggleSideProtocol={this.toggleSideProtocol}
                                                                    toggleProgressBar={this.toggleProgressBar}
                                                                    t={t}
                                                                />
                                                            }/>
                                                            <Route exact path="/edit-project-info/:projectId" render={
                                                                (props) => <ProjectInfos
                                                                    {...props}
                                                                    eventHandler={events}
                                                                    showSideProtocol={false}
                                                                    showProgressBar={false}
                                                                    toggleSideProtocol={this.toggleSideProtocol}
                                                                    toggleProgressBar={this.toggleProgressBar}
                                                                    t={t}
                                                                />
                                                            }/>
                                                            <Route exact path="/edit-user" render={
                                                                (props) => <UserProfile
                                                                    {...props}
                                                                    eventHandler={events}
                                                                    showSideProtocol={false}
                                                                    showProgressBar={false}
                                                                    toggleSideProtocol={this.toggleSideProtocol}
                                                                    toggleProgressBar={this.toggleProgressBar}
                                                                    t={t}
                                                                />
                                                            }/>
                                                            <Route
                                                                exact
                                                                path="/komplettschacht/save-configuration"
                                                                render={
                                                                    (props) => <SaveConfiguration
                                                                        {...props}
                                                                        eventHandler={events}
                                                                        t={t}
                                                                        showSideProtocol={false}
                                                                        showProgressBar={false}
                                                                        toggleSideProtocol={this.toggleSideProtocol}
                                                                        toggleProgressBar={this.toggleProgressBar}
                                                                    />
                                                                }/>
                                                            <Route
                                                                exact
                                                                path="/schlammsammler/save-configuration"
                                                                render={
                                                                    (props) => <SaveConfiguration
                                                                        {...props}
                                                                        eventHandler={events}
                                                                        t={t}
                                                                        showSideProtocol={false}
                                                                        showProgressBar={false}
                                                                        toggleSideProtocol={this.toggleSideProtocol}
                                                                        toggleProgressBar={this.toggleProgressBar}
                                                                    />
                                                                }/>
                                                            <Route exact path="/komplettschacht/price-weight-overview"
                                                                render={
                                                                    (props) => <PriceVisualization
                                                                        {...props}
                                                                        eventHandler={events}
                                                                        productName={'komplettschacht'}
                                                                        showSideProtocol={true}
                                                                        showProgressBar={true}
                                                                        toggleSideProtocol={this.toggleSideProtocol}
                                                                        toggleProgressBar={this.toggleProgressBar}
                                                                        t={t}
                                                                    />
                                                                }
                                                            />
                                                            <Route exact path="/schlammsammler/price-weight-overview"
                                                                render={
                                                                    (props) => <PriceVisualization
                                                                        {...props}
                                                                        productName={'schlammsammler'}
                                                                        eventHandler={events}
                                                                        showSideProtocol={true}
                                                                        showProgressBar={true}
                                                                        toggleSideProtocol={this.toggleSideProtocol}
                                                                        toggleProgressBar={this.toggleProgressBar}
                                                                        t={t}
                                                                    />
                                                                }
                                                            />
                                                            <Route
                                                                exact
                                                                path="/user-overview"
                                                                render={
                                                                    (props) => <UserOverviewData
                                                                        {...props}
                                                                        eventHandler={events}
                                                                        t={t}
                                                                        showSideProtocol={false}
                                                                        toggleSideProtocol={this.toggleSideProtocol}
                                                                    />
                                                                }/>
                                                        </Switch>
                                                    )
                                                }
                                            }
                                        />
                                    }/>
                                </Switch>
                                <Protocol
                                    eventHandler={events}
                                    isVisible={this.state.showSideProtocol}
                                    t={t}
                                />
                            </div>
                        </main>
                    </div>
                    <div className="cell shrink">
                        <Footer/>
                    </div>
                </div>
            </div>
        );
    }

    toggleSideProtocol(value) {
        this.setState(prev => {
            prev.showSideProtocol = !!value;

            return prev
        });
    }

    toggleProgressBar(value) {
        this.setState(prev => {
            prev.showProgressBar = !!value;

            return prev
        });
    }
}

Configurator.propTypes = {
    t: PropTypes.func
};

export default (translate(Configurator));
