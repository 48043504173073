import _ from 'lodash';

const {createReducer} = require('../../lib/reducer');

let initialState = {
    user: {
        first_name: '',
        last_name: '',
        street: '',
        city: '',
        phone: '',
        email: '',
        password: '',
        password_repeat: '',
        agb_accepted: 0
    },
    invalidFields: [],
    error: {},
    loading: false,
    isSaving: {},
    success: {}
};

const validSaveOperation = (state, action) => {
    //Reset invalid fields
    state.invalidFields = [];

    // reset general error
    state.error = undefined;

    return _.merge(
        {},
        state,
        {
            isSaving: false,
            success: true,
            user: action.payload.user
        }
    );
};

export const userReducer = createReducer(initialState, {

    //Save User data to server
    'save-user-data_PENDING': (state) => {
        state.success = undefined;
        state.error = undefined;

        return _.merge({}, state, {isSaving: true});
    },

    'save-user-data_FULFILLED': validSaveOperation,

    'save-user-data_REJECTED': (state, action) => {

        //Reset invalid fields
        state.invalidFields = [];

        let error = action.payload.error;
        let message = action.payload.message;
        let invalidFields = {};

        if (error.status === 422) {
            invalidFields = _.get(error, 'details.codes', {});
        }

        return _.merge({}, state, {
            isSaving: false,
            error: message,
            invalidFields: Object.keys(invalidFields).map(error => {
                return {
                    fieldName: error,
                    message: invalidFields[error][0]
                }
            }),
            success: false
        });
    },

    'get-user-data_FULFILLED': (state, action) => {

        return _.merge({}, state, {user: action.payload.userRes});
    },

    'get-user-data_PENDING': (state) => {
        state.success = undefined;
        state.error = undefined;

        return _.merge({}, state, {isSaving: true});
    },

    'get-user-data_REJECTED': (state, action) => {

        //Reset invalid fields
        state.invalidFields = [];

        let error = action.payload.error;
        let message = action.payload.message;
        let invalidFields = {};

        if (error.status === 422) {
            invalidFields = _.get(error, 'details.codes', {});
        }

        return _.merge({}, state, {
            isSaving: false,
            error: message,
            invalidFields: Object.keys(invalidFields).map(error => {
                return {
                    fieldName: error,
                    message: invalidFields[error][0]
                }
            }),
            success: false
        });
    },

    'update-user-data_FULFILLED': validSaveOperation,

    'update-user-data_PENDING': (state) => {
        state.success = undefined;
        state.error = undefined;

        return _.merge({}, state, {isSaving: true});
    },

    'update-user-data_REJECTED': (state, action) => {

        //Reset invalid fields
        state.invalidFields = [];

        let error = action.payload.error;
        let message = action.payload.message;
        let invalidFields = {};

        if (error.status === 422) {
            invalidFields = _.get(error, 'details.codes', {});
        }

        return _.merge({}, state, {
            isSaving: false,
            error: message,
            invalidFields: Object.keys(invalidFields).map(error => {
                return {
                    fieldName: error,
                    message: invalidFields[error][0]
                }
            }),
            success: false
        });
    },

    'sync-registration-data': (state, action) => {
        let user = state.user,
            data = action.data;

        user[data.name] = data.value;

        return _.merge({}, state, {user: user});
    },
});
