'use strict';

const ProductSelectorStep = require('./../../steps/product');
const KomplettschachtStepMeasurement = require('../../komplettschacht-configurator/steps/measurement');
const config = require('./../config/schlammsammler-configuration.json');

class StepMeasurement extends KomplettschachtStepMeasurement {
    constructor(props){
        props = props || {};
        props.config = config;

        super(props);

        this.productSelector = new ProductSelectorStep();
    }
}

module.exports = StepMeasurement;